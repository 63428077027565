import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { TextField, Dialog, DialogActions, DialogTitle, DialogContent, FormControl } from '@material-ui/core';

import { DialogButton, BannerType, ComponentController } from 'components/common';
import { GeneralDetailsFields, getCountryData } from '../Helpers';
import { addReassured } from '../SubmissionWizard.api';
import { maxAddressLength, maxPostCodeLength } from '../Helpers/Constants';
import { Dropdown } from 'components/common';

const reassuredMaxLength = 130;

export const ReassuredPopup = ({
  popupState,
  requestData,
  setPopupState,
  setFormState,
  setValidationValue,
  setBannerState,
  setLoading
}) => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: "onTouched",
    defaultValues: {
      reassured: popupState.newValue,
      state: ""
    }
  });

  const [states, setStates] = useState([]);
  const [stateToStateCodeMap, setStateToStateCodeMap] = useState({});
  const [isCountryValid, setIsCountryValid] = useState(false);
  
  useEffect(() => {
    const { countryData, states, stateToStateCodeMap } = getCountryData(
      requestData?.countryStatesData,
      requestData?.countryName
    );
    setStates(states);
    setStateToStateCodeMap(stateToStateCodeMap);
    setIsCountryValid(!!countryData);
  }, [requestData?.countryStatesData, requestData?.countryName]);

  const handleClose = useCallback(
    () => setPopupState(previous => ({ ...previous, show: false })),
    [setPopupState]);

  const onReassuredSubmit = useCallback(response => {
    let reassured = response.data?.reassured;
    let address = response.data?.address;
    let postCode = response.data?.postCode;
    let state = response.data?.state;
    let stateCode = response.data?.stateCode;

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        reassured,
        secondReassured: previous.fields.isDualStamp ? reassured : null,
        address,
        postCode,
        state,
        stateCode,
        hamiltonId : null
      }
    }));

    setValidationValue("reassured", reassured, { shouldValidate: true });
  }, [setValidationValue, setFormState])

  const onSubmit = useCallback(async popupData => {
    handleClose();
    let reassured = popupData.reassured;
    let stateCode = stateToStateCodeMap[popupData.state];
    let reassuredData = {
      data: {
        reassured: popupData.reassured,
        address: popupData.addressDetails,
        postCode: popupData.postCodeDetails,
        state: popupData.state,
        stateCode: stateCode
      }
    };
    if (requestData.typeOfReassured) {
      onReassuredSubmit(reassuredData);
    } else {
      setLoading(true);

      let response = await addReassured({ reassured });
      if (response.success) {
        onReassuredSubmit(reassured);
      } else {
        setBannerState({
          show: true,
          type: BannerType.error,
          message: response.errorMessage
        });
      }

      setLoading(false);
    }
  }, [setFormState, setValidationValue, handleClose, setBannerState, setLoading, stateToStateCodeMap]);

  const getRequiredValidationError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const getMaxLengthValidationError = name => {
    return errors[name]?.type === 'maxLength' &&
      <span role="alert">"{GeneralDetailsFields[name]}" maximum length is {reassuredMaxLength} characters</span>;
  }

  return (
    <Dialog open={popupState.show} onClose={handleClose} aria-labelledby="reassured-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="reassured-dialog-title">Add a new Reassured to Sequel Eclipse</DialogTitle>
        <DialogContent>
          <ComponentController
            name="reassured"
            control={control}
            required
            rules={{ maxLength: reassuredMaxLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getRequiredValidationError(name) || getMaxLengthValidationError(name)}
              />
            }
          />
          <ComponentController
            name="addressDetails"
            control={control}
            rules={{ maxLength: maxAddressLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getMaxLengthValidationError(name)}
              />
            }
          />
          <ComponentController
            name="postCodeDetails"
            control={control}
            rules={{ maxLength: maxPostCodeLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                helperText={getMaxLengthValidationError(name)}
              />
            }
          />
          {isCountryValid && (
            <ComponentController
              name="state"
              control={control}
              render={({ field: { name, value, onChange, onBlur } }) =>
                <FormControl fullWidth error={!!errors[name]}>
                  <Dropdown
                    id={name}
                    name={name}
                    value={value || ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={GeneralDetailsFields[name]}
                    data={states}
                    margin="dense"
                    fullWidth
                  />
                </FormControl>
              }
            />
          )}
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <DialogButton onClick={handleClose}>Cancel</DialogButton>
          <DialogButton type="Submit">Add</DialogButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
