import { generateRows, isDualStampEntity } from 'components/SubmissionWizard/Helpers';

export const generatePolicies = (policyNumbers, businessEntity, policies) => {
  const isDualStamp = isDualStampEntity(businessEntity.entityCode);
  let data = {
    classTypeCode: '',
    classType: '',
    majorClassCode: '',
    majorClass: '',
    minorClassCode: '',
    minorClass: '',
    class: '',
    classCode: '',
    producingTeamCode: '',
    producingTeam: '',
    entityCode: isDualStamp ? '' : businessEntity.entityCode,
    entity: isDualStamp ? '' : businessEntity.entityDescription,
    declarationType: '',
    declarationAssured: null
  };

  return generateRows(policyNumbers, policies, data);
}