import React, { useCallback, useState } from 'react';
import { useStarAiContext } from 'star-ai';
import { FormControl, Grid, TextField } from '@material-ui/core';

import { CheckboxElement, ComponentController, DatePickerElement, Dropdown, FormControlWrapper, SwitchComponent } from 'components/common';
import { createMajorClassStructure, S4000Entity, dualStampEntity, GeneralDetailsFields, TotalNumberOfPolicyReferencesName, HidacEntity, DocumentTypes } from 'components/SubmissionWizard/Helpers';
import TotalPoliciesField from 'components/SubmissionWizard/Fields/TotalPoliciesField';
import { ConditionCode, DefaultConditionName, AssuredAndPolicyReferenceControl, SecondAssuredAndPolicyReferenceControl } from './common';
import { validateDate } from 'Helpers';

import '../GeneralDetails.css';

const EndorsementsSection = ({
  fields,
  control,
  errors,
  trigger,
  forceClearDatasourceState,
  setFormState,
  setValidationValue,
  dropdownsData,
  setLoading,
  setBannerState,
  setForceClearDatasourceState,
  validationRef,
  resetFieldValidationState,
  modellingMajorClasses
}) => {
  // star ai
  const ai = useStarAiContext();

  const [abortController, setAbortController] = useState(new AbortController());

  const onFieldChange = useCallback((name, value) => {
    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields, [name]: value ?? '' }
    }));
  }, [setFormState]);

  const onDualStampSwitch = useCallback(checked => {
    resetFieldValidationState("assured");
    resetFieldValidationState("secondAssured");
    setForceClearDatasourceState({ "assured": true });

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        _assuredOrReassured: '',
        _secondAssuredOrReassured: '',
        isDualStamp: checked,
        businessEntity: checked ? dualStampEntity : '',
        majorClass: null,
        assured: null,
        reassured: '',
        policyId: null,
        policyReferenceNumber: '',
        secondAssured: null,
        secondReassured: '',
        secondPolicyId: null,
        secondPolicyReferenceNumber: '',
        renewedPolicyS4000: null,
        renewedPolicyHIDAC: null,
        totalNumberOfPolicyReferences: checked ? 2 : 1
      }
    }));

    abortController.abort();
  }, [abortController, setFormState, resetFieldValidationState, setForceClearDatasourceState]);

  const onAssuredPolicyChanged = useCallback(assured => {
    let majorClass = createMajorClassStructure(assured, modellingMajorClasses);
    let _prevMajorclassCode = true;

    setFormState(previous => {
      const prevMajorclassCode = previous.fields.majorClass?.majorClassCodes?.includes(majorClass?.majorClassCodes[0]);
      _prevMajorclassCode = prevMajorclassCode;

      if (!_prevMajorclassCode) {
        setForceClearDatasourceState({ "secondAssured": true });
        resetFieldValidationState("secondAssured");
      }

      const _secondAssured = prevMajorclassCode ? previous.fields.secondAssured : null;
      const secondAssuredOrReassured = prevMajorclassCode ? previous.fields._secondAssuredOrReassured : '';
      const _secondPolicyReferenceNumber = prevMajorclassCode ? previous.fields.secondPolicyReferenceNumber : '';
      const _secondPolicyId = prevMajorclassCode ? previous.fields.secondPolicyId : null;
      const _secondReassured = prevMajorclassCode ? previous.fields.secondReassured : null;
      const _renewedPolicyHIDAC = prevMajorclassCode ? previous.fields.renewedPolicyHIDAC : null;
      return {
        ...previous,
        fields: {
          ...previous.fields,
          _secondAssuredOrReassured: secondAssuredOrReassured,
          majorClass: majorClass,
          secondAssured: _secondAssured,
          secondReassured: _secondReassured,
          secondPolicyId: _secondPolicyId,
          secondPolicyReferenceNumber: _secondPolicyReferenceNumber,
          renewedPolicyHIDAC: _renewedPolicyHIDAC,
          requiredModellingPriority: majorClass?.isModellingPriority
            ? previous.dropdownsData.modellingPriorities[0]
            : ''
        }
      }
    });

    abortController.abort();
  }, [abortController, dropdownsData.modellingPriorities, setFormState, resetFieldValidationState, setForceClearDatasourceState]);

  const getRequiredError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const getDateValidationError = name => {
    let validDate = errors[name]?.type === 'validDate' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is not valid</span>;

    return validDate;
  }

  const renderDualStampSwitch = () => {
    const name = "dualStampSwitch";
    const valueKey = "isDualStamp";
    let isDualStampVisible = [S4000Entity.DisplayValue, HidacEntity.DisplayValue].every((el) => {
      return dropdownsData.entitiesForFilter.includes(el)
    });
    
    return isDualStampVisible && (
      <Grid item>
        <FormControlWrapper
          control={
            <SwitchComponent
              name={name}
              checked={fields[valueKey]}
              onChange={e => onDualStampSwitch(e.target?.checked)}
              inputRef={ai?.fieldsRef?.[valueKey]}
            />
          }
          label={GeneralDetailsFields.dualStampSwitch}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderModellingPriority = () => {
    let name = "requiredModellingPriority";
    return fields.majorClass?.isModellingPriority &&
      (
        <Grid item>
          <FormControl fullWidth>
            <Dropdown
              id={name}
              label={GeneralDetailsFields[name]}
              value={fields[name]}
              data={dropdownsData.modellingPriorities}
              onChange={e => onFieldChange(name, e.target.value)}
              inputRef={ai?.fieldsRef?.[name]}
            />
          </FormControl>
        </Grid>
      );
  }

  const renderLookupForAssuredAndPolicyReference = (lookupType = "lookupForAssuredAndPolicyReference") => {
    return (<AssuredAndPolicyReferenceControl
      lookupType={lookupType}
      errors={errors}
      control={control}
      fields={fields}
      onChange={value => onAssuredPolicyChanged(value)}
      additionalRequestData={{ businessEntities: fields.isDualStamp ? [S4000Entity.DisplayValue] : dropdownsData.entitiesForFilter }}
      setFormState={setFormState}
      setValidationValue={setValidationValue}
      setLoading={setLoading}
      setBannerState={setBannerState}
      forceClearDatasourceState={forceClearDatasourceState}
      forceEnabled={true}
      includePlacingBasis={true}
      abortController={abortController}
      setAbortController={setAbortController }
      inputRef={ai?.fieldsRef?.[lookupType]}
    />)
  }

  const renderAssured = (assuredType="assured") => {
    let name = "assured";
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[assuredType]}
          value={fields.assured?.assured ?? fields.reassured ?? ""}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderPolicyReferenceNumber = (policyReferenceNumberType="policyReferenceNumber") => {
    let name = "policyReferenceNumber";

    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[policyReferenceNumberType]}
          value={fields[name]}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderSecondLookupForAssuredAndPolicyReference = (lookupType = "secondLookupForAssuredAndPolicyReference") => {
    return (fields.isDualStamp && <SecondAssuredAndPolicyReferenceControl
      lookupType={lookupType}
      errors={errors}
      control={control}
      fields={fields}
      additionalRequestData={{ businessEntities: ["HDAC", "IDAC"] }}
      setFormState={setFormState}
      setValidationValue={setValidationValue}
      setLoading={setLoading}
      setBannerState={setBannerState}
      forceClearDatasourceState={forceClearDatasourceState}
      includePlacingBasis={true}
      inputRef={ai?.fieldsRef?.[lookupType]}
    />)
  }

  const renderSecondAssured = () => {
    let name = "secondAssured";
    return (
      fields.isDualStamp &&
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.secondAssured?.assured ?? fields.secondReassured ?? ""}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderSecondPolicyReferenceNumber = () => {
    let name = "secondPolicyReferenceNumber";
    return (
      fields.isDualStamp &&
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields[name]}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderTotalNumberOfReferences = () => {
    return (
      <Grid item>
        <TotalPoliciesField
          name={TotalNumberOfPolicyReferencesName}
          label={GeneralDetailsFields[TotalNumberOfPolicyReferencesName]}
          required={true}
          control={control}
          value={fields[TotalNumberOfPolicyReferencesName]}
          errors={errors}
          setFormState={setFormState}
          setValidationValue={setValidationValue}
          getRequiredError={getRequiredError}
          inputRef={ai?.fieldsRef?.[TotalNumberOfPolicyReferencesName]}
        />
      </Grid>
    );
  }

  const renderConditionCode = () => {
    return (
      <ConditionCode
        name={DefaultConditionName}
        dropdownsData={dropdownsData}
        fields={fields}
        control={control}
        errors={errors}
        trigger={trigger}
        setFormState={setFormState}
        setBannerState={setBannerState}
        validationRef={validationRef}
        isRequired={false}
        inputRef={ai?.fieldsRef?.[DefaultConditionName]}
      />
    );
  }

  const renderPremiumBearing = () => {
    const name = "premiumBearing";
    return (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              name={name}
              onChange={(e, checked) => onFieldChange(e.target?.name, checked)}
              checked={fields[name]}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
          label={GeneralDetailsFields[name]}
          labelPlacement="start"
        />
      </Grid>
    );
  };

  const renderDateField = fieldName => {
    return (
      <Grid item>
        <ComponentController
          name={fieldName}
          control={control}
          required
          rules={
            {
              validate: { validDate: value => validateDate(value) }
            }}
          render={({ field: { name, onChange, onBlur } }) =>
            <DatePickerElement
              id={name}
              name={name}
              label={GeneralDetailsFields[name]}
              fullWidth
              format="dd/MM/yyyy"
              value={fields[name]}
              onChange={e => { onFieldChange(name, e); onChange(e); }}
              onBlur={onBlur}
              error={!!errors[name]}
              helperText={getRequiredError(name) || getDateValidationError(name)}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
        />
      </Grid>
    );
  }

  const renderPromisedLineFields = () => {
    let renderPromisedLines = fields.documentType === DocumentTypes.PromisedLines;
    return renderPromisedLines ?
      <>
        {renderDateField("inceptionDate")}
        {renderDateField("promisedDate")}
      </> :
      <>
        {renderConditionCode()}
        {renderPremiumBearing()}
      </>;
  };

  return <>
    {renderDualStampSwitch()}
    {renderModellingPriority()}
    {renderLookupForAssuredAndPolicyReference(fields.isDualStamp ? "lookupForAssuredAndPolicyReference4000" : "lookupForAssuredAndPolicyReference")}
    {renderPolicyReferenceNumber(fields.isDualStamp ? "policyReferenceNumber4000" : "policyReferenceNumber")}
    {renderAssured(fields.isDualStamp ? "assuredOrMasterAssured4000" : "assured")}
    {renderSecondLookupForAssuredAndPolicyReference()}
    {renderSecondPolicyReferenceNumber()}
    {renderSecondAssured()}
    {renderTotalNumberOfReferences()}
    {renderPromisedLineFields()}
  </>;
}

export default EndorsementsSection;