import React, { useCallback, useEffect, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import DeleteIcon from '@material-ui/icons/Delete';
import FileCopy from '@material-ui/icons/FileCopy';
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid';
import { FormControl, Grid, IconButton } from '@material-ui/core';

import { Datasource } from 'Api';
import { ComponentController, Dropdown, PickerComponent } from 'components/common';
import {
  isDualStampEntity, isEntityField, isDeclaration,
  singlePolicyStructure, setZeroTime, toLocalDateTime
} from 'components/SubmissionWizard/Helpers';
import { AssuredPopup } from 'components/SubmissionWizard/Popups';

import './PoliciesTable.css';

const useStyles = makeStyles(
  (_) => {
    return {
      root: {
        '& .MuiDataGrid-cell--editing': {
          backgroundColor: 'rgb(255,215,115, 0.19)',
          color: '#1a3e72'
        },
        '& .Mui-error': {
          backgroundColor: `rgb(126,10,15, 0.1)`,
          color: '#750f0f'
        }
      },
    };
  }
);

const PoliciesTable = ({
  totalNumberOfPolicyReferences,
  policyStructure,
  businessEntity,
  placingBasis,
  placingBasisCode,
  policies,
  modellingMajorClasses,
  dropdownsData,
  control,
  errors,
  setLineOfBusinessState,
  setBannerState,
  setValidationValue,
  setLoading,
  getModellingPriority
}) => {
  const [columns, setColumns] = useState([
    {
      field: 'layer',
      headerName: 'Layer',
      flex: 0.5,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      mandatory: true
    },
    {
      field: 'entityCode',
      previousFields: [],
      nextFields: ['producingTeam', 'classType', 'majorClass', 'minorClass', 'class'],
      headerName: 'Entity',
      flex: 0.6,
      type: "singleSelect",
      valueOptions: [],
      dataSource: "classesOfBusiness",
      renderEditCell: {},
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      forceRerender: false,
      mandatory: true,
    },
    {
      field: 'producingTeam',
      previousFields: ['entityCode'],
      nextFields: ['classType', 'majorClass', 'minorClass', 'class'],
      headerName: 'Producing Team',
      flex: 1,
      type: "singleSelect",
      valueOptions: [],
      dataSource: "classesOfBusiness",
      renderEditCell: {},
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      mandatory: true
    },
    {
      field: 'classType',
      previousFields: ['producingTeam', 'entityCode'],
      nextFields: ['majorClass', 'minorClass', 'class'],
      headerName: 'Class Type',
      flex: 0.9,
      type: "singleSelect",
      valueOptions: [],
      dataSource: "classesOfBusiness",
      renderEditCell: {},
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      mandatory: true
    },
    {
      field: 'majorClass',
      previousFields: ['classType', 'producingTeam', 'entityCode'],
      nextFields: ['minorClass', 'class'],
      headerName: 'Major Class',
      flex: 1,
      type: "singleSelect",
      valueOptions: [],
      dataSource: "classesOfBusiness",
      renderEditCell: {},
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      mandatory: true
    },
    {
      field: 'minorClass',
      previousFields: ['majorClass', 'classType', 'producingTeam', 'entityCode'],
      nextFields: ['class'],
      headerName: 'Minor Class',
      flex: 0.9,
      type: "singleSelect",
      valueOptions: [],
      dataSource: "classesOfBusiness",
      renderEditCell: {},
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      mandatory: true
    },
    {
      field: 'class',
      previousFields: ['minorClass', 'majorClass', 'classType', 'producingTeam', 'entityCode'],
      nextFields: [],
      headerName: 'Class',
      flex: 0.5,
      type: "singleSelect",
      valueOptions: [],
      dataSource: "classesOfBusiness",
      renderEditCell: {},
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      mandatory: false
    },
    {
      field: 'declarationAssured',
      headerName: 'Declaration Assured',
      previousFields: [],
      nextFields: [],
      flex: 1.2,
      valueOptions: [],
      declarationAssuredContainer: true,
      renderEditCell: () => {},
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      mandatory: false,
      hide: true
    },
    {
      field: 'declarationType',
      headerName: 'Declaration Type',
      previousFields: [],
      nextFields: [],
      flex: 1,
      type: "singleSelect",
      valueOptions: [],
      dataSource: "declarationTypes",
      declarationTypeContainer: true,
      renderEditCell: {},
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      mandatory: false,
      hide: true
    },
    {
      field: 'deleteIcon',
      headerName: ' ',
      hide: false,
      flex: 0.4,
      type: "icon",
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      renderEditCell: () => "",
      renderCell: {}
    },
    {
      field: 'copyIcon',
      headerName: ' ',
      hide: false,
      flex: 0.4,
      type: "icon",
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      renderEditCell: () => "",
      renderCell: {}
    }
  ]);
  const classes = useStyles();

  const policiesRef = React.useRef([]);
  policiesRef.current = policies;
  
  const handleChange = useCallback((cellName, value, data, nextFields) => {
    let dropdownRecords = data?.data;
    let id = data?.id;

    setLineOfBusinessState(previous => ({
      ...previous,
      policies: policiesRef.current
        .map(policy => {
          if (policy.id === id) {
            let code = isEntityField(cellName) ? 'entity' : `${cellName}Code`;
            policy[code] = dropdownRecords.find(r => r.description === value).code;
            policy[cellName] = value;

            nextFields.forEach(field => {
              policy[`${field}Code`] = policy[field] = '';
            });
          }

          return policy;
        })
    }));

    if (nextFields?.find(x => x === "majorClass") || cellName === "majorClass") {
      setLineOfBusinessState(previous => {
        let modellingPriority = getModellingPriority(
          previous.fields.requiredModellingPriority, policiesRef.current, modellingMajorClasses);

        return {
          ...previous,
          fields: { ...previous.fields, requiredModellingPriority: modellingPriority }
        }
      });
    }

    setColumns(previous => {
      return previous.map(p => {
        if ('forceRerender' in p) {
          p.forceRerender = !p.forceRerender;
        }
        return p;
      });
    });
  }, [modellingMajorClasses, getModellingPriority, setLineOfBusinessState]);

  const onCopyRow = useCallback(cell => {
    if (policyStructure !== singlePolicyStructure) {
      let policyToCopy = policiesRef.current.find(x => x.id === cell.id);
      let newPolicyRow = { ...policyToCopy };

      newPolicyRow.id = uuidv4();
      newPolicyRow.layer = policiesRef.current.length + 1;

      setLineOfBusinessState(previous => {
        let policiesList = [...previous.policies, newPolicyRow];
        let modellingPriority = getModellingPriority(
          previous.fields.requiredModellingPriority, policiesList, modellingMajorClasses);

        let newState = {
          ...previous,
          fields: {
            ...previous.fields,
            totalNumberOfPolicyReferences: policiesList.length,
            requiredModellingPriority: modellingPriority
          },
          policies: policiesList
        };

        setValidationValue("totalNumberOfPolicyReferences", policiesList.length, { shouldValidate: true });

        return newState;
      });
    }
  }, [policyStructure, modellingMajorClasses, setLineOfBusinessState, getModellingPriority, setValidationValue])

  const onRemoveRow = useCallback((cell, event) => {
    let { id, api } = cell;
    let isDualStamp = isDualStampEntity(businessEntity.entityCode) && policiesRef.current.length === 2;
    let isSingle = policiesRef.current.length === 1;

    if (isDualStamp || isSingle) {
      setLineOfBusinessState(previous => {
        let policies = previous.policies
          .map(x => {
            if (x.id === id) {
              return {
                ...x,
                entityCode: isDualStamp ? "" : x.entityCode,
                producingTeam: "",
                classType: "",
                classTypeCode: "",
                majorClass: "",
                majorClassCode: "",
                minorClass: "",
                minorClassCode: "",
                class: "",
                classCode: "",
                declarationType: "",
                declarationTypeCode: "",
                declarationAssured: "",
                declarationAssuredId: null,
                effectiveFromDate: null,
                effectiveToDate: null
              }
            }

            return x;
          });

        let modellingPriority = getModellingPriority(
          previous.fields.requiredModellingPriority, policies, modellingMajorClasses);

        let newState = {
          ...previous,
          fields: {
            ...previous.fields,
            totalNumberOfPolicyReferences: policies.length,
            requiredModellingPriority: modellingPriority
          },
          policies
        };

        setValidationValue("totalNumberOfPolicyReferences", policies.length, { shouldValidate: true });

        return newState;
      });
    }
    else {
      event.stopPropagation();
      api.updateRows([{ id, _action: 'delete' }]);

      setLineOfBusinessState(previous => {
        let policies = previous.policies
          .filter(p => p.id !== id)
          .map((p, index) => {
            p.layer = index + 1;
            return p;
          });

        let modellingPriority = getModellingPriority(
          previous.fields.requiredModellingPriority, policies, modellingMajorClasses);

        let newState = {
          ...previous,
          fields: {
            ...previous.fields,
            totalNumberOfPolicyReferences: policies.length,
            requiredModellingPriority: modellingPriority
          },
          policies
        };

        setValidationValue("totalNumberOfPolicyReferences", policies.length, { shouldValidate: true });

        return newState;
      });
    }
  }, [businessEntity.entityCode, modellingMajorClasses, getModellingPriority, setLineOfBusinessState, setValidationValue]);

  useEffect(() => {
    let initMandatoryColumns = columns.map(c => {
      if (c.mandatory) {
        c.headerName = `${c.headerName}*`;
      }
      return c;
    });

    setColumns(initMandatoryColumns);
  }, []);

  useEffect(() => {
    const validateGrid = () => {
      let policiesValidationStatus = policies.map(x => !columns
        .filter(c => c.mandatory)
        .some(c => !x[c.field])
      );

      if (!policiesValidationStatus.every(x => x)) {
        return false;
      };

      if (!isDualStampEntity(businessEntity.entityCode)) {
        return true;
      }

      const existingEntities = policies.reduce((entities, policy) =>
        entities.includes(policy.entity) ? entities : [...entities, policy.entity], []);

      return existingEntities.length === 2;
    };

    let gridIsValid = validateGrid();
    setLineOfBusinessState(previous => ({
      ...previous,
      policiesValidData: gridIsValid
    }));
  }, [setLineOfBusinessState, policies, businessEntity.entityCode, columns]);

  const declarationAssuredContainer = useCallback(params => {
    const onAssuredChange = (assured, id) => {
      let updatedPolicies = policiesRef.current.map(p => {
        if (p.id === id) {
          let effectiveFromDate = assured?.effectiveFromDate
            ? toLocalDateTime(new Date(setZeroTime(assured.effectiveFromDate)))
            : null;
          let effectiveToDate = assured?.effectiveToDate
            ? toLocalDateTime(new Date(setZeroTime(assured.effectiveToDate)))
            : null;

          p.declarationAssured = assured?.assured ?? '';
          p.declarationAssuredId = assured?.assuredId;
          p.effectiveFromDate = effectiveFromDate;
          p.effectiveToDate = effectiveToDate;
        }

        return p;
      });

      setLineOfBusinessState(previous => ({ ...previous, policies: updatedPolicies }));

      setColumns(previous => {
        return previous.map(p => {
          if ('forceRerender' in p) {
            p.forceRerender = !p.forceRerender;
          }
          return p;
        });
      });
    }

    const onAssuredSubmit = (response, submitData) => {
      let assured = {
        assured: response.data?.assured,
        assuredId: response.data?.assuredId,
        effectiveFromDate: response.data?.effectiveFromDate,
        effectiveToDate: response.data?.effectiveToDate
      };

      onAssuredChange(assured, submitData.id);

      setColumns(previous => {
        return previous.map(p => {
          if ('forceRerender' in p) {
            p.forceRerender = !p.forceRerender;
          }
          return p;
        });
      });
    }

    const getDeclarationAssuredContainerData = params => {
      let data = policiesRef.current.length > 0 ? policiesRef.current.find(x => x.id === params.id) : [];
      let source = params.colDef.field;
      let id = params.id;
      let value = data[source];

      return { source, id, value };
    }

    let { value, id } = getDeclarationAssuredContainerData(params);

    return (
      <FormControl fullWidth>
        <PickerComponent
          fieldName="assured"
          value={value}
          datasource={Datasource.assuredNames}
          config={{ pickerViewClass: "policies-grid-picker-view" }}
          setBannerState={setBannerState}
          setValidationValue={setValidationValue}
          setLoading={setLoading}
          onChange={value => { onAssuredChange(value, id); }}
          Popup={AssuredPopup}
          onPopupSubmit={onAssuredSubmit}
          submitData={{ id }}
          OptionsHeader={({ className }) => {
            return (
              <Grid container direction="row" spacing={0} className={className}>
                <Grid item xs={8}><div>Assured</div></Grid>
                <Grid item xs={4}><div>Country</div></Grid>
              </Grid>
            );
          }}
          OptionView={({ option, className }) => {
            return (
              <Grid container direction="row" spacing={0} className={className}>
                <Grid item xs={8}><div>{option.assured}</div></Grid>
                <Grid item xs={4}><div>{option.country}</div></Grid>
              </Grid>
            );
          }}
        />
      </FormControl>
    );
  }, [setLineOfBusinessState, setBannerState, setValidationValue, setColumns, setLoading]);

  const getDropdownContainerData = useCallback(params => {
    const id = params.id;
    const policy = policiesRef.current.find(z => z.id === id);
    let column = params.colDef;

    const source = column.field;
    const previousField = column.previousFields[0];
    const isEnable = (isEntityField(source) || policy[previousField] !== '');
    const value = policy ? policy[source] : "";

    let data = [...dropdownsData[column.dataSource]];

    column.previousFields.forEach(field => {
      const selector = isEntityField(field) ? field : `${field}Code`;

      if (policy[selector]) {
        data = data.filter(item => item[selector] === policy[selector]);
      }
    });

    data = data.reduce((array, record) => {
      let code = isEntityField(source) ? record['entityDescription'] : record[`${source}Code`];
      let description = isEntityField(source) ? record[source] : record[`${source}Description`];

      if (!array.some(i => i.code === code)) {
        array.push({ code, description });
      }

      return array;
    }, []);

    return { source, data, id, isEnable, value };
  }, [dropdownsData]);

  useEffect(() => {
    const dropdownContainer = params => {
      let data = getDropdownContainerData(params);
      let options = data.data.map(d => d.description);

      return data.isEnable && (
        <ComponentController
          name={data.source}
          control={control}
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={data.id}
                name={name}
                label={data.value}
                value={data.value}
                data={options}
                onChange={e => { handleChange(e.target.name, e.target.value, data, params.colDef.nextFields); onChange(e); }}
                onBlur={onBlur}
                hideLabel={true}
                shouldSetDefaultValue={params.colDef.mandatory}
              />
            </FormControl>
          }
        />
      );
    };

    let defaultEntity = policies.map(x => x['entity']);
    if (defaultEntity[0] !== '' && !isDualStampEntity(businessEntity.entityCode)) {
      columns.map(x => {
        if (isEntityField(x.field)) {
          x.editable = false;
        }
        if (x.field === 'producingTeam') {
          x.isEnable = true;
        }
        if (x.previousFields?.length) {
          policies
            .filter(policy => x.previousFields.every(f => policy[f]))
            .forEach(policy => {
              const fieldData = getDropdownContainerData({ id: policy.id, colDef: x });
              if (x.mandatory && fieldData.data.length === 1 && fieldData.value !== fieldData.data[0].description) {
                policy[x.field] = fieldData.data[0].description;
                policy[`${x.field}Code`] = fieldData.data[0].code;
                handleChange(x.field, fieldData.data[0].description, fieldData, x.nextFields);
              }
            });
        }
        return x;
      });
    }

    const initDeclarationColumns = column => {
      if (isDeclaration(placingBasisCode)) {
        if (column.declarationAssuredContainer) {
          column.renderEditCell = declarationAssuredContainer;
          column.mandatory = true;
          column.headerName = `Declaration Assured*`;
          column.hide = false;
        } else if (column.declarationTypeContainer) {
          column.mandatory = true;
          column.headerName = `Declaration Type*`;
          column.hide = false;
        }
      } else {
        if (column.declarationAssuredContainer) {
          column.mandatory = false;
          column.hide = true;
        } else if (column.declarationTypeContainer) {
          column.mandatory = false;
          column.hide = true;
        }
      }
    }

    columns.map(c => {
      if (c.renderEditCell && !c.renderCell) {
        c.renderEditCell = dropdownContainer;
      }

      initDeclarationColumns(c);
      return c;
    });

    setColumns(columns);
  }, [businessEntity.entityCode, placingBasis, placingBasisCode,columns, control, dropdownsData.classesOfBusiness, errors,
    getDropdownContainerData, declarationAssuredContainer, handleChange, policies, policyStructure]);

  useEffect(() => {
    const renderCopyButton = cell => (
      <IconButton edge="end" aria-label="copyRow" onClick={() => onCopyRow(cell)}>
        <FileCopy />
      </IconButton>
    );
    
    const renderDeleteButton = cell => (
      <IconButton edge="end" aria-label="delete" onClick={event => onRemoveRow(cell, event)}>
        <DeleteIcon />
      </IconButton>
    );
    
    setColumns(previous => {
      return previous.map(p => {
        if (p.field === "deleteIcon") {
          p.hide = totalNumberOfPolicyReferences <= 1;
          p.renderCell = renderDeleteButton;
        }
        if (p.field === "copyIcon") {
          p.hide = policyStructure === singlePolicyStructure;
          p.renderCell = renderCopyButton;
        }

        return p;
      });
    });
  }, [totalNumberOfPolicyReferences, policyStructure, onCopyRow, onRemoveRow]);

  return (
    <div>
      <DataGrid
        rowHeight={55}
        className={classes.root}
        rows={policiesRef.current}
        columns={columns}
        density="compact"
        hideFooter={true}
        autoHeight={true}
        editMode="row"
        onEditRowsModelChange={handleChange}
      />
    </div>
  );
}

export default PoliciesTable;