import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FormControl, Grid, makeStyles, TextField } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import { useStarAiContext } from 'star-ai';

import {
  BannerType, CheckboxElement, DragAndDropArea, FormButton, FileSection, FormControlWrapper, Dropdown, ComponentController, InformationMessage
} from 'components/common';

import { uploadFile } from 'Api';
import { initDocument, parseMessage } from 'Helpers';
import { BannerValidationMessage, CustomValidationField, DocumentTypes, GeneralDetailsFields } from 'components/SubmissionWizard/Helpers';
import { SectionsFactory } from './Sections';

const useStyles = makeStyles({
  fields: {
    paddingRight: "5rem"
  },
  clearAttachments: {
    width: "100%",
    marginLeft: "1.2rem"
  }
});

export const GeneralDetails = ({
  initialState,
  generalDetailsState,
  renewalState,
  initialRenewalState,
  setGeneralDetailsState,
  forceClearDatasourceState,
  setForceClearDatasourceState,
  setBusinessEntity,
  businessEntity,
  files,
  validationRef,
  setFiles,
  setLoading,
  setBannerState,
  setRenewalState,
  setLineOfBusinessState,
  onOrganisationsDataLoad
}) => {
  const maxPolicyEntryInformationLength = 250;

  const classes = useStyles();
  const { fields, dropdownsData, modellingMajorClasses } = generalDetailsState;
  const { control, register, trigger, reset, resetField, setValue, formState: { errors } } = useForm({
    mode: "onChange",
    defaultValues: { ...fields }
  });

  // star ai
  const ai = useStarAiContext()

  useEffect(() => {
    const isInitialState = Object.keys(fields).every(fieldName => fields[fieldName] === initialState.fields[fieldName]);
    if (isInitialState) {
      reset({ ...initialState.fields });
    }
  }, [fields, initialState, reset]);

  useEffect(() => {
    validationRef.current = async () => {
      let isValid = await trigger();
      if (!isValid) {
        let displayCustomValidation = true;
        for (var error in errors) {
          if (errors.hasOwnProperty(error) && error !== CustomValidationField) {
            displayCustomValidation = false;
            break;
          }
        }

        setBannerState({
          show: true,
          type: BannerType.warning,
          message: (displayCustomValidation && errors[CustomValidationField].message) || BannerValidationMessage
        });
      }

      return isValid;
    };
  }, [errors, fields.documentType, setBannerState, trigger, validationRef]);

  const clearAttachments = useCallback(() => {
    setFiles([]);
  }, [setFiles]);

  const onMailRead = useCallback((event, file) => {
    const messageData = parseMessage(event.target.result, file);
    setFiles(prevFiles => [...prevFiles, messageData]);
  }, [setFiles]);

  const onDocumentRead = useCallback((event, file) => {
    const documentData = initDocument(event.target.result, file);
    setFiles(prevFiles => [...prevFiles, documentData]);
  }, [setFiles]);

  const onFileDrop = useCallback(event => {
    for (let index in event.dataTransfer.files) {
      if (index && event.dataTransfer.files.hasOwnProperty(index)) {
        const file = event.dataTransfer.files[index];
        const reader = new FileReader();
        const fileReadHandler = file.name.endsWith(".msg") ? onMailRead : onDocumentRead;
        reader.onload = event => fileReadHandler(event, file);
        reader.readAsArrayBuffer(file);
      }
    }
  }, [onDocumentRead, onMailRead]);

  const onFieldChange = useCallback((name, value) => {
    setGeneralDetailsState(previous => ({
      ...previous,
      fields: { ...previous.fields, [name]: value ?? '' }
    }));
  }, [setGeneralDetailsState]);

  const onDocumentTypeChange = useCallback(event => {
    let documentType = event.target?.value;

    setGeneralDetailsState(previous => {
      let newDropdownsDataState = {
        ...previous.dropdownsData,
        years: [],
        eclipseEntities: []
      };

      let newFieldsState = {
        ...initialState.fields,
        critical: documentType === DocumentTypes.PromisedLines ? false : previous.fields.critical,
        policyEntryInformation: previous.fields.policyEntryInformation,
        documentType
      };

      reset({ ...newFieldsState });
      return { ...previous, fields: { ...newFieldsState }, dropdownsData: { ...newDropdownsDataState }, modellingMajorClasses: { ...previous.modellingMajorClasses } };
    });

    setRenewalState(previous => ({
      ...previous,
      showUserDialogWarning: false,
      fields: [],
      dropdownsData: [],
      originalRenewals: []
    }));

    setForceClearDatasourceState({ "majorClass": true });
  }, [initialState, setGeneralDetailsState, setRenewalState, setForceClearDatasourceState, reset]);

  const getDocumentTypeError = () => {
    return errors.documentType?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields.documentType}" is required</span>;
  }

  const renderCriticalMonth = () => {
    let render = fields.documentType !== DocumentTypes.PromisedLines;
    return render && (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              name="critical"
              edge="end"
              onChange={(e, checked) => onFieldChange(e.target?.name, checked)}
              checked={fields.critical}
            />
          }
          label={GeneralDetailsFields.criticalMonthEndTask}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderPolicyEntryInformation = () => {
    return (
      <Grid item>
        <ComponentController
          name="policyEntryInformation"
          control={control}
          required
          rules={{ maxLength: maxPolicyEntryInformationLength }}
          render={({ field: { name, onBlur, onChange } }) =>
            <TextField
              id={name}
              name={name}
              label={GeneralDetailsFields[name]}
              fullWidth
              onChange={e => { onFieldChange(name, e.target?.value); onChange(e); }}
              onBlur={onBlur}
              value={fields.policyEntryInformation}
              error={!!errors[name]}
              helperText={<InformationMessage
                fieldName={GeneralDetailsFields.policyEntryInformation}
                fieldErrors={errors.policyEntryInformation}
                maxLength={maxPolicyEntryInformationLength}
                showLengthInfo={true}
                length={fields.policyEntryInformation.length}
              />}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
        />
      </Grid>
    );
  }

  const renderDocumentType = () => {
    return (
      <Grid item>
        <ComponentController
          name="documentType"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                value={fields.documentType}
                data={dropdownsData.documentTypes.map(x => x.name)}
                onChange={e => { onDocumentTypeChange(e); onChange(e); }}
                onBlur={onBlur}
                errorText={getDocumentTypeError()}
                inputRef={ai?.fieldsRef?.[name]}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  const renderDocumentTypeSection = () => {
    return (
      <SectionsFactory
        fields={fields}
        dropdownsData={dropdownsData}
        modellingMajorClasses={modellingMajorClasses}
        initialFormState={initialState}
        renewalState={renewalState}
        initialRenewalState={initialRenewalState}
        setBusinessEntity={setBusinessEntity}
        businessEntity={businessEntity}
        control={control}
        errors={errors}
        setValidationValue={setValue}
        register={register}
        trigger={trigger}
        setFormState={setGeneralDetailsState}
        setGeneralDetailsState={setGeneralDetailsState}
        setLoading={setLoading}
        setBannerState={setBannerState}
        validationRef={validationRef}
        setRenewalState={setRenewalState}
        resetValidationState={reset}
        resetFieldValidationState={resetField}
        setLineOfBusinessState={setLineOfBusinessState}
        forceClearDatasourceState={forceClearDatasourceState}
        setForceClearDatasourceState={setForceClearDatasourceState}
        onOrganisationsDataLoad={onOrganisationsDataLoad}
      />
    );
  }

  const renderAttachmentsArea = () => {
    return <>
      <Grid item>
        <DragAndDropArea text="Drag and Drop Email/Document" onFileDrop={onFileDrop} />
      </Grid>
      <Grid item>
        <FormButton endIcon={<Clear />} onClick={clearAttachments} className={classes.clearAttachments}>
          Clear Attachments
        </FormButton>
      </Grid>
      <Grid item>
        <FileSection files={files} setFiles={setFiles} uploadFile={uploadFile} setBannerState={setBannerState} />
      </Grid>
    </>;
  }

  return <>
    <Grid item container xs={6} direction="column">
      <form className={classes.fields}>
        <Grid item container direction="column" spacing={2}>
          {renderCriticalMonth()}
          {renderPolicyEntryInformation()}
          {renderDocumentType()}
          {renderDocumentTypeSection()}
        </Grid>
      </form>
    </Grid>
    <Grid item container xs={6} direction="column" spacing={3}>
      {renderAttachmentsArea()}
    </Grid>
    <Grid id="portal-root" item container xs={12} direction="column" spacing={3}>
    </Grid>
  </>;
}