import React, { useState, useEffect } from "react";

import { Grid, TextField, FormControl } from "@material-ui/core";
import AssuredAutocomplete from "./AssuredAutocomplete";
import BrokerAutocomplete from "./BrokerAutocomplete";
import BrokerBranchAutocomplete from "./BrokerBranchAutocomplete";
import BrokerContactAutocomplete from "./BrokerContactAutocomplete";
import { DatePickerElement, Dropdown, CheckboxElement, FormControlWrapper, BannerType, MultiDropdown } from "components/common";

import { fetchMinorClasses, fetchProductPlacingBases, fetchUWGroupMembers, fetchPlacingBases, fetchBrokerBranchTerritory, fetchProducingTeams } from "components/SubmissionForm/Form.api";
import {
  validateRequiredField, validateNumericField,
  validateRenewedPolicy, validateDocumentTypeAndPlacingBasis, validateEmailField,
  validateDatePicker, validateFieldLength, validateEffectiveDate
} from "components/SubmissionForm/Validation";
import {
  isTotalNumberOfPolicyReferencesEnabled, isPolicyReferenceNumberEnabled,
  isExpiringPolicyReferenceNumberEnabled, getMinorClassDefaultValue, isTypeOfModellingRiskEnabled,
  areMinorClassesUsed, isFinalPricingCompletedEnabled, getPlacingBasisDefaultValue, isProducingTeamUsed,
  isNewYorkMgaSectionEnabled, isPremiumEndorsement, isEeaBusinessEnabled, isMajorClassRequiredModelling,
  disableDocumentType, isCommentEnabled, isBrokerSectionEnabled, isStatesSectionEnabled, isNYEnergyEnviroWT,
  isMiamiSubmission, isNYSubmission, isAssuredAddressFilled, isHamiltonSelectSubmission, isPropertyDnF,
  getAssignedUWDefaultValueandName
} from "components/SubmissionForm/Helpers";

export const MGASection = props => {
  const {
    fieldsRef,
    fieldsHints,
    fieldsState,
    majorClassDetails,
    dropdownsData,
    validationState,
    setFieldsState,
    setValidationState,
    setDropdownsData,
    setBannerState,
    setLoading
  } = { ...props };

  const [policyReferencesList, setPolicyReferencesList] = useState([]);
  const [renewalChecked, setRenewalChecked] = useState(false);
  const [newBusinessChecked, setNewBusinessChecked] = useState(false);
  const NEW_YORK_MGA = ["New York MGA"];
  const ENTITY_PROPERTY = "PropertyDnF";

  // When a user reselects the major class in the form, then UI changes the assignedUW
  useEffect(() => {
    if (
      majorClassDetails
      && "assignedUW" in fieldsState 
      && !fieldsState.assignedUW
      && dropdownsData.assignedUWs?.length > 0
    ) {    
      const value = majorClassDetails.assignedUW;
      let assignedUWName = dropdownsData.assignedUWs?.find(a => a.mail === value)?.displayName || null;
    
      setFieldsState({
        ...fieldsState,
        assignedUW: value,
        assignedUWName
      });

      validateAssignedUW(value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [majorClassDetails, dropdownsData.assignedUWs, fieldsState.assignedUW]);

  useEffect(() => {
    if (dropdownsData.assignedUWs?.length > 0 && fieldsState.firstMajorClass === ENTITY_PROPERTY) {
      let assignedUWDef = getAssignedUWDefaultValueandName(fieldsState.businessEntity, fieldsState.firstMajorClass, dropdownsData.assignedUWs);

      setFieldsState({
        ...fieldsState,
        assignedUW: assignedUWDef.mail,
        assignedUWName: assignedUWDef.displayName
      });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownsData.assignedUWs])

  const validateMajorClass = (value, entityName) => {
    var result = validateRequiredField("Major Class", value);
    var majorClassesValidationState = { ...validationState.fields.majorClasses };
    majorClassesValidationState[entityName] = result;

    setValidationState(prevState => {
      return {
        ...prevState,
        fields: {
          ...prevState.fields,
          majorClasses: majorClassesValidationState
        }
      }
    });
  };

  const validateYearOfAccount = value => {
    let result = validateRequiredField("YOA", value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        yearOfAccount: result
      }
    });
  };

  const validateState = value => {
    let result = validateRequiredField("State", value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        state: result
      }
    });
  };

  const validateMinorClass = value => {
    let result = validateRequiredField("Minor Class", value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        minorClass: result
      }
    });
  };

  const validatePlacingBasis = value => {
    let result = validateRequiredField("Placing Basis", value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        placingBasis: result
      }
    });
  };

  const validatePolicyReference = value => {
    let result = validateRequiredField("Policy Reference Number", value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        policyReferenceNumber: result
      }
    });
  };

  const validateProducingTeam = value => {
    let result = validateRequiredField("Producing Team", value);
    setValidationState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        producingTeam: result
      }
    }));
  };

  const validateAssignedUW = value => {
    let result = validateRequiredField("Assigned UW", value);
    setValidationState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        assignedUW: result
      }
    }));
  };

  const getUWGroupMembers = async entity => {
    let uwGroupMembersResponse = await fetchUWGroupMembers(entity);
    if (!uwGroupMembersResponse.success) {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: uwGroupMembersResponse.errorMessage
      });

      return [];
    }

    return uwGroupMembersResponse.data ?? [];
  };

  const getPlacingBases = async (entity, majorClass = null) => {
    let placingBasesResponse = await fetchPlacingBases(entity);
    if (!placingBasesResponse?.success) {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: placingBasesResponse.errorMessage
      });

      return [];
    }
    const selectedPB = ["US Direct", "US Fac RI", "Not Applicable"];

    if (entity === "New York - S4000") {
      return placingBasesResponse.data.filter(item => majorClass === ENTITY_PROPERTY ? selectedPB.includes(item.name) : !selectedPB.includes(item.name))
    }

    return placingBasesResponse?.data ?? [];
  };

  const getProducingTeams = async (entity, majorClass) => {
    let producingTeamsResponse = await fetchProducingTeams(entity);

    if (!producingTeamsResponse?.success) {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: producingTeamsResponse.errorMessage
      });

      return [];
    }

    if (entity === "New York - S4000" && majorClass === ENTITY_PROPERTY) {
      return NEW_YORK_MGA;
    }

    return producingTeamsResponse?.data ?? [];
  }

  const getTerritories = () => {
    return ["EAST", "WEST"];
  }

  const onMajorClassChanged = async event => {
    let value = event.target.value;
    let currentMajorClasses = { ...fieldsState.majorClasses };
    currentMajorClasses[fieldsState.businessEntity] = value;
    let placingBases = await getPlacingBases(fieldsState.businessEntity, value);
    let producingTeams = await getProducingTeams(fieldsState.businessEntity, value);

    setDropdownsData({
      ...dropdownsData,
      producingTeams,
      placingBases,
      preRenewal: false,
      typeOfBusiness: '',
      territory: ''
    });

    let placingBasis = getPlacingBasisDefaultValue(fieldsState.businessEntity, value, dropdownsData.placingBases);    
    setFieldsState({
      ...fieldsState,
      firstMajorClass: value,
      minorClass: '',
      class: '',
      requiredModellingPriority: isMajorClassRequiredModelling(value, fieldsState.businessEntity, dropdownsData) ? 'No Modelling Required' : '',
      majorClasses: currentMajorClasses,
      documentType: fieldsState.documentType,
      placingBasis: placingBasis,
      expiringPolicyReferenceNumber: '',
      assured: '',
      isNewAssured: false,
      assuredMiami: '',
      miamiCedant: '',
      miamiCedantCountry: '',
      miamiCedantCountryCode: '',
      miamiCedantText: '',
      preRenewal: false,
      hasExpiringPolicies: false,
      typeOfBusiness: ''
    });

    validateMajorClass(value, fieldsState.businessEntity);

    if (!!fieldsState.documentType) {
      await changeDocumentType(fieldsState.documentType);
      await tryLoadProductPlacingBases(fieldsState.documentType, value);
    }

    await tryLoadMinorClasses(fieldsState.documentType, value);    
  };

  const onMajorClassBlur = (event, entityName) => {
    validateMajorClass(event?.target?.value, entityName);
  };

  const onYearOfAccountChanged = event => {
    setFieldsState({
      ...fieldsState,
      yoa: event.target.value,
      expiringPolicyReferenceNumber: '',
      assured: '',
      assuredMiami: '',
      miamiCedant: '',
      miamiCedantCountry: '',
      miamiCedantCountryCode: '',
      miamiCedantText: '',
      isNewAssured: false,
      preRenewal: false,
      hasExpiringPolicies: false,
      typeOfBusiness: ''
    });

    validateYearOfAccount(event.target.value);
  };

  const onYearOfAccountBlur = event => {
    validateYearOfAccount(event?.target?.value);
  };

  const onStateChanged = event => {
    setFieldsState({
      ...fieldsState,
      state: event.target.value
    });

    validateState(event.target.value);
  };

  const onStateBlur = event => {
    validateState(event?.target?.value);
  };

  const onDocumentTypeChanged = async event => {
    await changeDocumentType(event.target.value);
    await tryLoadMinorClasses(event.target.value, fieldsState.firstMajorClass);
    await tryLoadProductPlacingBases(event.target.value, fieldsState.firstMajorClass);
  }

  const tryLoadMinorClasses = async (documentType, majorClass) => {
    if (!!majorClass) {
      let minorClass = "";
      let minorClassesResponse = {};

      if (areMinorClassesUsed(documentType, fieldsState.businessEntity, majorClass, dropdownsData)) {
        setLoading(true);

        minorClassesResponse = await fetchMinorClasses(fieldsState.businessEntity, majorClass, documentType);

        if (!minorClassesResponse.success) {
          setBannerState({
            show: true,
            type: BannerType.error,
            message: minorClassesResponse.errorMessage
          });

          return;
        }

        minorClass = getMinorClassDefaultValue(
          fieldsState.businessEntity, majorClass, minorClassesResponse.data);

        setValidationState(prevState => ({
          ...prevState,
          minorClass: { valid: true, message: "" },
          preRenewal: false,
          typeOfBusiness: '',
          territory: ''
        }));

        setLoading(false);
      }

      setDropdownsData(prevState => ({
        ...prevState,
        minorClasses: minorClassesResponse.data ?? []
      }));

      let showMgaSection = isNewYorkMgaSectionEnabled(documentType, fieldsState.documentTypesList);
      if (showMgaSection) {
        let uwGroupMembers = await getUWGroupMembers(fieldsState.businessEntity);
        let territoriesInfo = getTerritories();

        setDropdownsData(prevState => ({
          ...prevState,
          assignedUWs: uwGroupMembers,
          territories: territoriesInfo
        }));
      }

      fieldsState.minorClass = minorClass;
    }
  }

  const tryLoadProductPlacingBases = async (documentType, majorClass) => {
    if (!!majorClass) {
      let placingBasesResponse = {};

      if (!areMinorClassesUsed(documentType, fieldsState.businessEntity, majorClass, dropdownsData)) {
        setLoading(true);

        placingBasesResponse = await fetchProductPlacingBases(fieldsState.businessEntity, majorClass, documentType);

        if (!placingBasesResponse.success) {
          setBannerState({
            show: true,
            type: BannerType.error,
            message: placingBasesResponse.errorMessage
          });

          return;
        }

        setLoading(false);
      }

      setDropdownsData(prevState => ({
        ...prevState,
        productPlacingBases: placingBasesResponse.data ?? []
      }));

      let showMgaSection = isNewYorkMgaSectionEnabled(documentType, fieldsState.documentTypesList);
      if (showMgaSection) {
        let uwGroupMembers = await getUWGroupMembers(fieldsState.businessEntity);
        let territoriesInfo = getTerritories();
        setDropdownsData(prevState => ({
          ...prevState,
          assignedUWs: uwGroupMembers,
          territories: territoriesInfo
        }));
      }
    }
  }

  const changeDocumentType = async newDocumentType => {
    let totalNumberOfPolicyReferences = isTotalNumberOfPolicyReferencesEnabled(fieldsState.documentTypesList, newDocumentType)
      ? fieldsState.totalNumberOfPolicyReferences : 1;

    let typeOfModellingRisk = isTypeOfModellingRiskEnabled(newDocumentType, fieldsState.documentTypesList)
      ? "Pre-Live Risk" : "";

    let result = validateRenewedPolicy(fieldsState.renewedPolicy, newDocumentType, fieldsState.documentTypesList);
    setValidationState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        renewedPolicy: result
      }
    }));

    result = validateRequiredField("Type Of Document", newDocumentType);
    if (!result.valid) {
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          typeOfDocument: result
        }
      }));
    }
    else {
      result = validateDocumentTypeAndPlacingBasis(newDocumentType, fieldsState.placingBasis, fieldsState.documentTypesList);
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          typeOfDocument: result,
          placingBasis: result
        }
      }));
    }    

    setFieldsState((prevState) => ({
      ...fieldsState,
      ...prevState,
      documentType: newDocumentType,
      assignedUW: '',
      product: null,
      broker: null,
      brokerBranch: null,
      brokerContact: null,
      effectiveDate: null,
      quoteBy: null,
      policyReferenceNumber: '',
      totalNumberOfPolicyReferences: totalNumberOfPolicyReferences,
      expiringPolicyReferenceNumber: '',
      typeOfModellingRisk: typeOfModellingRisk,
      assured: '',
      assuredMiami: '',
      assuredId: null,
      isNewAssured: false,
      comments: '',
      miamiCedant: '',
      miamiCedantCountry: '',
      miamiCedantCountryCode: '',
      miamiCedantText: '',
      preRenewal: false,
      hasExpiringPolicies: false,
      typeOfBusiness: '',
      territory: ''
    }));
  };

  const onPremiumEndorsementChecked = checked => {
    setFieldsState({
      ...fieldsState,
      premiumEndorsement: checked
    });
  };

  const onDocumentTypeBlur = (event) => {
    const documentType = event?.target?.value;
    let result = validateRequiredField("Type Of Document", documentType);
    if (!result.valid) {
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          typeOfDocument: result
        }
      }));
    }
    else {
      result = validateDocumentTypeAndPlacingBasis(documentType, fieldsState.placingBasis, fieldsState.documentTypesList);
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          typeOfDocument: result,
          placingBasis: result
        }
      }));
    }
  };

  const selectPlacingBasis = minorClass => {
    let placingBasis = '';
    if (minorClass.includes("Excess")) {
      placingBasis = fieldsState.defaultPlacingBasis.excess;
    } else {
      placingBasis = fieldsState.defaultPlacingBasis.primary;
    }

    setFieldsState(previousState => ({
      ...previousState,
      placingBasis: dropdownsData.placingBases?.find(basis => basis.name === placingBasis).name || null
    }));
  }

  const onMinorClassChanged = event => {
    let minorClass = event.target?.value;
    let minorClassData = dropdownsData.minorClasses?.find(m => m.minorClass === minorClass) || null; 

    if (fieldsState.defaultPlacingBasis) {
      selectPlacingBasis(minorClass);
    }

    setFieldsState(previousState => ({
      ...previousState,
      minorClass,
      class: '',
      product: {
        productId: minorClassData.productId,
        productCode: minorClassData.productCode,
      },
      broker: null,
      brokerBranch: null,
      brokerContact: null
    }));

    validateMinorClass(minorClass);
  };

  const onMultiMinorClassChanged = event => {
    let minorClasses = event.target?.value;

    if (minorClasses.length > 0) {
      let minorClassData = dropdownsData.minorClasses?.find(m => m.minorClass === minorClasses[0]) || null;

      if (fieldsState.defaultPlacingBasis) {
        selectPlacingBasis(minorClasses[0]);
      }

      let secondMinorClass = '';
      let minorClassData2 = null;
      if (minorClasses.length > 1) {
        secondMinorClass = minorClasses[1];
        minorClassData2 = dropdownsData.minorClasses?.find(m => m.minorClass === minorClasses[1]) || null;
      }

      setFieldsState(previousState => ({
        ...previousState,
        minorClass: minorClasses[0],
        minorClass2: secondMinorClass,
        class: '',
        product: {
          productId: minorClassData.productId,
          productCode: minorClassData.productCode,
          productId2: minorClassData2 != null ? minorClassData2.productId : 0,
          productCode2: minorClassData2 != null ? minorClassData2.productCode : ''
        },
        broker: null,
        brokerBranch: null,
        brokerContact: null
      }));

      console.log(fieldsState);
      validateMinorClass(minorClasses[0]);
    }

  };

  const onMultiMinorClassBlur = event => {
    let minorClassValue = event?.target?.value;
    validateMinorClass(minorClassValue[0]);
    console.log(fieldsState);
  };

  const onProductPlacingBasisChanged = event => {
    let placingBasis = event.target?.value;
    let placingBasisData = dropdownsData.productPlacingBases?.find(m => m.placingBasis === placingBasis) || null;

    setFieldsState(previousState => ({
      ...previousState,
      placingBasis: placingBasis,
      product: {
        productId: placingBasisData.productId,
        productCode: placingBasisData.productCode,
      },
      broker: null,
      brokerBranch: null,
      brokerContact: null
    }));

    validatePlacingBasis(placingBasis);
  }

  const onMinorClassBlur = event => {
    let minorClassValue = event?.target?.value;
    validateMinorClass(minorClassValue);
  };

  const onPolicyReferenceChanged = event => {
    let policyReference = event?.target?.value;
    setFieldsState({
      ...fieldsState,
      policyReferenceNumber: policyReference,
      expiringPolicyReferenceNumber: policyReference
    });

    validatePolicyReference(policyReference);
  };

  const onPolicyReferenceBlur = event => {
    validatePolicyReference(event?.target?.value);
  };

  const onPlacingBasisChanged = event => {
    const newPlacingBasis = event.target.value;
    setFieldsState({
      ...fieldsState,
      placingBasis: newPlacingBasis
    });

    let result = validateRequiredField("Placing Basis", newPlacingBasis);
    if (!result.valid) {
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields, placingBasis: result
        }
      }));
    }
    else {
      result = validateDocumentTypeAndPlacingBasis(fieldsState.documentType, newPlacingBasis, fieldsState.documentTypesList);
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields, typeOfDocument: result, placingBasis: result
        }
      }));
    }
  };

  const onPlacingBasisBlur = (event) => {
    const placingBasis = event?.target?.value;
    let result = validateRequiredField("Placing Basis", placingBasis);
    if (!result.valid) {
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          placingBasis: result
        }
      }));
    }
    else {
      result = validateDocumentTypeAndPlacingBasis(fieldsState.documentType, placingBasis, fieldsState.documentTypesList);
      setValidationState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          typeOfDocument: result,
          placingBasis: result
        }
      }));
    }
  };

  const onProducingTeamChanged = event => {
    let value = event.target?.value;
    setFieldsState({
      ...fieldsState,
      producingTeam: value
    });
  };

  const onProducingTeamBlur = event => {
    validateProducingTeam(event?.target?.value);
  };

  const onBrokerContactEmailChanged = event => {
    let value = event?.target?.value;
    setFieldsState(previousState => ({
      ...previousState,
      brokerContact: {
        ...previousState.brokerContact,
        email: value
      }
    }));
  };

  const onAssuredMiamiChanged = event => {
    setFieldsState({
      ...fieldsState,
      assuredMiami: event.target?.value
    });
  };
  const onMiamiCedantChanged = async (event) => {

    let miamiCedantsResponse = dropdownsData.miamiCedants?.find(m => `${m.companyName} | ${m.countryName}` === event.target?.value) || null;

    let miamiCedantsCountriesResponse = (miamiCedantsResponse && dropdownsData.miamiCedantsCountries?.find(m => m.description === miamiCedantsResponse.countryName)) || null;

    setFieldsState({
      ...fieldsState,
      miamiCedant: miamiCedantsResponse.companyName,
      miamiCedantCountry: miamiCedantsCountriesResponse.description,
      miamiCedantCountryCode: miamiCedantsCountriesResponse.code,
      miamiCedantText: event.target?.value,
    });

  }

  const onBrokerContactEmailBlur = event => {
    let value = event?.target?.value;

    let result = validateRequiredField("Broker Contact Email", value);
    if (result.valid) {
      result = validateEmailField("Broker Contact Email", value);
    }

    setValidationState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        brokerContactEmail: result
      }
    }));
  };

  const onAssignedUWChanged = event => {
    let value = event?.target?.value;
    let assignedUWName = dropdownsData.assignedUWs?.find(a => a.mail === value)?.displayName || null;
    
    setFieldsState({
      ...fieldsState,
      assignedUW: value,
      assignedUWName
    });

    validateAssignedUW(value);
  };

  const onAssignedUWBlur = event => {
    validateAssignedUW(event?.target?.value);
  };

  const onEffectiveDateChanged = value => {
    let result = validateEffectiveDate(fieldsState, value);

    setValidationState(validState => ({
      ...validState,
      fields: {
        ...validState.fields,
        effectiveDate: result
      }
    }));

    setFieldsState(prevState => ({
      ...prevState,
      effectiveDate: value
    }));
  };

  const onQuoteByChanged = value => {
    let result = validateDatePicker(value);

    setValidationState(validState => ({
      ...validState,
      fields: {
        ...validState.fields,
        quoteBy: result
      }
    }));

    setFieldsState(prevState => ({
      ...prevState,
      quoteBy: value
    }));
  };

  const onTotalNumberOfPolicyReferencesChanged = event => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      setFieldsState({
        ...fieldsState,
        totalNumberOfPolicyReferences: event.target.value
      });
    }
  };

  const onTotalNumberOfPolicyReferencesBlur = event => {
    let result = validateNumericField("Total Number of Policy References/Declarations", event.target?.value);
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        totalNumberOfPolicyReferences: result
      }
    });
  };

  const onExpiringPolicyReferenceNumberDropdownChanged = event => {
    let value = event?.target?.value;
    setFieldsState(prevState => ({
      ...prevState,
      expiringPolicyReferenceNumber: value
    }));
  };

  const onRequiredModellingPriorityChanged = event => {
    setFieldsState({
      ...fieldsState,
      requiredModellingPriority: event.target.value
    });
  };

  const onFinalPricingCompletedChecked = checked => {
    setFieldsState({
      ...fieldsState,
      finalPricingCompleted: checked
    });
  };

  const onBlockedSubmissionChecked = checked => {
    setFieldsState({
      ...fieldsState,
      blockedSubmission: checked
    });
  };

  const onPreRenewalChecked = checked => {
    setFieldsState({
      ...fieldsState,
      preRenewal: checked,
      expiringPolicyReferenceNumber: ''
    });
  }

  const onTerritoryChanged = event => {
    setFieldsState({
      ...fieldsState,
      territory: event.target.value
    });
  }

  const onNewBusinessChecked = () => {
    setFieldsState({
      ...fieldsState,
      typeOfBusiness: 'New' 
    });
    setRenewalChecked(false);
    setNewBusinessChecked(true);
  }

  const onRenewalChecked = () => {
    setFieldsState({
      ...fieldsState,
      typeOfBusiness: 'Renew'
    });
    setRenewalChecked(true);
    setNewBusinessChecked(false);
  }

  const onPricingNotRequiredChecked = checked => {
    setFieldsState({
      ...fieldsState,
      pricingNotRequired: checked
    });
  };

  const onEeaBusinessChecked = checked => {
    setFieldsState({
      ...fieldsState,
      eeaBusiness: checked
    });
  };

  const onTypeOfModellingRiskChanged = event => {
    setFieldsState({
      ...fieldsState,
      typeOfModellingRisk: event.target.value
    });
  };

  const onCommentsChanged = event => {
    setFieldsState({
      ...fieldsState,
      comments: event.target?.value
    });
  };

  const onCommentsBlur = event => {
    let result = validateFieldLength("Comments", event?.target?.value);
    setValidationState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        comments: result
      }
    }));
  };

  const renderMajorClasses = () => {
    return (
      <Grid item>
        <FormControl
          fullWidth
          required
          error={validationState.fields.majorClasses[fieldsState.businessEntity]?.valid === false}>
          <Dropdown
            id="majorClass"
            inputRef={fieldsRef?.["firstMajorClass"]}
            label="Major Class"
            value={
              fieldsState?.majorClasses?.[fieldsState.businessEntity]
                ? fieldsState.majorClasses[fieldsState.businessEntity]
                : ''}
            data={dropdownsData.majorClasses?.map(d => d.name) || []}
            onChange={(event) => onMajorClassChanged(event)}
            onBlur={(event) => onMajorClassBlur(event, fieldsState.businessEntity)}
            disabled={fieldsState.businessEntity === ''}
            errorText={validationState.fields.majorClasses[fieldsState.businessEntity]?.message}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderMinorClass = () => {

    let dropControl;
    if (fieldsState.firstMajorClass === "Energy" && fieldsState.businessEntity === "Hamilton Select") {
      dropControl = <MultiDropdown
        id="minorClass2"
        inputRef={fieldsRef?.['minorClass']}
        label="Minor Class"
        value={fieldsState.minorClass}
        data={dropdownsData.minorClasses?.map(m => m.minorClass) || []}
        disabled={!dropdownsData.minorClasses || dropdownsData.minorClasses?.length === 0}
        //renderValue={(selected) => selected.join(', ')}
        onChange={onMultiMinorClassChanged}
        onBlur={onMultiMinorClassBlur}
        errorText={validationState.fields.minorClass.message}
      />;
    }
    else {
      dropControl = <Dropdown
        id="minorClass"
        inputRef={fieldsRef?.['minorClass']}
        label="Minor Class"
        value={fieldsState.minorClass}
        data={dropdownsData.minorClasses?.map(m => m.minorClass) || []}
        disabled={!dropdownsData.minorClasses || dropdownsData.minorClasses?.length === 0}
        onChange={onMinorClassChanged}
        onBlur={onMinorClassBlur}
        errorText={validationState.fields.minorClass.message}
      />;
    }

    return areMinorClassesUsed(fieldsState.documentType, fieldsState.businessEntity, fieldsState.firstMajorClass, dropdownsData) && (
      <Grid item>
        <FormControl
          fullWidth
          required
          error={validationState.fields.minorClass.valid === false}>
          {dropControl}
        </FormControl>
      </Grid>
    );
  };

  const renderRequiredModellingPriority = () => {

    let render = !isMiamiSubmission(fieldsState.businessEntity);

    return render && isMajorClassRequiredModelling(fieldsState.firstMajorClass, fieldsState.businessEntity, dropdownsData) && (
      <Grid item>
        <FormControl fullWidth>
          <Dropdown
            id="requiredModellingPriority"
            label="Required Modelling Priority"
            value={fieldsState.requiredModellingPriority}
            data={["No Modelling Required", "Low", "Medium", "High"]}
            onChange={onRequiredModellingPriorityChanged}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderFinalPricingCompleted = () => {
    let render = isFinalPricingCompletedEnabled(fieldsState);
    return render && (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              onChange={(event, checked) => onFinalPricingCompletedChecked(checked)}
              checked={fieldsState.finalPricingCompleted}
            />
          }
          label="Has Final Pricing Been Completed"
          labelPlacement="start"
        />
      </Grid>
    );
  };

  const renderPricingNotRequired = () => {
    let render = fieldsState.documentTypesList
      ?.find(document => document.name === fieldsState.documentType)?.isPricingNotRequiredEnabled || null;

    return render && (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              onChange={(event, checked) => onPricingNotRequiredChecked(checked)}
              checked={fieldsState.pricingNotRequired}
            />
          }
          label="Pricing Not Required"
          labelPlacement="start"
        />
      </Grid>
    );
  };

  const renderEeaBusiness = () => {
    let render = isEeaBusinessEnabled(dropdownsData, fieldsState.businessEntity, fieldsState.documentType);
    if (!render && fieldsState.eeaBusiness) {
      onEeaBusinessChecked(false);
    }

    return render && (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              onChange={(_event, checked) => onEeaBusinessChecked(checked)}
              checked={fieldsState.eeaBusiness}
            />
          }
          label="EEA Business"
          labelPlacement="start"
        />
      </Grid>
    );
  };

  const renderTypeOfModellingRisk = () => {
    const render = isTypeOfModellingRiskEnabled(fieldsState.documentType, fieldsState.documentTypesList);
    return render && (
      <Grid item>
        <FormControl fullWidth>
          <Dropdown
            id="typeOfModellingRisk"
            label="Type Of Modelling Risk"
            value={fieldsState.typeOfModellingRisk}
            data={["Live Risk", "Pre-Live Risk"]}
            onChange={onTypeOfModellingRiskChanged}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderProducingTeam = () => {
    return isProducingTeamUsed(fieldsState.documentType, fieldsState.businessEntity, fieldsState.firstMajorClass, dropdownsData) && (
      <Grid item>
        <FormControl fullWidth required error={!validationState.fields.producingTeam.valid}>
          <Dropdown
            id="producingTeam"
            label="Producing Team"
            value={fieldsState.producingTeam}
            data={dropdownsData.producingTeams}
            onChange={onProducingTeamChanged}
            onBlur={onProducingTeamBlur}
            errorText={validationState.fields.producingTeam.message}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderAssignedUW = () => {
    return <Grid item>
      <FormControl fullWidth required error={!validationState.fields.assignedUW.valid}>
        <Dropdown
          id="assignedUW"
          inputRef={fieldsRef?.["assignedUW"]}
          label="Assigned UW"
          value={fieldsState.assignedUW}
          data={dropdownsData.assignedUWs?.map(a => a.mail) || []}
          disabled={!dropdownsData?.assignedUWs || dropdownsData.assignedUWs.length === 0}
          onChange={onAssignedUWChanged}
          onBlur={onAssignedUWBlur}
          errorText={validationState.fields.assignedUW.message}
        />
      </FormControl>
    </Grid>;
  };

  const renderEffectiveDate = () => {
    return <Grid item>
      <DatePickerElement
        id="effectiveDate"
        inputRef={fieldsRef?.["effectiveDate"]}
        label="Effective Date"
        required
        fullWidth
        format="MM/dd/yyyy"
        value={fieldsState.effectiveDate}
        onChange={onEffectiveDateChanged}
        error={!validationState.fields.effectiveDate.valid}
        helperText={validationState.fields.effectiveDate.message}
      />
    </Grid>;
  }

  const renderQuoteBy = () => {
    let render = !isMiamiSubmission(fieldsState.businessEntity);
    return render && <Grid item>
      <DatePickerElement
        id="quoteBy"
        inputRef={fieldsRef?.["quoteBy"]}
        label="Quote By"
        fullWidth
        format="MM/dd/yyyy"
        value={fieldsState.quoteBy}
        onChange={onQuoteByChanged}
        error={!validationState.fields.quoteBy.valid}
        helperText={validationState.fields.quoteBy.message}
      />
    </Grid>;
  }

  const renderAssured = () => {
    let entityData = dropdownsData.entities[fieldsState.businessEntity];    
    let render = !isMiamiSubmission(fieldsState.businessEntity);
    return render && (
      <Grid item>
        <AssuredAutocomplete
          inputRef={fieldsRef?.["assured"]}
          hintText={fieldsHints?.['assured']}
          fieldsState={fieldsState}
          entityData={entityData}
          validationState={validationState}
          setFieldsState={setFieldsState}
          setPolicyReferencesList={setPolicyReferencesList}
          setValidationState={setValidationState}
          setBannerState={setBannerState}
          setLoading={setLoading}
        />        
      </Grid>
    );
  };
  const renderAssuredMiami = () => {

    let render = isMiamiSubmission(fieldsState.businessEntity);

    return render && (

      <Grid item>
        <TextField
          id="assignedMiami"
          label="Insured (Miami)"
          required
          fullWidth
          value={fieldsState.assuredMiami ?? ""}
          onChange={onAssuredMiamiChanged}
        />
      </Grid>
      )
  }
  const renderAssuredAddress = () => {
    let render = !isMiamiSubmission(fieldsState.businessEntity) && isAssuredAddressFilled(fieldsState.assuredAddress);
    const isTownIncludedinCity = fieldsState.isNewAssured ? false : ((fieldsState.assuredAddress !== undefined && fieldsState.assuredAddress !== null) ? (fieldsState.assuredAddress.city.includes(fieldsState.assuredAddress.state)) : false);
    return render && (
      <Grid item xs={12}>
        <p>Address:</p>
        <p>{fieldsState.assuredAddress.line1} {fieldsState.assuredAddress.line2} {fieldsState.assuredAddress.line3}</p>
        <p>{fieldsState.assuredAddress.city} {!isTownIncludedinCity ? (fieldsState.assuredAddress.state) : ('')} {fieldsState.assuredAddress.zipCode}</p>
        <p>{fieldsState.assuredAddress.countryCode}</p>
      </Grid>
    )
  }

  const renderMiamiCedants = () => {

    let render = isMiamiSubmission(fieldsState.businessEntity);

    return render && (
      <Grid item>
        <FormControl fullWidth>
          <Dropdown
            id="miamiCedants"
            label="Cedant"                  
            data={dropdownsData.miamiCedants?.map(d => d.companyName + ' | ' + d.countryName) || []}
            value={fieldsState.miamiCedantText ?? "" }
            onChange={onMiamiCedantChanged}
            disabled={fieldsState.businessEntity === ''}
          />
        </FormControl>
      </Grid>
    )
  }

  const renderBlockedSubmission = () => {    
    let render = !fieldsState.isNewAssured && (fieldsState.assuredId != null) && (isNYEnergyEnviroWT(fieldsState.businessEntity, fieldsState.firstMajorClass));

    return render && (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              onChange={(event, checked) => onBlockedSubmissionChecked(checked)}
              checked={fieldsState.blockedSubmission}
            />
          }
          label="Block Submission"
          labelPlacement="start"
        />
      </Grid>      
      );
  };

  const renderTypeOfBusiness = () => {
    let render = (fieldsState.assuredId !== null) && (isNYSubmission(fieldsState.businessEntity));

    return render && (
      <Grid container spacing={2}>
        <Grid item xs={4} style={{marginLeft: '12px'}}>
          <FormControlWrapper
            control={
              <CheckboxElement
                edge="end"
                onChange={onNewBusinessChecked}
                checked={newBusinessChecked}
              />
            }
            label="New Business"
            labelPlacement="start"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlWrapper
            control={
              <CheckboxElement
                edge="end"
                onChange={onRenewalChecked}
                checked={renewalChecked}
              />
            }
            label="Renewal"
            labelPlacement="start"
          />
        </Grid>
        <Grid item xs={3}>
          <div className="MuiFormHelperText-root Mui-error">
            {validationState.fields.typeOfBusiness?.message}
          </div>
        </Grid>
      </Grid>
    );
  };


  const renderPreRenewal = () => {
    let render = isExpiringPolicyReferenceNumberEnabled(
      fieldsState.documentTypesList, fieldsState.documentType, true) && !fieldsState.isNewAssured && (fieldsState.assuredId != null) && (isNYEnergyEnviroWT(fieldsState.businessEntity, fieldsState.firstMajorClass)) && fieldsState.hasExpiringPolicies;

    return render && (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              onChange={(event, checked) => onPreRenewalChecked(checked)}
              checked={fieldsState.preRenewal}
            />
          }
          label="Pre-Renewal Submission"
          labelPlacement="start"
        />
      </Grid>
    );
  };

  const renderBrokerName = () => {
    return <Grid item>
      <BrokerAutocomplete
        inputRef={fieldsRef?.["broker"]}
        hintText={fieldsHints?.['broker']}
        fieldsState={fieldsState}
        validationState={validationState}
        setFieldsState={setFieldsState}
        setValidationState={setValidationState}
        setBannerState={setBannerState}
      />
    </Grid>
  };

  const renderBrokerBranch = () => {

    let render = !isHamiltonSelectSubmission(fieldsState.businessEntity)
    return render && <Grid item>
      <BrokerBranchAutocomplete
        inputRef={fieldsRef?.["brokerBranch"]}
        hintText={fieldsHints?.['brokerBranch']}
        fieldsState={fieldsState}
        validationState={validationState}
        setFieldsState={setFieldsState}
        setValidationState={setValidationState}
        setBannerState={setBannerState}
      />
    </Grid>;
  };


  const renderBrokerBranchHS = () => {

    let render = isHamiltonSelectSubmission(fieldsState.businessEntity)
    return  render && <Grid item>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <BrokerBranchAutocomplete
            inputRef={fieldsRef?.["brokerBranch"]}
            hintText={fieldsHints?.['brokerBranch']}
            fieldsState={fieldsState}
            validationState={validationState}
            setFieldsState={setFieldsState}
            setValidationState={setValidationState}
            setBannerState={setBannerState}
            onChange={
              (_, value) => {
                // Clarify a branch territory and prefill it for the next field
                if (value) {
                  fetchBrokerBranchTerritory(value).then(({success, data}) => {     
                    const territory = success && data ? data.territory : null;  
                    setFieldsState(previousState => ({...previousState, territory}));
                  });          
                } else {
                  setFieldsState(previousState => ({...previousState, territory: null}));
                }   
              }
            }
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth style={{marginTop: -4}}>
            <Dropdown
              id="Territory"
              label="Territory"
              placeholder="Territory"
              value={fieldsState.territory}
              data={dropdownsData.territories}
              onChange={onTerritoryChanged}
              disabled={fieldsState.businessEntity === '' || !fieldsState.broker?.id  }
              inputRef={fieldsRef?.["brokerTerritory"]}              
            />
            </FormControl>
        </Grid>
      </Grid>
    </Grid>;
  };

  const renderBrokerContact = () => {
    return <Grid item>
      <BrokerContactAutocomplete
        inputRef={fieldsRef?.["brokerContact"]}
        hintText={fieldsHints?.['brokerContact']}
        fieldsState={fieldsState}
        validationState={validationState}
        setFieldsState={setFieldsState}
        setValidationState={setValidationState}
        setBannerState={setBannerState}
      />
    </Grid>;
  };

  const renderBrokerContactEmail = () => {

    let render = !fieldsState.businessEntity.includes('Miami');

    return render && <Grid item>
      <TextField
        id="brokerContactEmail"
        inputRef={fieldsRef?.["brokerEmail"]}
        label="Broker Contact Email"
        type="text"
        disabled={!fieldsState.product?.productId}
        required
        fullWidth
        onChange={onBrokerContactEmailChanged}
        onBlur={onBrokerContactEmailBlur}
        value={fieldsState.brokerContact?.email ?? ""}
        error={!validationState.fields.brokerContactEmail.valid}
        helperText={validationState.fields.brokerContactEmail.message}
      />
    </Grid>;
  };

  const renderPolicyReferenceNumber = () => {
    let render =
      isPolicyReferenceNumberEnabled(fieldsState.documentTypesList, fieldsState.documentType, fieldsState.isNewAssured) &&
      !fieldsState.isNewAssured;
    return render && (
      <Grid item>
        <FormControl fullWidth required error={!validationState.fields.policyReferenceNumber.valid}>
          <Dropdown
            id="policyReferenceNumber"
            label="Policy Reference Number"
            value={fieldsState.policyReferenceNumber}
            data={policyReferencesList}
            onChange={onPolicyReferenceChanged}
            onBlur={onPolicyReferenceBlur}
            errorText={validationState.fields.policyReferenceNumber.message}
            disabled={!fieldsState.assured}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderTotalNumberOfPolicyReferences = () => {
    const render = isTotalNumberOfPolicyReferencesEnabled(fieldsState.documentTypesList, fieldsState.documentType) && !isPropertyDnF(fieldsState.businessEntity, fieldsState.firstMajorClass);
    return render && (
      <Grid item>
        <TextField
          id="totalNumberOfPolicyReferences"
          label="Total Number of Policy References/Declarations"
          type="text"
          required
          fullWidth
          onChange={onTotalNumberOfPolicyReferencesChanged}
          onBlur={onTotalNumberOfPolicyReferencesBlur}
          value={fieldsState.totalNumberOfPolicyReferences}
          error={!validationState.fields.totalNumberOfPolicyReferences.valid}
          helperText={validationState.fields.totalNumberOfPolicyReferences.message}
        />
      </Grid>
    );
  };

  const renderDocumentType = () => {
    return (
      <Grid item>
        <FormControl fullWidth required error={!validationState.fields.typeOfDocument.valid}>
          <Dropdown
            id="documentType"
            label="Type Of Document"
            value={fieldsState.documentType}
            data={fieldsState.documentTypesList?.map(x => x.name) || []}
            onChange={onDocumentTypeChanged}
            onBlur={onDocumentTypeBlur}
            errorText={validationState.fields.typeOfDocument.message}
            disabled={disableDocumentType(fieldsState)}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderPremiumEndorsement = () => {
    return isPremiumEndorsement(fieldsState, dropdownsData) && (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              onChange={(event, checked) => onPremiumEndorsementChecked(checked)}
              checked={fieldsState.premiumEndorsement}
            />
          }
          label="Premium Bearing"
          labelPlacement="start" />
      </Grid>
    );
  };

  const renderYearOfAccount = () => {
    return (
      <Grid item>
        <FormControl fullWidth required error={!validationState.fields.yearOfAccount.valid}>
          <Dropdown
            id="yoa"
            label="YOA"
            value={fieldsState.yoa}
            data={dropdownsData.years}
            onChange={onYearOfAccountChanged}
            onBlur={onYearOfAccountBlur}
            disabled={fieldsState.businessEntity === ''}
            errorText={validationState.fields.yearOfAccount.message}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderExpiringPolicyReferenceNumber = () => {
    let render = isExpiringPolicyReferenceNumberEnabled(
      fieldsState.documentTypesList, fieldsState.documentType, true);

    return render && (
      <Grid item>
        <FormControl fullWidth>
          <Dropdown
            id="expiringPolicyReferenceNumber"
            label="Expiring Policy Reference Number"
            value={fieldsState.expiringPolicyReferenceNumber}
            data={policyReferencesList}
            onChange={onExpiringPolicyReferenceNumberDropdownChanged}
            disabled={!fieldsState.assured && !fieldsState.preRenewal}
          />
        </FormControl>
      </Grid>
    )
  };

  const renderPlacingBasis = () => {
    let render = areMinorClassesUsed(fieldsState.documentType, fieldsState.businessEntity, fieldsState.firstMajorClass, dropdownsData);

    return render && (
      <Grid item>
        <FormControl fullWidth required error={!validationState.fields.placingBasis.valid}>
          <Dropdown
            id="placingBasis"
            label="Placing Basis"
            disabled={!fieldsState.businessEntity}
            value={fieldsState.placingBasis}
            data={dropdownsData.placingBases?.map(x => x.name) || []}
            onChange={onPlacingBasisChanged}
            onBlur={onPlacingBasisBlur}
            errorText={validationState.fields.placingBasis.message}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderProductPlacingBasis = () => {
    return (
      <Grid item>
        <FormControl fullWidth required error={!validationState.fields.placingBasis.valid}>
          <Dropdown
            id="placingBasis"
            label="Placing Basis"
            value={fieldsState.placingBasis}
            data={dropdownsData.productPlacingBases?.map(x => x.placingBasis) || []}
            disabled={!fieldsState.businessEntity || !dropdownsData.productPlacingBases || dropdownsData.productPlacingBases?.length === 0}
            onChange={onProductPlacingBasisChanged}
            onBlur={onPlacingBasisBlur}
            errorText={validationState.fields.placingBasis.message}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderNewYorkMgaSection = () => {
    let render = isNewYorkMgaSectionEnabled(fieldsState.documentType, fieldsState.documentTypesList);
    return render && <>
      {renderBrokerSection()}
      {renderAssignedUW()}
      {renderEffectiveDate()}
      {renderQuoteBy()}
    </>;
  };

  const renderBrokerSection = () => {
    let render = isBrokerSectionEnabled(fieldsState.product);
    return render && <>
      {renderBrokerName()}
      {renderBrokerBranch() || renderBrokerBranchHS()}
      {renderBrokerContact()}
      {renderBrokerContactEmail()}
    </>;
  };

  const renderComments = () => {
    let render = isCommentEnabled(dropdownsData, fieldsState.businessEntity, fieldsState.documentType);
    return render && (
      <Grid item>
        <TextField
          id="comments"
          label="Comments"
          fullWidth
          multiline
          onChange={onCommentsChanged}
          onBlur={onCommentsBlur}
          value={fieldsState.comments}
          error={!validationState.fields.comments.valid}
          helperText={validationState.fields.comments.message}
        />
      </Grid>
    );
  };

  const renderStatesSection = () => {
    let render = isStatesSectionEnabled(fieldsState.businessEntity);
    return render && (
      <Grid item>
        <FormControl fullWidth required error={!validationState.fields.state.valid}>
          <Dropdown
            id="state"
            label="State"
            value={fieldsState.state}
            data={dropdownsData.states}
            onChange={onStateChanged}
            onBlur={onStateBlur}
            disabled={fieldsState.businessEntity === ''}
            errorText={validationState.fields.state.message}
          />
        </FormControl>
      </Grid>
    );
  }

  return <>
    {renderStatesSection()}
    {renderMajorClasses()}
    {renderRequiredModellingPriority()}
    {renderDocumentType()}
    {renderPremiumEndorsement()}
    {renderMinorClass() || renderProductPlacingBasis()}
    {renderProducingTeam()}
    {renderNewYorkMgaSection()}
    {renderYearOfAccount()}
    {renderFinalPricingCompleted()}
    {renderMiamiCedants()}
    {renderAssured() || renderAssuredMiami()}
    {renderAssuredAddress()}
    {renderTypeOfBusiness()}
    {renderBlockedSubmission()}
    {renderPreRenewal()}    
    {renderPolicyReferenceNumber()}
    {renderPlacingBasis()}
    {renderTotalNumberOfPolicyReferences()}
    {renderExpiringPolicyReferenceNumber()}
    {renderPricingNotRequired()}
    {renderEeaBusiness()}
    {renderTypeOfModellingRisk()}
    {renderComments()}    
  </>;
};