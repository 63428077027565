import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { TextField, Dialog, DialogActions, DialogTitle, DialogContent, FormControl } from '@material-ui/core';

import { DialogButton, ComponentController } from 'components/common';
import { GeneralDetailsFields, getCountryData } from '../Helpers';
import { maxAddressLength, assuredMaxLength, maxPostCodeLength } from '../Helpers/Constants';
import { Dropdown } from 'components/common';

export const IndividualAssuredPopup = ({
  popupState,
  onPopupSubmit,
  setPopupState,
  setFormState,
  setValidationValue,
  requestData
}) => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: "onTouched",
    defaultValues: { 
      givenName: popupState.newValue, 
      addressDetails: requestData.address,
      postCodeDetails: requestData.postCode,
      state: ""
    }
  }, [requestData]);

  const [states, setStates] = useState([]);
  const [stateToStateCodeMap, setStateToStateCodeMap] = useState({});
  const [isCountryValid, setIsCountryValid] = useState(false);
    
  useEffect(() => {
    const { countryData, states, stateToStateCodeMap } = getCountryData(
      requestData?.countryStatesData,
      requestData?.countryName
    );
    setStates(states);
    setStateToStateCodeMap(stateToStateCodeMap);
    setIsCountryValid(!!countryData);
  }, [requestData?.countryStatesData, requestData?.countryName]);

  const handleClose = useCallback(
    () => setPopupState(previous => ({ ...previous, show: false })),
    [setPopupState]);

  const onAssuredSubmit = useCallback(response => {
    let givenName = response.data?.givenName ?? '';
    let familyName = response.data?.familyName ?? '';
    let address = response.data?.address;
    let postCode = response.data?.postCode;
    let state = response.data?.state;
    let stateCode = response.data?.stateCode;
    let assured = {
      assured: response.data?.assured,
    };

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        assured,
        secondAssured: previous.fields.isDualStamp ? assured : null,
        givenName,
        familyName,
        address,
        postCode,
        state,
        stateCode,
        hamiltonId: null
      }
    }));

    setValidationValue("assured", assured, { shouldValidate: true });
  }, [setFormState, setValidationValue]);

  const onSubmit = useCallback(async popupData => {
    handleClose();

    const { givenName, familyName, addressDetails, postCodeDetails } = popupData
    let assured = `${popupData?.givenName} ${popupData?.familyName}`;
    let stateCode = stateToStateCodeMap[popupData.state];
    let submitData = {
      data: {
        assured,
        givenName,
        familyName,
        address: addressDetails,
        postCode: postCodeDetails,
        state: popupData.state,
        stateCode: stateCode
      }
    };
    if (onPopupSubmit) {
      onPopupSubmit(submitData);
    } else {
      onAssuredSubmit(submitData);
    }

  }, [handleClose, onAssuredSubmit, onPopupSubmit, stateToStateCodeMap]);

  const getRequiredValidationError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const getMaxLengthValidationError = name => {
    return errors[name]?.type === 'maxLength' &&
      <span role="alert">"{GeneralDetailsFields[name]}" maximum length is {assuredMaxLength} characters</span>;
  }

  return (
    <Dialog open={popupState.show} onClose={handleClose} aria-labelledby="assured-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="assured-dialog-title">Add a new Assured to Sequel Eclipse</DialogTitle>
        <DialogContent>
          <ComponentController
            name="givenName"
            control={control}
            required
            rules={{ maxLength: assuredMaxLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getRequiredValidationError(name) || getMaxLengthValidationError(name)}
              />
            }
          />
          <ComponentController
            name="familyName"
            control={control}
            required
            rules={{ maxLength: assuredMaxLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getRequiredValidationError(name) || getMaxLengthValidationError(name)}
              />
            }
          />
          <ComponentController
            name="addressDetails"
            control={control}
            rules={{ maxLength: maxAddressLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getMaxLengthValidationError(name)}
              />
            }
          />
          <ComponentController
            name="postCodeDetails"
            control={control}
            rules={{ maxLength: maxPostCodeLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                helperText={getMaxLengthValidationError(name)}
              />
            }
          />
          {isCountryValid && (
            <ComponentController
              name="state"
              control={control}
              render={({ field: { name, value, onChange, onBlur } }) =>
                <FormControl fullWidth error={!!errors[name]}>
                  <Dropdown
                    id={name}
                    name={name}
                    value={value || ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={GeneralDetailsFields[name]}
                    data={states}
                    margin="dense"
                    fullWidth
                  />
                </FormControl>
              }
            />
          )}
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <DialogButton onClick={handleClose}>Cancel</DialogButton>
          <DialogButton type="Submit">Add</DialogButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
