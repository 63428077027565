import React, { useState, useEffect, useRef } from 'react';
import { getItemIdKeyValue } from 'star-ai';
import {
  FormControl,
  Grid,
  Divider,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
} from '@material-ui/core';
import { Send, Clear } from '@material-ui/icons';
import { fetchCurrentUser } from 'Api';
import { ErrorPage } from '../navigation';

import {
  Banner,
  BannerType,
  CheckboxElement,
  Dropdown,
  FormButton,
  Footer,
  DragAndDropArea,
  FormControlWrapper,
  DialogButton,
  FileSection,
  useToast,
} from '../common';

import {
  fetchPlacingBases,
  fetchEntitiesData,
  submitFormData,
  fetchDocTypes,
  fetchMiamiCedants,
  uploadFile,
  fetchAddressCountries,
} from './Form.api';
import {
  parseMessage,
  initDocument,
  FileUploadStatus,
  FileType,
  ProducingTeam,
} from 'Helpers';
import {
  isMasterPolicyReferenceNumberEnabled,
  isExpiringPolicyReferenceNumberEnabled,
  isProducingTeamUsed,
  isNewYorkMgaSectionEnabled,
  hideRenewedPolicy,
  getFormattedDate,
  isMiamiSubmission,
} from './Helpers';
import {
  validateTextField,
  validateRequiredField,
  validateRenewedPolicy,
  validateForm,
} from './Validation';
import { MGASection, ShanghaiSection, BermudaSection } from './Sections';
import {
  initialDropdowns,
  initialFormState,
  initialValidationState,
} from './State';
import './Form.css';

export const Form = ({
  ai: {
    fieldsRef,
    fieldsData,
    formValues,
    setFormValues,
    aiInit,
    aiSubmitCallback,
    aiResetCallback,
    aiUnmountCallback,
    formFiles,
    majorClassDetails,
  } = {},
  ...props
}) => {
  const [isInitialized, setInitialized] = useState(false);
  const isSubmitting = useRef(false);
  const [userState, setUserState] = useState();
  const [dropdownsData, setDropdownsData] = useState(initialDropdowns);

  const [innerFieldsState, setInnerFieldsState] = useState(initialFormState);
  const fieldsState = formValues || innerFieldsState;
  const setFieldsState = setFormValues || setInnerFieldsState;

  const [validationState, setValidationState] = useState(
    initialValidationState,
  );
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const showNotifications = true;
  const showNotificationsForFileUploder = props.embedded === false;
  const [bannerState, setBannerState] = useState({});
  const [, setToastState] = useToast({}, showNotifications);
  const setNotification = props.embedded ? setToastState : setBannerState;

  useEffect(() => {
    const initializeForm = async () => {
      props.setLoading(true);
      const response = await fetchEntitiesData();

      if (response.success) {
        loadFormData(response.data);
        await loadUserInfo();
        setInitialized(true);
      } else {
        if (response.permissionError) {
          setErrorMessage(response.errorMessage);
        } else {
          setNotification({
            show: true,
            type: BannerType.error,
            message: response.errorMessage,
          });
        }
      }

      props.setLoading(false);
    };

    initializeForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Init AutoPopulation Effect
  useEffect(() => {
    if (isInitialized && aiInit && fieldsData) {
      aiInit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, fieldsData]);

  // Unmount AutoPopulation Effect
  useEffect(() => {
    return () => {
      if (aiUnmountCallback) {
        aiUnmountCallback(() => {
          clearAttachments();
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUserInfo = async () => {
    const user = await fetchCurrentUser();
    setUserState(user);
    setFieldsState({
      ...fieldsState,
      underwriterEmail: user?.mail,
      requestInitiatorEmail: user?.mail,
      requestInitiatorName: user?.name,
    });

    return user;
  };

  const loadFormData = (data) => {
    var entities = [];
    for (var item in data.entities) {
      if (data.entities.hasOwnProperty(item)) {
        entities.push(item);
      }
    }

    setDropdownsData({
      ...data,
      entitiesList: entities,
      majorClasses: [],
      minorClasses: [],
      classes: [],
      years: [],
      placingBases: [],
      productPlacingBases: [],
      assignedUWs: [],
      states: [],
      miamiCedants: [],
      miamiCedantsCountries: [],
      territories: [],
    });
  };

  const getPlacingBases = async (entity) => {
    let placingBasesResponse = await fetchPlacingBases(entity);
    if (!placingBasesResponse?.success) {
      setNotification({
        show: true,
        type: BannerType.error,
        message: placingBasesResponse.errorMessage,
      });

      return [];
    }

    return placingBasesResponse?.data ?? [];
  };

  const getMiamiCedants = async (entity) => {
    if (isMiamiSubmission(entity)) {
      let miamiCedantsResponse = await fetchMiamiCedants();
      if (!miamiCedantsResponse?.success) {
        setNotification({
          show: true,
          type: BannerType.error,
          message: miamiCedantsResponse.errorMessage,
        });

        return [];
      }

      return miamiCedantsResponse?.data ?? [];
    }
  };

  const getMiamiCedantsCountries = async (entity) => {
    if (isMiamiSubmission(entity)) {
      let miamiCedantsCountriesResponse = await fetchAddressCountries();
      if (!miamiCedantsCountriesResponse?.success) {
        setNotification({
          show: true,
          type: BannerType.error,
          message: miamiCedantsCountriesResponse.errorMessage,
        });

        return [];
      }

      return miamiCedantsCountriesResponse?.data ?? [];
    }
  };

  const getDocTypes = async (entity) => {
    let docTypesReponse = await fetchDocTypes(entity);
    if (!docTypesReponse?.success) {
      setNotification({
        show: true,
        type: BannerType.error,
        message: docTypesReponse.errorMessage,
      });

      return [];
    }

    return docTypesReponse?.data ?? [];
  };

  const onMailRead = (event, file) => {
    const messageData = parseMessage(event.target.result, file);
    setFiles((prevFiles) => [...prevFiles, messageData]);
  };

  const onDocumentRead = (event, file) => {
    const documentData = initDocument(event.target.result, file);
    setFiles((prevFiles) => [...prevFiles, documentData]);
  };

  const onFileDrop = (event) => {
    for (let index in event.dataTransfer.files) {
      if (index && event.dataTransfer.files.hasOwnProperty(index)) {
        const file = event.dataTransfer.files[index];
        const reader = new FileReader();
        const fileReadHandler = file.name.endsWith('.msg')
          ? onMailRead
          : onDocumentRead;
        reader.onload = (event) => fileReadHandler(event, file);
        reader.readAsArrayBuffer(file);
      }
    }
  };

  const clearAttachments = () => {
    setFiles([]);
    setValidationState((prevState) => ({
      ...prevState,
      files: { dropped: false },
      valid: false,
    }));
  };

  const clearForm = (isResetTriggeredByUser) => {
    setFieldsState(() => ({
      ...initialFormState,
      underwriterEmail: userState?.mail,
      requestInitiatorEmail: userState?.mail,
      requestInitiatorName: userState?.displayName,
    }));

    setFiles([]);
    setValidationState(initialValidationState);

    // Reset for AI Timeflow functionality
    if (aiResetCallback && isResetTriggeredByUser) {
      aiResetCallback();
    }
  };

  const onCriticalChecked = (checked) => {
    setFieldsState({
      ...fieldsState,
      critical: checked,
    });
  };

  const onRenewedPolicyChecked = (checked) => {
    setFieldsState((prevState) => ({
      ...prevState,
      renewedPolicy: checked,
    }));

    var result = validateRenewedPolicy(
      checked,
      fieldsState.documentType,
      fieldsState.documentTypesList,
    );
    setValidationState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        renewedPolicy: result,
      },
    }));
  };

  const validateEntity = (value) => {
    var result = validateRequiredField('Business Entity', value);
    setValidationState((prevState) => {
      return {
        ...prevState,
        fields: {
          ...prevState.fields,
          entity: result,
        },
      };
    });
  };

  const onEntityChanged = async (event) => {
    var entityValue = event.target.value;

    if (entityValue !== '') {
      let entityData = dropdownsData.entities[entityValue];
      let currentMajorClasses = {};
      if (entityData.parentEntities) {
        for (var i = 0; i < entityData.parentEntities.length; i++) {
          currentMajorClasses[entityData.parentEntities[i]] = '';
        }
      } else {
        currentMajorClasses[entityValue] = '';
      }

      setValidationState((prevState) => {
        return {
          ...prevState,
          fields: {
            ...initialValidationState.fields,
            policyEntryInformation: prevState.fields.policyEntryInformation,
            renewedPolicy: prevState.fields.renewedPolicy,
          },
        };
      });
      let entityName = entityData.parentEntities
        ? entityData.parentEntities[0]
        : entityData.name;
      let placingBases = await getPlacingBases(entityName);
      let docTypes = await getDocTypes(entityName);
      let miamiCedants = await getMiamiCedants(entityName);
      let miamiCedantsCountries = await getMiamiCedantsCountries(entityName);
      let majorClassesList = entityData.parentEntities
        ? dropdownsData.entities[entityData.parentEntities[0]].majorClasses
        : entityData.majorClasses;
      let years = entityData.years;
      let states = entityData.states;

      setFieldsState((prevState) => ({
        ...initialFormState,
        critical: prevState.critical,
        renewedPolicy: prevState.renewedPolicy,
        policyEntryInformation: prevState.policyEntryInformation,
        underwriterEmail: userState?.mail,
        businessEntity: entityValue,
        firstMajorClass: '',
        majorClasses: currentMajorClasses,
        producingTeam:
          dropdownsData.producingTeams.find(
            (team) => team === entityData.defaultProducingTeam,
          ) || '',
        yoa: entityData.autoselectCurrentYear
          ? years.find((year) => year === new Date().getFullYear())
          : '',
        defaultPlacingBasis: entityData.defaultPlacingBasis,
        documentTypesList: docTypes,
        documentType: entityValue.includes('Miami')
          ? docTypes.find((doc) => doc.name === 'Submissions').name
          : docTypes.length === 1
          ? docTypes[0].name
          : '',
        totalNumberOfPolicyReferences:
          entityData.defaultTotalNumberOfPolicyReferences || 1,
        peerReviewNotRequired: !entityData.allowsPeerReview,
        companyId: entityData.companyId,
        requestInitiatorEmail: userState?.mail,
        requestInitiatorName: userState?.displayName,
      }));

      setDropdownsData({
        ...dropdownsData,
        majorClasses: majorClassesList ? majorClassesList : [],
        years: years ? years : [],
        placingBases: placingBases,
        documentTypes: docTypes,
        states: states ? states : [],
        miamiCedants: miamiCedants ? miamiCedants : [],
        miamiCedantsCountries: miamiCedantsCountries
          ? miamiCedantsCountries
          : [],
      });
    }

    validateEntity(entityValue);
  };

  const onEntityBlur = (event) => {
    validateEntity(event?.target?.value);
  };

  const onPolicyEntryInformationChanged = (event) => {
    setFieldsState({
      ...fieldsState,
      policyEntryInformation: event.target.value,
    });
  };

  const onPolicyEntryInformationLength = (event) => {
    var result = validateTextField(
      'Policy Entry Information',
      event.target.value,
    );
    setValidationState({
      ...validationState,
      fields: {
        ...validationState.fields,
        policyEntryInformation: result,
      },
    });
  };

  const submitForm = async () => {
    const filesToSubmit = [];
    files.forEach((data) => {
      if (data.type === FileType.document) {
        filesToSubmit.push({ name: data.file.name, path: data.upload.path });
      } else if (data.type === FileType.message) {
        filesToSubmit.push({
          name: data.file.name,
          path: data.upload.path,
          body: data.body,
          title: data.title,
          sender: data.sender,
          attachments: data.attachments,
        });
      }
    });

    let producingTeam = isProducingTeamUsed(
      fieldsState.documentType,
      fieldsState.businessEntity,
      fieldsState.firstMajorClass,
      dropdownsData,
    )
      ? fieldsState.producingTeam
      : '';

    let {
      masterPolicyReference,
      secondMasterPolicyReference,
      expiringPolicyReference,
      secondExpiringPolicyReference,
    } = {
      masterPolicyReference: isMasterPolicyReferenceNumberEnabled(
        fieldsState.documentTypesList,
        dropdownsData,
        fieldsState.documentType,
        fieldsState.placingBasis,
      )
        ? fieldsState.masterPolicyReferenceNumber
        : '',
      secondMasterPolicyReference: isMasterPolicyReferenceNumberEnabled(
        fieldsState.documentTypesList,
        dropdownsData,
        fieldsState.documentType,
        fieldsState.placingBasis,
      )
        ? fieldsState.secondMasterPolicyReferenceNumber
        : '',
      expiringPolicyReference:
        (isNewYorkMgaSectionEnabled(
          fieldsState.documentType,
          fieldsState.documentTypesList,
        ) &&
          isExpiringPolicyReferenceNumberEnabled(
            fieldsState.documentTypesList,
            fieldsState.documentType,
            true,
          )) ||
        isExpiringPolicyReferenceNumberEnabled(
          fieldsState.documentTypesList,
          fieldsState.documentType,
          fieldsState.renewedPolicy,
        )
          ? fieldsState.expiringPolicyReferenceNumber
          : '',
      secondExpiringPolicyReference: isExpiringPolicyReferenceNumberEnabled(
        fieldsState.documentTypesList,
        fieldsState.documentType,
        fieldsState.renewedPolicy,
      )
        ? fieldsState.secondExpiringPolicyReferenceNumber
        : '',
    };

    let response = await submitFormData(
      {
        ...fieldsState,
        producingTeam: producingTeam,
        masterPolicyReferenceNumber: masterPolicyReference,
        secondMasterPolicyReferenceNumber: secondMasterPolicyReference,
        expiringPolicyReferenceNumber: expiringPolicyReference,
        secondExpiringPolicyReferenceNumber: secondExpiringPolicyReference,
        totalNumberOfPolicyReferences: parseInt(
          fieldsState.totalNumberOfPolicyReferences,
        ),
        assured: {
          name: fieldsState.assured,
          id: fieldsState.assuredId,
          isNew: fieldsState.isNewAssured,
        },
        assuredMiami: fieldsState.assuredMiami,
        secondAssured: {
          name: fieldsState.secondAssured,
          isNew: fieldsState.isNewSecondAssured,
        },
        effectiveDate: getFormattedDate(fieldsState.effectiveDate),
        quoteBy: getFormattedDate(fieldsState.quoteBy),
        broker: fieldsState.broker && {
          ...fieldsState.broker,
          id: parseInt(fieldsState.broker.id),
        },
        brokerContact: fieldsState.brokerContact && {
          ...fieldsState.brokerContact,
          id: parseInt(fieldsState.brokerContact.id),
          relationshipId: parseInt(fieldsState.brokerContact.relationshipId),
        },
        brokerBranch: fieldsState.brokerBranch && {
          ...fieldsState.brokerBranch,
          id: parseInt(fieldsState.brokerBranch.id),
        },
        miamiCedant: fieldsState.miamiCedant,
        miamiCedantCountry: fieldsState.miamiCedantCountry,
        miamiCedantCountryCode: fieldsState.miamiCedantCountryCode,
        ...getItemIdKeyValue(fieldsData),

        // This line is necessary for Timeflow AI to generate a unique submission number in the Orchestrator
        ...(fieldsData?.itemId && { TimeflowAiItemId: fieldsData.itemId }),
      },
      filesToSubmit,
    );

    let secondSub = false;
    let response2;

    if (
      fieldsState.businessEntity === 'Hamilton Select' &&
      fieldsState.firstMajorClass === 'Energy' &&
      'minorClass2' in fieldsState &&
      fieldsState['minorClass2'] !== ''
    ) {
      secondSub = true;
      fieldsState.minorClass = fieldsState.minorClass2;
      fieldsState.product.productId = fieldsState.product.productId2;
      fieldsState.product.productCode = fieldsState.product.productCode2;

      if (fieldsState.minorClass.includes('Excess')) {
        fieldsState.placingBasis = fieldsState.defaultPlacingBasis.excess;
      } else {
        fieldsState.placingBasis = fieldsState.defaultPlacingBasis.primary;
      }
      await timeout(1000);
      response2 = await submitFormData(
        {
          ...fieldsState,
          producingTeam: producingTeam,
          masterPolicyReferenceNumber: masterPolicyReference,
          secondMasterPolicyReferenceNumber: secondMasterPolicyReference,
          expiringPolicyReferenceNumber: expiringPolicyReference,
          secondExpiringPolicyReferenceNumber: secondExpiringPolicyReference,
          totalNumberOfPolicyReferences: parseInt(
            fieldsState.totalNumberOfPolicyReferences,
          ),
          assured: {
            name: fieldsState.assured,
            id: fieldsState.assuredId,
            isNew: fieldsState.isNewAssured,
          },
          secondAssured: {
            name: fieldsState.secondAssured,
            isNew: fieldsState.isNewSecondAssured,
          },
          effectiveDate: getFormattedDate(fieldsState.effectiveDate),
          quoteBy: getFormattedDate(fieldsState.quoteBy),
          broker: fieldsState.broker && {
            ...fieldsState.broker,
            id: parseInt(fieldsState.broker.id),
          },
          brokerContact: fieldsState.brokerContact && {
            ...fieldsState.brokerContact,
            id: parseInt(fieldsState.brokerContact.id),
            relationshipId: parseInt(fieldsState.brokerContact.relationshipId),
          },
          brokerBranch: fieldsState.brokerBranch && {
            ...fieldsState.brokerBranch,
            id: parseInt(fieldsState.brokerBranch.id),
          },
          ...getItemIdKeyValue(fieldsData),

          // This line is necessary for Timeflow AI to generate a unique submission number in the Orchestrator
          ...(fieldsData?.itemId && { TimeflowAiItemId: fieldsData.itemId }),
        },
        [],
      );
    }

    if (response.success) {
      let msg = 'Submission is';

      if (secondSub && response2.success) {
        msg = 'Submissions';
      }

      // Submit for AI Timeflow functionality
      if (aiSubmitCallback) {
        await aiSubmitCallback();
      }

      clearForm();
      setNotification({
        type: BannerType.success,
        message: msg + ' successfully started.',
        show: true,
      });
    } else {
      setNotification({
        type: BannerType.error,
        message: response.errorMessage,
        show: true,
      });
    }

    props.setLoading(false);
    isSubmitting.current = false;
  };

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const onSubmit = async () => {
    let result = validateForm(fieldsState, dropdownsData);
    setValidationState((_) => result);

    if (!result.valid) {
      setNotification({
        show: true,
        type: BannerType.warning,
        message:
          'Form contains validation errors. Please provide valid information.',
      });
      return;
    }

    if (files.length === 0) {
      setConfirmationDialogOpen(true);
    } else {
      handleSubmit();
    }
  };

  const handleClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleSubmit = async () => {
    if (isSubmitting.current) return;
    isSubmitting.current = true;

    setConfirmationDialogOpen(false);
    props.setLoading(true);

    if (
      files.length === 0 ||
      files.every((file) => file.upload.status === FileUploadStatus.success)
    ) {
      await submitForm();
    } else if (
      files.some(
        (file) =>
          file.upload.status !== FileUploadStatus.success ||
          file.upload.status !== FileUploadStatus.inProgress,
      )
    ) {
      props.setLoading(false);
      isSubmitting.current = false;
      setNotification({
        show: true,
        type: BannerType.error,
        message:
          "Some of the dropped files haven't been uploaded. Cannot submit.",
      });
    } else {
      const uploadPromises = files
        .filter((file) => file.upload.status === FileUploadStatus.inProgress)
        .map((file) => file.upload.promise);

      Promise.all(uploadPromises).then(
        async () => {
          // on success
          await submitForm();
        },
        () => {
          // on error
          props.setLoading(false);
          isSubmitting.current = false;
        },
      );
    }
  };

  const renderRenewedPolicy = () => {
    let render = !hideRenewedPolicy(fieldsState.documentType);

    return (
      render && (
        <Grid item>
          <FormControlWrapper
            control={
              <CheckboxElement
                edge="end"
                onChange={(_event, checked) => onRenewedPolicyChecked(checked)}
                checked={fieldsState.renewedPolicy}
              />
            }
            label="Renewed Policy"
            labelPlacement="start"
          ></FormControlWrapper>
          <div className="MuiFormHelperText-root Mui-error">
            {validationState.fields.renewedPolicy.message}
          </div>
        </Grid>
      )
    );
  };

  const renderFormSection = () => {
    let entity = dropdownsData.entities[fieldsState.businessEntity];
    switch (entity?.producingTeam) {
      case ProducingTeam.NewYork:
      case ProducingTeam.Miami:
      case ProducingTeam.Dubai:
      case ProducingTeam.HamiltonSelect:
        return (
          <MGASection
            fieldsRef={fieldsRef}
            fieldsHints={fieldsData}
            fieldsState={fieldsState}
            majorClassDetails={majorClassDetails}
            dropdownsData={dropdownsData}
            validationState={validationState}
            setFieldsState={setFieldsState}
            setValidationState={setValidationState}
            setDropdownsData={setDropdownsData}
            setBannerState={setNotification}
            setLoading={props.setLoading}
          />
        );
      case ProducingTeam.Bermuda:
        return (
          <BermudaSection
            fieldsRef={fieldsRef}
            fieldsState={fieldsState}
            dropdownsData={dropdownsData}
            validationState={validationState}
            setFieldsState={setFieldsState}
            setValidationState={setValidationState}
            setDropdownsData={setDropdownsData}
            setBannerState={setNotification}
          />
        );
      default:
        return (
          <ShanghaiSection
            fieldsRef={fieldsRef}
            fieldsState={fieldsState}
            dropdownsData={dropdownsData}
            validationState={validationState}
            setFieldsState={setFieldsState}
            setValidationState={setValidationState}
            setDropdownsData={setDropdownsData}
            setBannerState={setNotification}
          />
        );
    }
  };

  const renderConfirmationDialog = () => {
    return (
      <Dialog
        open={confirmationDialogOpen}
        onClose={handleClose}
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">
          Confirm that the form does not have any attachments
        </DialogTitle>
        <DialogActions
          className="dialog-buttons"
          style={{ justifyContent: 'center' }}
        >
          <DialogButton onClick={handleClose}>Cancel</DialogButton>
          <DialogButton onClick={handleSubmit}>Confirm</DialogButton>
        </DialogActions>
      </Dialog>
    );
  };

  const renderForm = () => {
    if (errorMessage.length > 0) {
      return <ErrorPage message={errorMessage} />;
    }

    if (!isInitialized) {
      return null;
    }

    return (
      <div className="form-container">
        {!props.embedded ? (
          <>
            <h2>Entry form</h2>
            <Divider />
            <Banner
              type={bannerState.type}
              message={bannerState.message}
              show={bannerState.show}
              showBanner={(show) =>
                setNotification({
                  ...bannerState,
                  show,
                })
              }
            />
          </>
        ) : (
          <>
            {fieldsData ? (
              <Typography variant="h5" className="form-header">
                ID{fieldsData.id}: {fieldsData.subject}
              </Typography>
            ) : null}
          </>
        )}
        <Grid
          container
          className="form-section"
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid
            item
            container
            xs={6}
            direction="column"
            spacing={3}
            className="form-fields-container"
          >
            <Grid item>
              <FormControlWrapper
                control={
                  <CheckboxElement
                    edge="end"
                    onChange={(_event, checked) => onCriticalChecked(checked)}
                    checked={fieldsState.critical}
                  />
                }
                label="Critical Month End Task"
                labelPlacement="start"
              />
            </Grid>
            {renderRenewedPolicy()}
            <Grid item>
              <TextField
                id="policyEntryInformation"
                label="Policy Entry Information"
                required
                fullWidth
                onChange={(e) => {
                  onPolicyEntryInformationChanged(e);
                  onPolicyEntryInformationLength(e);
                }}
                onBlur={onPolicyEntryInformationLength}
                value={fieldsState.policyEntryInformation}
                inputRef={fieldsRef?.['policyEntryInformation']}
                error={!validationState.fields.policyEntryInformation.valid}
                helperText={
                  validationState.fields.policyEntryInformation.message
                }
              />
            </Grid>
            <Grid item>
              <FormControl
                fullWidth
                required
                error={!validationState.fields.entity.valid}
              >
                <Dropdown
                  id="entity"
                  label="Business Entity"
                  key={fieldsState.businessEntity}
                  value={fieldsState.businessEntity}
                  data={dropdownsData.entitiesList}
                  inputRef={fieldsRef?.['businessEntity']}
                  onChange={onEntityChanged}
                  onBlur={onEntityBlur}
                  errorText={validationState.fields.entity.message}
                />
              </FormControl>
            </Grid>
            {renderFormSection()}
          </Grid>
          <Grid item container xs={6} direction="column" spacing={3}>
            <Grid item>
              <DragAndDropArea
                text="Drag and Drop Email/Document"
                onFileDrop={onFileDrop}
                fileToUpload={formFiles?.[0]}
              />
            </Grid>
            <Grid item>
              <FormButton
                endIcon={<Clear />}
                onClick={clearAttachments}
                style={{ width: '100%', marginLeft: '1.2rem' }}
              >
                Clear Attachments
              </FormButton>
            </Grid>
            <Grid item>
              {files.length > 0 && (
                <FileSection
                  files={files}
                  setFiles={setFiles}
                  uploadFile={uploadFile}
                  setBannerState={
                    showNotificationsForFileUploder ? setNotification : () => {}
                  }
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <div className="form-footer">
          <FormButton endIcon={<Clear />} onClick={clearForm}>
            Reset
          </FormButton>
          <FormButton
            endIcon={<Send />}
            onClick={onSubmit}
            disabled={props?.isLoading}
          >
            Submit
          </FormButton>
          {renderConfirmationDialog()}
        </div>
        {!props.embedded && <Footer />}
      </div>
    );
  };

  return renderForm();
};
