import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { TextField, Dialog, DialogActions, DialogTitle, DialogContent, FormControl } from '@material-ui/core';

import { DialogButton, BannerType, ComponentController } from 'components/common';
import { GeneralDetailsFields, getCountryData } from '../Helpers';
import { maxAddressLength, assuredMaxLength, maxPostCodeLength } from '../Helpers/Constants';
import { addAssured } from '../SubmissionWizard.api';
import { Dropdown } from 'components/common';

export const AssuredPopup = ({
  popupState,
  onPopupSubmit,
  submitData,
  requestData,
  setPopupState,
  setFormState,
  setValidationValue,
  setBannerState,
  setLoading
}) => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: "onTouched",
    defaultValues: {
      assured: popupState.newValue,
      state: ""
    }
  });

  const [states, setStates] = useState([]);
  const [stateToStateCodeMap, setStateToStateCodeMap] = useState({});
  const [isCountryValid, setIsCountryValid] = useState(false);

  useEffect(() => {
    const { countryData, states, stateToStateCodeMap } = getCountryData(
      requestData?.countryStatesData,
      requestData?.countryName
    );
    setStates(states);
    setStateToStateCodeMap(stateToStateCodeMap);
    setIsCountryValid(!!countryData);
  }, [requestData?.countryStatesData, requestData?.countryName]);

  const handleClose = useCallback(
    () => setPopupState(previous => ({ ...previous, show: false })),
    [setPopupState]);

  const onAssuredSubmit = useCallback(response => {
    let assured = {
      assured: response.data?.assured,
      assuredId: response.data?.assuredId,
      effectiveFromDate: response.data?.effectiveFromDate,
      effectiveToDate: response.data?.effectiveToDate
    };

    let address = response.data?.address;
    let postCode = response.data?.postCode;
    let state = response.data?.state;
    let stateCode = response.data?.stateCode;

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        assured,
        secondAssured: previous.fields.isDualStamp ? assured : null,
        address,
        postCode,
        state,
        stateCode
      }
    }));

    setValidationValue("assured", assured, { shouldValidate: true });
  }, [setFormState, setValidationValue]);

  const onSubmit = useCallback(async popupData => {
    handleClose();
    let assuredName = popupData.assured;
    let stateCode = stateToStateCodeMap[popupData.state];
    let assuredData = {
      data: {
        assured: assuredName,
        address: popupData.addressDetails,
        postCode: popupData.postCodeDetails,
        state: popupData.state,
        stateCode: stateCode
      }
    };

    if (requestData?.typeOfReassured) {
      if (onPopupSubmit) {
        onPopupSubmit(assuredData, submitData);
      } else {
        onAssuredSubmit(assuredData);
      }
    } else {
      setLoading(true);
    
      let response = await addAssured({ assured: assuredName });
      if (response.success) {
        if (onPopupSubmit) {
          onPopupSubmit(response, submitData);
        } else {
          onAssuredSubmit(response);
        }
      } else {
        setBannerState({
          show: true,
          type: BannerType.error,
          message: response.errorMessage
        });
      }
    
      setLoading(false);
    }
  }, [handleClose, submitData, onAssuredSubmit, onPopupSubmit, setBannerState, setLoading, requestData?.typeOfReassured, stateToStateCodeMap]);

  const getRequiredValidationError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const getMaxLengthValidationError = name => {
    return errors[name]?.type === 'maxLength' &&
      <span role="alert">"{GeneralDetailsFields[name]}" maximum length is {assuredMaxLength} characters</span>;
  }

  return (
    <Dialog open={popupState.show} onClose={handleClose} aria-labelledby="assured-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="assured-dialog-title">Add a new Assured to Sequel Eclipse</DialogTitle>
        <DialogContent>
          <ComponentController
            name="assured"
            control={control}
            required
            rules={{ maxLength: assuredMaxLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getRequiredValidationError(name) || getMaxLengthValidationError(name)}
              />
            }
          />
          <ComponentController
            name="addressDetails"
            control={control}
            rules={{ maxLength: maxAddressLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getMaxLengthValidationError(name)}
              />
            }
          />
          <ComponentController
            name="postCodeDetails"
            control={control}
            rules={{ maxLength: maxPostCodeLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                helperText={getMaxLengthValidationError(name)}
              />
            }
          />
          {isCountryValid  && (
            <ComponentController
              name="state"
              control={control}
              render={({ field: { name, value, onChange, onBlur } }) =>
                <FormControl fullWidth error={!!errors[name]}>
                  <Dropdown
                    id={name}
                    name={name}
                    value={value || ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={GeneralDetailsFields[name]}
                    data={states}
                    margin="dense"
                    fullWidth
                  />
                </FormControl>
              }
            />
          )}
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <DialogButton onClick={handleClose}>Cancel</DialogButton>
          <DialogButton type="Submit">Add</DialogButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};