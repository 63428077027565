import React, { useCallback, useEffect, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import DeleteIcon from '@material-ui/icons/Delete';
import FileCopy from '@material-ui/icons/FileCopy';
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid';
import { FormControl, Grid, IconButton } from '@material-ui/core';

import { ComponentController, NumberBox, PickerComponent } from 'components/common';
import { Datasource } from 'Api';
import { CustomValidationField } from 'components/SubmissionWizard/Helpers';

const useStyles = makeStyles(
  (_) => {
    return {
      root: {
        '& .MuiDataGrid-cell--editing': {
          backgroundColor: 'rgb(255,215,115, 0.19)',
          color: '#1a3e72'
        },
        '& .Mui-error': {
          backgroundColor: `rgb(126,10,15, 0.1)`,
          color: '#750f0f'
        }
      },
    };
  }
);

const ValidationMessages = {
  required: "All fields are required to be populated in policy references grid",
  policyReference: "At least one row in policy references grid must match the Policy Reference Number"
};

const EpiUpdateTable = ({
  fields,
  epiUpdatePolicies,
  totalNumberOfPolicyReferences,
  dropdownsData,
  control,
  errors,
  register,
  forceClearDatasourceState,
  ai,
  setFormState,
  setBannerState,
  setValidationValue,
  setLoading
}) => {
  const [columns, setColumns] = useState([
    {
      field: 'policyReferenceNumber',
      headerName: 'Policy Reference',
      flex: 0.7,
      policyRefContainer: true,
      renderEditCell: () => { },
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      mandatory: true
    },
    {
      field: 'originalCurrency',
      headerName: 'Original Currency',
      flex: 0.7,
      currencyContainer: true,
      renderEditCell: () => { },
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      mandatory: true
    },
    {
      field: 'gwpOriginal',
      headerName: '100% GWP (Original)',
      flex: 0.7,
      numberboxContainer: true,
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      mandatory: true
    },
    {
      field: 'settlementCurrency',
      headerName: 'Settlement Currency',
      flex: 0.7,
      currencyContainer: true,
      renderEditCell: () => { },
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      mandatory: true
    },
    {
      field: 'gwpSettlement',
      headerName: '100% GWP (Settlement)',
      flex: 0.7,
      numberboxContainer: true,
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      mandatory: true
    },
    {
      field: 'deleteIcon',
      headerName: 'Delete',
      hide: false,
      flex: 0.3,
      type: "icon",
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      renderEditCell: () => "",
      renderCell: {}
    },
    {
      field: 'copyIcon',
      headerName: 'Copy',
      hide: false,
      flex: 0.3,
      type: "icon",
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      renderEditCell: () => "",
      renderCell: {}
    }
  ]);
  const classes = useStyles();
  const [abortController, setAbortController] = useState(new AbortController());

  const additionalPoliciesRef = React.useRef([]);
  additionalPoliciesRef.current = epiUpdatePolicies;

  const handleChange = useCallback((cellName, value, rowId) => {
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        epiUpdatePolicies: additionalPoliciesRef.current
          .map(policy => {
            if (policy.id === rowId) {
              policy[cellName] = value;
            }

            return policy;
          })
      }
    }));

    setColumns(previous => {
      return previous.map(p => {
        if ('forceRerender' in p) {
          p.forceRerender = !p.forceRerender;
        }
        return p;
      });
    });
  }, [setFormState]);

  const onCopyRow = useCallback(cell => {
    let policyToCopy = additionalPoliciesRef.current.find(x => x.id === cell.id);
    let newPolicyRow = { ...policyToCopy };

    newPolicyRow.id = uuidv4();
    newPolicyRow.layer = additionalPoliciesRef.current.length + 1;

    setFormState(previous => {
      let epiUpdatePolicies = [...previous.fields.epiUpdatePolicies, newPolicyRow];

      let newState = {
        ...previous,
        fields: {
          ...previous.fields,
          totalNumberOfPolicyReferences: epiUpdatePolicies.length,
          epiUpdatePolicies
        }
      };

      setValidationValue("totalNumberOfPolicyReferences", epiUpdatePolicies.length, { shouldValidate: true });

      return newState;
    });
  }, [setFormState, setValidationValue])

  const onRemoveRow = useCallback((cell, event) => {
    let { id, api } = cell;
    let isSingle = additionalPoliciesRef.current.length === 1;

    if (isSingle) {
      setFormState(previous => {
        let epiUpdatePolicies = previous.fields.epiUpdatePolicies
          .map(x => {
            if (x.id === id) {
              return {
                ...x,
                policyReferenceNumber: '',
                originalCurrency: '',
                settlementCurrency: '',
                gwpOriginal: null,
                gwpSettlement: null
              }
            }

            return x;
          });

        let newState = {
          ...previous,
          fields: {
            ...previous.fields,
            totalNumberOfPolicyReferences: epiUpdatePolicies.length,
            epiUpdatePolicies
          }
        };

        setValidationValue("totalNumberOfPolicyReferences", epiUpdatePolicies.length, { shouldValidate: true });

        return newState;
      });
    }
    else {
      event.stopPropagation();
      api.updateRows([{ id, _action: 'delete' }]);

      setFormState(previous => {
        let epiUpdatePolicies = previous.fields.epiUpdatePolicies
          .filter(p => p.id !== id)
          .map((p, index) => {
            p.layer = index + 1;
            return p;
          });

        let newState = {
          ...previous,
          fields: {
            ...previous.fields,
            totalNumberOfPolicyReferences: epiUpdatePolicies.length,
            epiUpdatePolicies
          }
        };

        setValidationValue("totalNumberOfPolicyReferences", epiUpdatePolicies.length, { shouldValidate: true });

        return newState;
      });
    }
  }, [setFormState, setValidationValue]);

  const onPickerChanged = useCallback((columnName, policy, id, sourceName) => {
    let epiUpdatePolicies = additionalPoliciesRef.current.map(p => {
      if (p.id === id) {
        p[columnName] = (policy && policy[sourceName ?? columnName]) ?? '';
      }

      return p;
    });

    setFormState(previous => ({ ...previous, fields: { ...previous.fields, epiUpdatePolicies } }));

    setColumns(previous => {
      return previous.map(p => {
        if ('forceRerender' in p) {
          p.forceRerender = !p.forceRerender;
        }
        return p;
      });
    });

    abortController.abort();
  }, [setFormState, abortController]);

  const onPolicyReferenceChanged = useCallback((columnName, policy, id) => {
    let epiUpdatePolicies = additionalPoliciesRef.current.map(p => {
      if (p.id === id) {
        p[columnName] = (policy && policy[columnName]) ?? '';
        p.businessEntity = policy?.businessEntity;
        p.settlementCurrency = '';
        p.gwpSettlement = '';
      }

      return p;
    });

    setFormState(previous => ({ ...previous, fields: { ...previous.fields, epiUpdatePolicies } }));

    setColumns(previous => {
      return previous.map(p => {
        if ('forceRerender' in p) {
          p.forceRerender = !p.forceRerender;
        }
        return p;
      });
    });

    abortController.abort();
  }, [setFormState, abortController]);

  const getPickerContainerData = params => {
    let data = additionalPoliciesRef.current.length > 0 ? additionalPoliciesRef.current.find(x => x.id === params.id) : [];
    let source = params.colDef.field;
    let id = params.id;
    let value = data[source];
    let businessEntity = data.businessEntity;

    return { source, id, value, businessEntity };
  }

  const getNumberboxContainerData = params => {
    let data = additionalPoliciesRef.current.find(x => x.id === params.id);

    let name = params.colDef.field;
    let id = params.id;
    let value = data[name];

    return { name, id, value };
  }

  const policyRefContainer = useCallback(params => {
    let { value, id, source } = getPickerContainerData(params);

    return (
      <ComponentController
        name={source}
        control={control}
        required
        render={({ field: { name, onBlur, onChange } }) =>
          <FormControl fullWidth>
            <PickerComponent
              fieldName={name}
              inputRef={ai?.fieldsRef?.[name]}
              value={value ?? ''}
              datasource={Datasource.assuredWithMajorClass}
              abortController={abortController}
              requestData={{ documentType: fields.documentType, businessEntities: dropdownsData.entitiesForFilter }}
              error={!!errors[name]}
              config={{ pickerViewClass: "assured-picker-view" }}
              forceClearDatasource={forceClearDatasourceState}
              setBannerState={setBannerState}
              setLoading={setLoading}
              setFormState={setFormState}
              setValidationValue={setValidationValue}
              onChange={newValue => { onPolicyReferenceChanged(name, newValue, id); onChange(newValue); }}
              onBlur={onBlur}
              setAbortController={setAbortController}
              OptionsHeader={({ className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={2}><div>Organisation Name</div></Grid>
                    <Grid item xs={1}><div>Organisation Type</div></Grid>
                    <Grid item xs={1}><div>Entity</div></Grid>
                    <Grid item xs={1}><div>Major Class</div></Grid>
                    <Grid item xs={1}><div>YOA</div></Grid>
                    <Grid item xs={1}><div>Policy Reference</div></Grid>
                    <Grid item xs={1}><div>Policy Status</div></Grid>
                    <Grid item xs={1}><div>Country</div></Grid>
                    <Grid item xs={1}><div>UMR</div></Grid>
                    <Grid item xs={1}><div>Dec Ref</div></Grid>
                    <Grid item xs={1}><div>Placing Basis</div></Grid>
                  </Grid>
                );
              }}
              OptionView={({ option, className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={2}><div>{option.assured}</div></Grid>
                    <Grid item xs={1}><div>{option.assuredType}</div></Grid>
                    <Grid item xs={1}><div>{option.entity}</div></Grid>
                    <Grid item xs={1}><div>{option.majorClassCode}</div></Grid>
                    <Grid item xs={1}><div>{option.yearOfAccount}</div></Grid>
                    <Grid item xs={1}><div>{option.policyReferenceNumber}</div></Grid>
                    <Grid item xs={1}><div>{option.policyStatus}</div></Grid>
                    <Grid item xs={1}><div>{option.country}</div></Grid>
                    <Grid item xs={1}><div>{option.umr}</div></Grid>
                    <Grid item xs={1}><div>{option.declarationReference}</div></Grid>
                    <Grid item xs={1}><div>{option.placingBasis}</div></Grid>
                  </Grid>
                );
              }}
            />
          </FormControl>
        }
      />
    );
  }, [ai?.fieldsRef, errors, forceClearDatasourceState, control, dropdownsData.entitiesForFilter, fields, abortController, onPickerChanged, setFormState, setBannerState, setValidationValue, setLoading]);

  const currencyContainer = useCallback(params => {
    let { value, id, source, businessEntity } = getPickerContainerData(params);
    let datasource = Datasource[source];
    let requestData = source === 'settlementCurrency' ? { businessEntities: [businessEntity] } : null;

    return (
      <ComponentController
        name={source}
        control={control}
        required
        render={({ field: { name, onBlur, onChange } }) =>
          <FormControl fullWidth>
            <PickerComponent
              fieldName={name}
              inputRef={ai?.fieldsRef?.[name]}
              value={value ?? ''}
              datasource={datasource}
              requestData={requestData}
              abortController={abortController}
              error={!!errors[name]}
              forceClearDatasource={forceClearDatasourceState}
              setBannerState={setBannerState}
              setLoading={setLoading}
              setFormState={setFormState}
              setValidationValue={setValidationValue}
              onChange={newValue => { onPickerChanged(name, newValue, id, "code"); onChange(newValue); }}
              onBlur={onBlur}
              setAbortController={setAbortController}
              OptionsHeader={({ className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={6}><div>Description</div></Grid>
                    <Grid item xs={6}><div>Code</div></Grid>
                  </Grid>
                );
              }}
              OptionView={({ option, className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={6}><div>{option.description}</div></Grid>
                    <Grid item xs={6}><div>{option.code}</div></Grid>
                  </Grid>
                );
              }}
            />
          </FormControl>
        }
      />
    );
  }, [ai?.fieldsRef, errors, forceClearDatasourceState, control, abortController, onPickerChanged, setFormState, setBannerState, setValidationValue, setLoading]);

  const numberboxContainer = useCallback(params => {
    let { name, id, value } = getNumberboxContainerData(params);

    return (
      <NumberBox
        name={name}
        control={control}
        required={true}
        numberValue={value}
        digitsCount={9}
        onValueChange={value => { handleChange(name, value, id); setValidationValue(name, value, { shouldValidate: true }); }}
        errors={errors}
        inputRef={ai?.fieldsRef?.[name]}
      />
    );
  }, [errors, control, handleChange, setValidationValue, ai?.fieldsRef]);

  useEffect(() => {
    let initMandatoryColumns = columns.map(c => {
      if (c.mandatory) {
        c.headerName = `${c.headerName}*`;
      }
      return c;
    });

    setColumns(initMandatoryColumns);
  }, []);

  useEffect(() => {
    const initColumn = (column, container, containerName) => {
      if (column[containerName]) {
        column.renderEditCell = container;
      }
    }

    columns.map(c => {
      initColumn(c, policyRefContainer, "policyRefContainer");
      initColumn(c, currencyContainer, "currencyContainer");
      initColumn(c, numberboxContainer, "numberboxContainer");

      return c;
    });

    setColumns(columns);
  }, [columns, policyRefContainer, currencyContainer, numberboxContainer]);

  useEffect(() => {
    const renderCopyButton = cell => (
      <IconButton edge="end" aria-label="copyRow" onClick={() => onCopyRow(cell)}>
        <FileCopy />
      </IconButton>
    );

    const renderDeleteButton = cell => (
      <IconButton edge="end" aria-label="delete" onClick={event => onRemoveRow(cell, event)}>
        <DeleteIcon />
      </IconButton>
    );

    setColumns(previous => {
      return previous.map(p => {
        if (p.field === "deleteIcon") {
          p.hide = totalNumberOfPolicyReferences <= 1;
          p.renderCell = renderDeleteButton;
        }
        if (p.field === "copyIcon") {
          p.renderCell = renderCopyButton;
        }

        return p;
      });
    });
  }, [totalNumberOfPolicyReferences, onCopyRow, onRemoveRow]);

  const validateGrid = useCallback(() => {
    let policyReferenceItem = epiUpdatePolicies.find(x => x.policyReferenceNumber === fields.policyReferenceNumber);
    if (!policyReferenceItem) {
      return ValidationMessages.policyReference;
    }

    let requiredValid = epiUpdatePolicies
      .map(x => columns
        .filter(c => c.mandatory)
        .every(c => (!!x[c.field]) || x[c.field] === 0))
      .every(x => x);
    if (!requiredValid) {
      return ValidationMessages.required;
    }

    return true;
  }, [fields.policyReferenceNumber, epiUpdatePolicies, columns]);

  return (
    <div>
      <DataGrid
        rowHeight={55}
        className={classes.root}
        rows={additionalPoliciesRef.current}
        columns={columns}
        density="compact"
        hideFooter={true}
        autoHeight={true}
        editMode="row"
        onEditRowsModelChange={handleChange}
      />
      <input {...register(CustomValidationField, { validate: validateGrid })} hidden={true}></input>
    </div>
  );
}

export default EpiUpdateTable;