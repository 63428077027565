import React, { useCallback, useEffect } from 'react';
import { useStarAiContext } from 'star-ai'
import { FormControl, Grid, TextField } from '@material-ui/core';

import { ComponentController, Dropdown, InformationMessage, Portal } from 'components/common';
import { GeneralDetailsFields, TotalNumberOfPolicyReferencesName, TotalPoliciesValidRange, createMajorClassStructure, generateRows } from 'components/SubmissionWizard/Helpers';
import TotalPoliciesField from 'components/SubmissionWizard/Fields/TotalPoliciesField';
import { AssuredAndPolicyReferenceControl } from '../common';
import EpiUpdateTable from './EpiUpdateTable';

import 'components/SubmissionWizard/Steps/GeneralDetails/GeneralDetails.css';

const EpiUpdateSection = ({
  fields,
  dropdownsData,
  control,
  errors,
  register,
  forceClearDatasourceState,
  setFormState,
  setValidationValue,
  setLoading,
  setBannerState,
  modellingMajorClasses
}) => {
  const ai = useStarAiContext();

  const maxJustificationLength = 250;

  useEffect(() => {
    if (fields.totalNumberOfPolicyReferences <= TotalPoliciesValidRange.max &&
      fields.totalNumberOfPolicyReferences >= TotalPoliciesValidRange.min) {
      let data = {
        policyReference: '',
        originalCurrency: '',
        settlementCurrency: '',
        gwpOriginal: null,
        gwpSettlement: null
      };

      let epiUpdatePolicies = generateRows(fields.totalNumberOfPolicyReferences, fields.epiUpdatePolicies, data);
      setFormState(previous => ({
        ...previous,
        fields: { ...previous.fields, epiUpdatePolicies }
      }));
    }
  }, [fields.totalNumberOfPolicyReferences, fields.epiUpdatePolicies, setFormState]);

  const onFieldChange = useCallback((name, value) => {
    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields, [name]: value ?? '' }
    }));
  }, [setFormState]);

  const onAssuredPolicyChanged = useCallback(assured => {
    let majorClass = createMajorClassStructure(assured, modellingMajorClasses);

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        majorClass: majorClass,
        requiredModellingPriority: majorClass?.isModellingPriority
          ? previous.dropdownsData.modellingPriorities[0]
          : ''
      }
    }));
  }, [modellingMajorClasses, setFormState]);

  const getRequiredError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const getMaxLengthError = name => {
    return errors[name]?.type === 'maxLength' &&
      <span role="alert">"{GeneralDetailsFields[name]}" maximum length is {maxJustificationLength} characters</span>;
  }

  const renderModellingPriority = () => {
    let name = "requiredModellingPriority";
    return fields.majorClass?.isModellingPriority &&
      (
        <Grid item>
          <FormControl fullWidth>
            <Dropdown
              id={name}
              label={GeneralDetailsFields.requiredModellingPriority}
              value={fields.requiredModellingPriority}
              data={dropdownsData.modellingPriorities}
              onChange={e => onFieldChange(name, e.target.value)}
              inputRef={ai?.fieldsRef?.[name]}
            />
          </FormControl>
        </Grid>
      );
  }

  const renderLookupForAssuredAndPolicyReference = (lookupType = "lookupForAssuredAndPolicyReference") => {
    return (<AssuredAndPolicyReferenceControl
      lookupType={lookupType}
      errors={errors}
      control={control}
      fields={fields}
      onChange={value => onAssuredPolicyChanged(value)}
      additionalRequestData={{ documentType: fields.documentType, businessEntities: dropdownsData.entitiesForFilter }}
      setFormState={setFormState}
      setValidationValue={setValidationValue}
      setLoading={setLoading}
      setBannerState={setBannerState}
      forceClearDatasourceState={forceClearDatasourceState}
      forceEnabled={true}
      includePlacingBasis={true}
      inputRef={ai?.fieldsRef?.[lookupType]}
    />)
  }

  const renderPolicyReferenceNumber = () => {
    let name = "policyReferenceNumber";

    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.policyReferenceNumber}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderAssured = () => {
    let name = "assured";
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.assured?.assured ?? fields.reassured ?? ""}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderYoa = () => {
    let name = "yoa";
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.yoa ?? ""}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderJustificationForEpiUpdate = () => {
    return (
      <Grid item>
        <ComponentController
          name="justificationForEpiUpdate"
          control={control}
          required
          rules={{ maxLength: maxJustificationLength }}
          render={({ field: { name, onBlur, onChange } }) =>
            <TextField
              id={name}
              name={name}
              label={GeneralDetailsFields[name]}
              fullWidth
              onChange={e => { onFieldChange(name, e.target?.value); onChange(e); }}
              onBlur={onBlur}
              value={fields.justificationForEpiUpdate}
              error={!!errors[name]}
              inputRef={ai?.fieldsRef?.[name]}
              helperText={<InformationMessage
                fieldName={GeneralDetailsFields.justificationForEpiUpdate}
                fieldErrors={errors.justificationForEpiUpdate}
                maxLength={maxJustificationLength}
                showLengthInfo={true}
                length={fields.justificationForEpiUpdate.length}
              />}
            />
          }
        />
      </Grid>
    );
  }

  const renderEpiUpdateTable = () => {
    return (
      <EpiUpdateTable
        fields={fields}
        epiUpdatePolicies={fields.epiUpdatePolicies}
        totalNumberOfPolicyReferences={fields.totalNumberOfPolicyReferences}
        dropdownsData={dropdownsData}
        control={control}
        errors={errors}
        register={register}
        forceClearDatasourceState={forceClearDatasourceState}
        ai={ai}
        setFormState={setFormState}
        setBannerState={setBannerState}
        setValidationValue={setValidationValue}
        setLoading={setLoading}
      />
    );
  }

  const renderPortal = () => {
    return (
      <Portal>
        {renderEpiUpdateTable()}
      </Portal>
    );
  }

  const renderEpiRationale = () => {
    return (
      <Grid item>
        <ComponentController
          name="epiRationale"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                label={GeneralDetailsFields[name]}
                value={fields[name]}
                data={dropdownsData.epiRationaleOptions}
                onChange={e => { onFieldChange(name, e.target.value); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name)}
                shouldSetDefaultValue={true}
                inputRef={ai?.fieldsRef?.[name]}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  const renderEvidenceProvided = () => {
    return (
      <Grid item>
        <ComponentController
          name="epiEvidenceProvided"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                label={GeneralDetailsFields[name]}
                value={fields[name]}
                data={dropdownsData.epiEvidenceProvidedOptions}
                onChange={e => { onFieldChange(name, e.target.value); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name)}
                shouldSetDefaultValue={true}
                inputRef={ai?.fieldsRef?.[name]}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  const renderNotes = () => {
    const evidenceOther = "Other";

    const notesMessage = "If supporting evidence is not provided, EPI update will not be processed";

    return (
      <Grid item>
        <ComponentController
          name="notes"
          control={control}
          required={fields.epiEvidenceProvided.includes(evidenceOther) || fields.epiRationale.includes(evidenceOther)}
          rules={{ maxLength: maxJustificationLength }}
          render={({ field: { name, onBlur, onChange } }) =>
            <TextField
              id={name}
              name={name}
              label={GeneralDetailsFields[name]}
              fullWidth
              onChange={e => { onFieldChange(name, e.target?.value); onChange(e); }}
              onBlur={onBlur}
              value={fields.notes}
              error={!!errors[name]}
              inputRef={ai?.fieldsRef?.[name]}
              helperText={getRequiredError(name) || getMaxLengthError(name) || notesMessage}
            />
          }
        />
      </Grid>
    );
  }

  const renderTotalNumberOfReferences = () => {
    return (
      <Grid item>
        <TotalPoliciesField
          name={TotalNumberOfPolicyReferencesName}
          label={GeneralDetailsFields[TotalNumberOfPolicyReferencesName]}
          required={true}
          control={control}
          value={fields.totalNumberOfPolicyReferences}
          errors={errors}
          setFormState={setFormState}
          setValidationValue={setValidationValue}
          getRequiredError={getRequiredError}
          inputRef={ai?.fieldsRef?.[TotalNumberOfPolicyReferencesName]}
        />
      </Grid>
    );
  }

  return <>
    {renderModellingPriority()}
    {renderLookupForAssuredAndPolicyReference()}
    {renderPolicyReferenceNumber()}
    {renderAssured()}
    {renderYoa()}
    {renderJustificationForEpiUpdate()}
    {renderEpiRationale()}
    {renderEvidenceProvided()}
    {renderNotes()}
    {renderTotalNumberOfReferences()}
    {renderPortal()}
  </>;
}

export default EpiUpdateSection;