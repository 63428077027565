import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import debounce from 'lodash/debounce';

import { FormControl, Grid } from '@material-ui/core';

import { Datasource } from 'Api';
import { BannerType, Dropdown, PickerComponent, ConfirmationDialog, ComponentController } from 'components/common';
import {
  BannerValidationMessage,
  MajorClassCodes,
  LineOfBusinessFields,
  isDualStampEntity,
  isDeclaration,
  singlePolicyStructure,
  declarationPolicyStructure,
  TotalPoliciesValidRange,
  AssuredValidationMessage,
  isCoverHolder
} from 'components/SubmissionWizard/Helpers';
import TotalPoliciesField from 'components/SubmissionWizard/Fields/TotalPoliciesField';
import { getModellingMajorClasses } from 'components/SubmissionWizard/SubmissionWizard.api';
import { ObligorPopup } from 'components/SubmissionWizard/Popups';

import PoliciesTable from './PoliciesTable';
import { generatePolicies } from './PolicyGenerator';

export const LineOfBusiness = ({
  lineOfBusinessState,
  validationRef,
  setLineOfBusinessState,
  businessEntity,
  currentEntity,
  setCurrentEntity,
  currentYoa,
  setCurrentYoa,
  setBannerState,
  setLoading,
  getClassesOfBusiness,
  generalDetailsState,
  forceClearDatasourceState,
}) => {
  const [ abortController, setAbortController ] = useState(new AbortController());
  const { fields, dropdownsData, policies, policiesValidData, modellingMajorClasses } = lineOfBusinessState;
  const { placingBasis, underwriter, yoa, placingBasisCode } = generalDetailsState.fields;
  const { control, trigger, setValue, formState: { errors } } = useForm({
    mode: "onChange",
    defaultValues: { ...fields }
  });
  const [isSingleStructureState, setIsSingleStructureState] = useState(false);
  const loadingTime = 1000;

  const getModellingPriority = useCallback((requiredModellingPriority, policiesList, modellingClasses) => {
    let isModelling = policiesList.some(p => modellingClasses?.includes(p.majorClass));
    return isModelling
      ? !requiredModellingPriority ? dropdownsData.modellingPriorities[0] : requiredModellingPriority
      : '';
  }, [dropdownsData.modellingPriorities]);

  useEffect(() => {
    const initializeForm = async () => {
      setLoading(true);
      if (!lineOfBusinessState.dropdownsData?.classesOfBusiness?.length || businessEntity !== currentEntity || yoa!== currentYoa) {
        const classesOfBusiness = await getClassesOfBusiness(
          underwriter.underwriterAbbreviation,
          yoa,
          businessEntity.entityCode);

        setLineOfBusinessState(previous => ({
          ...previous,
          dropdownsData: {
            ...previous.dropdownsData,
            classesOfBusiness: classesOfBusiness.data
          }
        }));
      }

      if (policies.length === 0 || businessEntity !== currentEntity) {
        const isDualStamp = isDualStampEntity(businessEntity.entityCode);
        const numberOfReferences = isDualStamp ? 2 : 1;
        const initialPolicies = generatePolicies(numberOfReferences, businessEntity, policies);
        setLineOfBusinessState(previous => {
          let policystr = isDualStamp ? '' : previous.fields.policyStructure;
          return {
            ...previous,
            policies: initialPolicies,
            fields: {
              ...previous.fields,
              totalNumberOfPolicyReferences: numberOfReferences,
              policyStructure: policystr,
              requiredModellingPriority: getModellingPriority(
                previous.fields.requiredModellingPriority, initialPolicies, modellingMajorClasses)
            }
          }
        });

        setCurrentEntity(businessEntity);
        setCurrentYoa(yoa);
      }

      setLoading(false);
    }

    const formIsNotInitialized = !lineOfBusinessState.dropdownsData?.classesOfBusiness?.length ||
      policies.length === 0 ||
      businessEntity !== currentEntity;

    if (formIsNotInitialized) {
      initializeForm();
    }

    setCurrentEntity(businessEntity);
    setCurrentYoa(yoa);
  }, [businessEntity, currentEntity, modellingMajorClasses, fields.totalNumberOfPolicyReferences, lineOfBusinessState.fields.policyStructure,
    underwriter.underwriterAbbreviation, yoa, getClassesOfBusiness, policies.length, setCurrentEntity, setCurrentYoa,
    setLineOfBusinessState, setLoading, getModellingPriority,
    lineOfBusinessState.dropdownsData.classesOfBusiness.length, policies]);

  useEffect(() => {
    const initModellingClasses = async () => {
      const response = await getModellingMajorClasses();
      if (response.success) {
        setLineOfBusinessState(previous => ({
          ...previous,
          modellingMajorClasses: response.data
        }));
      }
      else {
        setBannerState({
          show: true,
          type: BannerType.error,
          message: response.errorMessage
        });
      }
    }

    if (!lineOfBusinessState.modellingMajorClasses.length) {
      initModellingClasses();
    }
  }, [lineOfBusinessState.modellingMajorClasses, setLineOfBusinessState, setBannerState]);

  useEffect(() => {
    const declaration = isDeclaration(placingBasisCode);
    
    setLineOfBusinessState(previous => { 
        let updatedPolicies = previous.policies.map(p => ({
          ...p,
          declarationType: declaration ? p.declarationType : "",
          declarationAssured: declaration ? p.declarationAssured : null,
          declarationAssuredId: declaration ? p.declarationAssuredId : null,
          declarationTypeCode: declaration ? p.declarationTypeCode: null
        }));      

        let policyStructure = declaration
        ? declarationPolicyStructure : previous.fields.policyStructure === declarationPolicyStructure && !isDualStampEntity(businessEntity.entityCode)
          ? singlePolicyStructure : previous.fields.policyStructure;

      return {
        ...previous,
        fields: { ...previous.fields, policyStructure },
        policies: policyStructure === singlePolicyStructure && updatedPolicies.length > 0
          ? [updatedPolicies[0]] : updatedPolicies
      }
    });
  }, [placingBasisCode, setLineOfBusinessState]);

  useEffect(() => {
    validationRef.current = async () => {
      let isValid = await trigger();

      if (policiesValidData === false) {
        isValid = false;
      }

      let bannerMessage = BannerValidationMessage;
      for (const assuredValidation of lineOfBusinessState.assuredValidations) {
        if (isValid && assuredValidation.documentType.find(doc => doc === generalDetailsState.fields.documentType) &&
         (!generalDetailsState.fields.assured?.assured)) {
         for (const policy of policies) {
           if (assuredValidation.assuredMajorClasses.includes(policy.majorClass) ||
             (assuredValidation.placingBasis.includes(generalDetailsState.fields.placingBasisCode) &&
             assuredValidation.majorClasses.includes(policy.majorClass))) {
              let isDeclarationType = isDeclaration(generalDetailsState.fields.placingBasisCode) && policy.declarationAssuredId;
              if (!isDeclarationType && !isCoverHolder(generalDetailsState.fields.placingBasisCode)) {
                bannerMessage = AssuredValidationMessage;
                isValid = false;
                break;
              }
           }
         }
       }
      }
      
      if (!isValid) {
        setBannerState({
          show: true,
          type: BannerType.warning,
          message: bannerMessage
        });
      }

      return isValid;
    };
  }, [policies, policiesValidData, setBannerState, trigger, validationRef]);

  const onFieldChange = useCallback((name, value) => {
    setLineOfBusinessState(previous => ({
      ...previous,
      fields: { ...previous.fields, [name]: value ?? '' },
    }));
  }, [setLineOfBusinessState]);

  const onTotalPoliciesChange = useCallback(debounce(event => {
    let policyNumbers = event.target?.value;
    let policiesList = policyNumbers > TotalPoliciesValidRange.max || policyNumbers < TotalPoliciesValidRange.min
      ? policies
      : generatePolicies(policyNumbers, businessEntity, policies);

    setLineOfBusinessState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        requiredModellingPriority: getModellingPriority(
          previous.fields.requiredModellingPriority, policiesList, modellingMajorClasses)
      },
      policies: policiesList
    }));
  }, loadingTime), [policies, modellingMajorClasses, getModellingPriority, setLineOfBusinessState]);

  const onObligorChange = useCallback(obligor => {
    const selectedObligor = {
      name: obligor?.obligor ?? '',
      id: obligor?.id
    };
    
    setLineOfBusinessState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        obligor: obligor ? selectedObligor : null,
        obligorCountryName: '',
        obligorCountryCode: ''
      },
    }));
  }, [setLineOfBusinessState]);

  const onObligorCountryChange = useCallback(value => {
    let obligorCountryName = value?.shortName ?? '';
    let obligorCountryCode = value?.iso2 ?? '';

    setLineOfBusinessState(previous => {
      return {
        ...previous,
        fields: {
          ...previous.fields,
          obligorCountryName,
          obligorCountryCode
        }
      };
    });

    abortController.abort();
  }, [setLineOfBusinessState, abortController]);

  const onPolicyStructureChange = useCallback(event => {
    let policyStructure = event.target?.value;

    if (policyStructure !== singlePolicyStructure) {
      setLineOfBusinessState(previous => {
        let structureConfig = previous.dropdownsData.policyStructures.find(x => x.name === policyStructure);
        let policyNumbers = structureConfig?.isTotalNumberOfPolicyReferencesEnabled
          ? previous.fields.totalNumberOfPolicyReferences
          : structureConfig?.totalNumberOfPolicyReferences;

        let policiesList = generatePolicies(policyNumbers, businessEntity, policies);
        let modellingPriority = getModellingPriority(
          previous.fields.requiredModellingPriority, policiesList, modellingMajorClasses);

        return {
          ...previous,
          fields: {
            ...previous.fields,
            policyStructure,
            totalNumberOfPolicyReferences: policyNumbers,
            requiredModellingPriority: modellingPriority
          },
          policies: policiesList
        };
      });
    }
    else {
      setIsSingleStructureState(true);
    }
  }, [businessEntity, policies, modellingMajorClasses, getModellingPriority, setLineOfBusinessState]);

  const onPeriodBasisChange = useCallback(value => {
    let periodBasis = dropdownsData.periodBases.find(p => p.name === value);

    setLineOfBusinessState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        periodBasis: value,
        periodBasisCode: periodBasis?.code
      },
    }));
  }, [dropdownsData, setLineOfBusinessState]);

  const getRequiredError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{LineOfBusinessFields[name]}" is required</span>;
  }

  const handleSingleStructureDialogConfirm = useCallback(async () => {
    let policyStructure = singlePolicyStructure;

    setIsSingleStructureState(false);

    setLineOfBusinessState(previous => {
      let structureConfig = previous.dropdownsData.policyStructures.find(x => x.name === policyStructure);
      let policyNumbers = structureConfig?.isTotalNumberOfPolicyReferencesEnabled
        ? previous.fields.totalNumberOfPolicyReferences
        : structureConfig?.totalNumberOfPolicyReferences;

      let policiesList = generatePolicies(policyNumbers, businessEntity, policies);
      let modellingPriority = getModellingPriority(
        previous.fields.requiredModellingPriority, policiesList, modellingMajorClasses);

      return {
        ...previous,
        fields: {
          ...previous.fields,
          policyStructure,
          totalNumberOfPolicyReferences: policyNumbers,
          requiredModellingPriority: modellingPriority
        },
        policies: policiesList
      };
    });
  }, [businessEntity, policies, modellingMajorClasses, getModellingPriority, setLineOfBusinessState]);

  const renderPolicyStructure = () => {
    let name = "policyStructure";
    let render = !isDeclaration(placingBasisCode);
    let dualStamp = isDualStampEntity(businessEntity.entityCode);
    let data = dualStamp
      ? dropdownsData.policyStructures.filter(x => x.name !== singlePolicyStructure).map(x => x.name)
      : dropdownsData.policyStructures.map(x => x.name);

    return render && (
      <Grid item xs={3} className="form-fields-container">
        <ComponentController
          name={name}
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                label={LineOfBusinessFields[name]}
                value={fields.policyStructure}
                data={data}
                onChange={e => { onPolicyStructureChange(e); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name)}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  const renderTotalNumberOfReferences = () => {
    let dualStamp = isDualStampEntity(businessEntity.entityCode);
    let disabled = !!fields.policyStructure &&
      !dropdownsData.policyStructures.find(x => x.name === fields.policyStructure)?.isTotalNumberOfPolicyReferencesEnabled;

    return (
      <Grid item xs={3} className="form-fields-container">
        <TotalPoliciesField
          name="totalNumberOfPolicyReferences"
          required={true}
          label={LineOfBusinessFields.totalNumberOfPolicyReferences}
          disabled={disabled}
          control={control}
          value={fields.totalNumberOfPolicyReferences}
          errors={errors}
          dualStampSubmission={dualStamp}
          handleChange={onTotalPoliciesChange}
          setFormState={setLineOfBusinessState}
          setValidationValue={setValue}
          getRequiredError={getRequiredError}
        />
      </Grid>
    );
  }

  const renderObligor = () => {
    let render = policies.some(p => p.majorClassCode === MajorClassCodes.PR);

    return render && (
      <Grid item xs={3} className="form-fields-container">
        <ComponentController
          name="obligor"
          control={control}
          required = {false}
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={LineOfBusinessFields[name]}
              fieldName={name}
              value={fields[name]?.name || fields[name]}
              datasource={Datasource.obligor}
              abortController={abortController}
              error={!!errors[name]}
              errorText={`Applicable to '${MajorClassCodes.PR}' Major Class`}
              setBannerState={setBannerState}
              setValidationValue={setValue}
              setLoading={setLoading}
              setFormState={setLineOfBusinessState}
              setAbortController={setAbortController}
              onChange={value => { onObligorChange(value); onChange(value); }}
              onBlur={onBlur}
              Popup={ObligorPopup}
              OptionsHeader={({ className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={8}><div>Obligor</div></Grid>
                    <Grid item xs={4}><div>Country</div></Grid>
                  </Grid>
                );
              }}
              OptionView={({ option, className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={8}><div>{option.obligor}</div></Grid>
                    <Grid item xs={4}><div>{option.country}</div></Grid>
                  </Grid>
                );
              }}
            />
          }
        />
      </Grid>
    );
  }

  const renderObligorCountry = () => {
    let labelName = LineOfBusinessFields.obligorCountryName;
    let isValid = policies.some(p => p.majorClassCode === MajorClassCodes.PR);
    let requiredField = fields.obligor ? !fields.obligor?.id && isValid : false;

    return requiredField && (
      <Grid item xs={3} className="form-fields-container">
        <ComponentController
          name="obligorCountryName"
          control={control}
          required={requiredField}
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={labelName}
              fieldName={name}
              value={fields.obligorCountryName}
              config={{ showAddOptionIfNoData: false }}
              datasource={Datasource.countries}
              forceClearDatasource={forceClearDatasourceState}
              abortController={abortController}
              error={!!errors[name]}
              errorText={getRequiredError(name)}
              setBannerState={setBannerState}
              setValidationValue={setValue}
              setLoading={setLoading}
              setFormState={setLineOfBusinessState}
              setAbortController={setAbortController}
              onChange={value => { onObligorCountryChange(value); onChange(value); }}
              onBlur={onBlur}
              OptionsHeader={({ className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={9}><div>Country</div></Grid>
                    <Grid item xs={3}><div>Country Code</div></Grid>
                  </Grid>
                );
              }}
              OptionView={({ option, className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={9}><div>{option.shortName}</div></Grid>
                    <Grid item xs={3}><div>{option.iso2}</div></Grid>
                  </Grid>
                );
              }}
            />
          }
        />
      </Grid>
    );
  }

  const renderPeriodBasis = () => {
    let name = "periodBasis"
    let render = policies.some(p => p.majorClassCode === MajorClassCodes.MA);

    return render && (
      <Grid item xs={3} className="form-fields-container"> 
        <ComponentController
          name={name}
          control={control}
          required
          render={({ field: { name,onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                label={LineOfBusinessFields.periodBasis}
                data={dropdownsData.periodBases.map(p => p.name)}
                value={fields.periodBasis ?? ''}
                onChange={e => { onPeriodBasisChange(e.target?.value); onChange(e)}}
                onBlur={onBlur }
                errorText={`Applicable to '${MajorClassCodes.MA}' Major Class`}
              />
            </FormControl>
           }
         />
      </Grid>
    );
  }

  const renderModellingPriority = () => {
    let name = "requiredModellingPriority";
    let render = policies.some(p => modellingMajorClasses?.includes(p.majorClass));

    return render && (
      <Grid item xs={3} className="form-fields-container">
        <FormControl fullWidth>
          <Dropdown
            id={name}
            label={LineOfBusinessFields.requiredModellingPriority}
            value={fields.requiredModellingPriority}
            data={dropdownsData.modellingPriorities}
            onChange={e => onFieldChange(name, e.target.value)}
          />
        </FormControl>
      </Grid>
    );
  }

  const renderPoliciesTable = () => {
    return (
      <PoliciesTable
        totalNumberOfPolicyReferences={fields.totalNumberOfPolicyReferences}
        policyStructure={fields.policyStructure}
        businessEntity={businessEntity}
        placingBasis={placingBasis}
        placingBasisCode={placingBasisCode}
        policies={policies}
        modellingMajorClasses={modellingMajorClasses}
        dropdownsData={dropdownsData}
        control={control}
        errors={errors}
        setLineOfBusinessState={setLineOfBusinessState}
        setBannerState={setBannerState}
        setValidationValue={setValue}
        setLoading={setLoading}
        getModellingPriority={getModellingPriority}
      />
    );
  }

  const renderSingleStructureDialog = () => {
    return (
      <ConfirmationDialog
        isOpened={isSingleStructureState}
        content={`You have selected '${singlePolicyStructure}' as the structure.All rows except the first will be deleted.Would you like to continue?`}
        onConfirm={handleSingleStructureDialogConfirm}
        setIsOpened={setIsSingleStructureState}
      />
    );
  }

  return <>
    <Grid item container xs={12} spacing={2} direction="row">
      {renderPolicyStructure()}
      {renderTotalNumberOfReferences()}
    </Grid>
    <Grid item container xs={12} direction="column">
      <Grid item container direction="column" spacing={1}>
        {renderPoliciesTable()}
        {renderSingleStructureDialog()}
      </Grid>
    </Grid>
    <Grid item container xs={12} spacing={2} direction="row">
      {renderModellingPriority()}
      {renderObligor()}
      {renderObligorCountry()}
      {renderPeriodBasis()}
    </Grid>
  </>;
}