import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import { ComponentController } from './ComponentController';
import { GeneralDetailsFields } from '../SubmissionWizard/Helpers';

export const NumberBox = ({ name, label, control, required, numberValue, digitsCount, precision, onValueChange, customValidation, errors, inputRef }) => {
  let [value, setValue] = useState(!numberValue ? '' : `${numberValue}`);

  let handleChange = event => {
    const defaultPrecision = 2;
    const defaultDigitsCount = 6;

    let { value } = event.target;
    let decimalPlaces = precision ?? defaultPrecision;
    let digits = digitsCount ?? defaultDigitsCount;

    const regexPattern = `^\\d{0,${digits}}(\\.\\d{0,${decimalPlaces}})?$`;
    let regex = new RegExp(regexPattern);

    if (regex.test(value)) {
      setValue(value);
      onValueChange(value ? parseFloat(value) : null);
    }
  };

  const getRequiredError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  return (
    <ComponentController
      name={name}
      control={control}
      required={required}
      rules={customValidation?.rules}
      render={({ field: { name, onChange, onBlur } }) =>
        <TextField
          id={name}
          name={name}
          label={label}
          value={value}
          onChange={e => {
            handleChange(e);
            onChange(e);
          }}
          onBlur={onBlur}
          type="text"
          fullWidth
          error={!!errors[name]}
          helperText={getRequiredError(name) || customValidation?.getError(name)}
          inputRef={inputRef}
          autoComplete="off"
        />
      }
    />
  );
}