import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

export const Portal = ({ children }) => {
  const elRef = useRef(null);

  if (!elRef.current) {
    elRef.current = document.createElement('div');
  }

  const portalRoot = document.getElementById('portal-root');

  useEffect(() => {
    const currentEl = elRef.current;
    portalRoot.appendChild(currentEl);

    return () => {
      portalRoot.removeChild(currentEl);
    };
  }, []);

  return ReactDOM.createPortal(
    children,
    elRef.current
  );
};