import React, { useCallback, useState } from 'react';
import { FormControl, Grid, TextField } from '@material-ui/core';
import { useStarAiContext } from 'star-ai';

import { Datasource } from 'Api';
import { validateDate } from 'Helpers';

import { renderAssuredLookupView, renderAssuredLookupHeader } from 'components/SubmissionWizard/Views';

import {
  BannerType,
  CheckboxElement,
  FormControlWrapper,
  Dropdown,
  PickerComponent,
  SwitchComponent,
  ComponentController,
  DatePickerElement,
  InformationMessage,
  NumberBox
} from 'components/common';
import {
  createMajorClassStructure,
  S4000Entity,
  dualStampEntity,
  GeneralDetailsFields,
  PlacingBases,
  TotalNumberOfPolicyReferencesName,
  HidacEntity,
  TypesOfReassured,
  OrganisationDataSources,
  SingleEntityFieldNames,
  DualEntityFieldNames,
  getFormattedDate,
  OrganisationTypes,
  isDeclaration
} from 'components/SubmissionWizard/Helpers';
import TotalPoliciesField from 'components/SubmissionWizard/Fields/TotalPoliciesField';
import { ConditionCode, DefaultConditionName, AssuredAndPolicyReferenceControl, SecondAssuredAndPolicyReferenceControl } from './common';

import { getAtaccamaOrganisation, getPolicy } from 'components/SubmissionWizard/SubmissionWizard.api';
import { AssuredPopup, IndividualAssuredPopup, CompanyPopup } from 'components/SubmissionWizard/Popups';
import { maxAddressLength, maxPostCodeLength } from 'components/SubmissionWizard/Helpers/Constants';

import '../GeneralDetails.css';

const FirmOrderSection = ({
  fields,
  dropdownsData,
  control,
  errors,
  trigger,
  forceClearDatasourceState,
  setFormState,
  setValidationValue,
  setLoading,
  setBannerState,
  setForceClearDatasourceState,
  validationRef,
  resetFieldValidationState,
  modellingMajorClasses,
  onOrganisationsDataLoad
}) => {

  // star ai
  const ai = useStarAiContext();

  const [abortController, setAbortController] = useState(new AbortController());

  const onFieldChange = useCallback((name, value) => {
    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields, [name]: value ?? '' }
    }));
  }, [setFormState]);

  const getRequiredError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const getCompanyRequiredError = (name, labelName) => {
    return !!labelName && errors[name]?.type === 'required' &&
      <span role="alert">{labelName} is required</span>;
  }

  const getCountryRequiredError = (name, labelName) => {
    return !!labelName && errors[name]?.type === 'required' &&
      <span role="alert">{labelName} is required</span>;
  }

  const getDateValidationError = name => {
    let validDate = errors[name]?.type === 'validDate' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is not valid</span>;

    return validDate;
  }

  const getAssuredRequiredError = (errorAlias, label) => {
    if (errors[errorAlias]?.type === 'required') {
      return <span role="alert">"{label}" is required</span>;
    }
  }

  const getOrbisDataPacket = (fields, property, item, updateKycDetails) => {
    if (item?.source === OrganisationDataSources.Orbis && updateKycDetails) {
      return { ...fields.kycDataPacket, [property]: { ...item } };
    }

    if (!item || !item[property] || item?.source !== OrganisationDataSources.Orbis) {
      return { ...fields.kycDataPacket, [property]: null };
    }

    return fields.kycDataPacket;
  }

  const onDualStampSwitch = useCallback(checked => {
    resetFieldValidationState("assured");
    resetFieldValidationState("secondAssured");
    resetFieldValidationState("updatedTypeOfReassured");
    resetFieldValidationState("updatedCountryName");
    resetFieldValidationState("updatedAssured");
    resetFieldValidationState("updateReassured");
    resetFieldValidationState("companyForSanctionsScreening");
    setForceClearDatasourceState({ "assured": true });
    setForceClearDatasourceState({ "updatedCountryName": true });
    setForceClearDatasourceState({ "updatedAssured": true });
    setForceClearDatasourceState({ "updatedReassured": true });
    setForceClearDatasourceState({ "companyForSanctionsScreening": true });

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        _assuredOrReassured: '',
        _secondAssuredOrReassured: '',
        isDualStamp: checked,
        businessEntity: checked ? dualStampEntity : '',
        majorClass: null,
        placingBasis: '',
        placingBasisCode: '',
        assured: null,
        reassured: '',
        policyId: null,
        policyReferenceNumber: '',
        secondAssured: null,
        secondReassured: '',
        secondPolicyId: null,
        secondPolicyReferenceNumber: '',
        masterPolicyReferenceNumber: '',
        renewedPolicyS4000: null,
        renewedPolicyHIDAC: null,
        totalNumberOfPolicyReferences: checked ? 2 : 1,
        reassuredChangedAtFirmOrder: false,
        typeOfReassured: '',
        dateOfBirth: null,
        address: '',
        countryName: '',
        countryCode: '',
        updatedTypeOfReassured: '',
        updatedAssured: null,
        updatedReassured: null,
        updatedDateOfBirth: null,
        updatedAddress: '',
        updatedPostCode: '',
        state: '',
        stateCode: '',
        updatedCountryName: '',
        updatedCountryCode: '',
        companyForSanctionsScreening: null,
        countryForSanctionsScreening: '',
        countryCodeForSanctionsScreening: '',
        secondReassuredChangedAtFirmOrder: false,
        secondTypeOfReassured: '',
        secondDateOfBirth: null,
        secondAddress: '',
        secondCountryName: '',
        secondCountryCode: '',
        secondUpdatedTypeOfReassured: '',
        secondUpdatedAssured: null,
        secondUpdatedReassured: null,
        secondUpdatedDateOfBirth: null,
        secondUpdatedAddress: '',
        secondUpdatedPostCode: '',
        secondUpdatedCountryName: '',
        secondUpdatedCountryCode: '',
        secondCompanyForSanctionsScreening: null,
        secondCountryForSanctionsScreening: '',
        secondCountryCodeForSanctionsScreening: ''
      }
    }));

    abortController.abort();
  }, [abortController, setFormState, setForceClearDatasourceState, resetFieldValidationState]);

  const onTriaSwitch = useCallback(checked => {
    resetFieldValidationState("triaPremium");
    setValidationValue("triaPremium", null);

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        tria: checked,
        triaPremium: null
      }
    }));
  
    abortController.abort();
  }, [abortController, setFormState]);

  const onAssuredPolicyChanged = useCallback(async assured => {
    let majorClass = createMajorClassStructure(assured, modellingMajorClasses);
    let _prevMajorclassCode = true;
    let assuredType;
    let entity;

    setLoading(true);
    let response = await getPolicy(assured?.policyId);
    setLoading(false);

    if (response.success) {
      let assuredOrg = response.data?.assuredOrganisations?.find(org => org.main)?.orgName;
      let reassuredOrg = response.data?.reassured?.find(org => org.main)?.orgName;
      if (reassuredOrg) {
        assuredType = OrganisationTypes.Reassured.toUpperCase();
      } else if (assuredOrg) {
        assuredType = OrganisationTypes.Assured.toUpperCase();
      }

      entity = response.data?.policyLines[0]?.synd;
    }

    setFormState(previous => {
      const prevMajorclassCode = previous.fields.majorClass?.majorClassCodes?.includes(majorClass?.majorClassCodes[0]);
      _prevMajorclassCode = prevMajorclassCode;

      if (!_prevMajorclassCode) {
        setForceClearDatasourceState({ "secondAssured": true });
        resetFieldValidationState("secondAssured");
      }   

      const _secondAssured = prevMajorclassCode ? previous.fields.secondAssured : null;
      const secondAssuredOrReassured = prevMajorclassCode ? previous.fields._secondAssuredOrReassured : '';
      const _secondPolicyReferenceNumber = prevMajorclassCode ? previous.fields.secondPolicyReferenceNumber : '';
      const _secondPolicyId = prevMajorclassCode ? previous.fields.secondPolicyId : null;
      const _secondReassured = prevMajorclassCode ? previous.fields.secondReassured : null;
      const _renewedPolicyHIDAC = prevMajorclassCode ? previous.fields.renewedPolicyHIDAC : null;
      return {
        ...previous,
        fields: {
          ...previous.fields,
          _secondAssuredOrReassured: secondAssuredOrReassured,
          reassuredChangedAtFirmOrder: false,
          typeOfReassured: '',
          dateOfBirth: null,
          address: '',
          countryName: '',
          countryCode: '',
          updatedTypeOfReassured: '',
          updatedAssured: null,
          updatedReassured: null,
          updatedDateOfBirth: null,
          updatedAddress: '',
          updatedPostCode: '',
          state: '',
          stateCode: '',
          updatedCountryName: '',
          updatedCountryCode: '',
          majorClass: majorClass,
          secondAssured: _secondAssured,
          secondReassured: _secondReassured,
          secondPolicyId: _secondPolicyId,
          secondPolicyReferenceNumber: _secondPolicyReferenceNumber,
          renewedPolicyHIDAC: _renewedPolicyHIDAC,
          requiredModellingPriority: majorClass?.isModellingPriority
            ? previous.dropdownsData.modellingPriorities[0]
            : '',
          assuredType: assuredType ?? '',
          entity: entity ?? ''
        }
      }
    });

    setValidationValue("updatedTypeOfReassured", null);
    setValidationValue("updatedCountryName", null);
    setValidationValue("updatedAssured", null);
    setValidationValue("updatedReassured", null);
    setValidationValue("inceptionDate", null);
    resetFieldValidationState("inceptionDate");

    abortController.abort();
  }, [abortController, dropdownsData.modellingPriorities, setFormState, resetFieldValidationState, setForceClearDatasourceState]);

  const onFirmOrderStageChange = useCallback(async (checked, entityFieldNames) => {
    setValidationValue(entityFieldNames.updatedTypeOfReassured, null);
    setValidationValue(entityFieldNames.updatedCountryName, null);
    setValidationValue(entityFieldNames.updatedAssured, null);
    setValidationValue(entityFieldNames.countryForSanctionsScreening, null);
    setValidationValue(entityFieldNames.companyForSanctionsScreening, null);

    setForceClearDatasourceState({
      [entityFieldNames.updatedAssured]: true,
      [entityFieldNames.updatedCountryName]: true,
      [entityFieldNames.companyForSanctionsScreening]: true,
      [entityFieldNames.countryForSanctionsScreening]: true
    });

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        [entityFieldNames.reassuredChangedAtFirmOrder]: checked,
        [entityFieldNames.typeOfReassured]: '',
        [entityFieldNames.dateOfBirth]: null,
        [entityFieldNames.address]: '',
        [entityFieldNames.countryName]: '',
        [entityFieldNames.countryCode]: '',
        [entityFieldNames.givenName]: '',
        [entityFieldNames.familyName]: '',
        [entityFieldNames.updatedTypeOfReassured]: '',
        [entityFieldNames.updatedAssured]: null,
        [entityFieldNames.updatedDateOfBirth]: null,
        [entityFieldNames.updatedAddress]: '',
        [entityFieldNames.updatedPostCode]: '',
        [entityFieldNames.state]: '',
        [entityFieldNames.stateCode]: '',
        [entityFieldNames.updatedCountryName]: '',
        [entityFieldNames.updatedCountryCode]: '',
        [entityFieldNames.updatedGivenName]: '',
        [entityFieldNames.updatedFamilyName]: '',
        [entityFieldNames.countryForSanctionsScreening]: '',
        [entityFieldNames.countryCodeForSanctionsScreening]: '',
        [entityFieldNames.companyForSanctionsScreening]: null
      }
    }));

    if (checked) {
      setLoading(true);
      let response = await getAtaccamaOrganisation(fields[entityFieldNames.policyId]);
      setLoading(false);

      if (response.success) {
        let typeOfReassured = response.data?.orgType;
        let countryName = !response.data?.entityCountryOfResidencyName ? response.data?.countryName : response.data?.entityCountryOfResidencyName;
        let countryCode = !response.data?.entityCountryOfResidencyCode ? response.data?.country : response.data?.entityCountryOfResidencyCode;
        let address = response.data?.insuredAddress;
        let postCode = response.data?.insuredPostCode;
        let state = response.data?.state;
        let stateCode = response.data?.stateCode;
        let dateOfBirth = response.data?.insuredDOB ? new Date(response.data?.insuredDOB) : null;
        let givenName = response.data?.givenName;
        let familyName = response.data?.familyName;

        setFormState(previous => ({
          ...previous,
          fields: {
            ...previous.fields,
            [entityFieldNames.typeOfReassured]: typeOfReassured,
            [entityFieldNames.countryName]: countryName,
            [entityFieldNames.countryCode]: countryCode,
            [entityFieldNames.dateOfBirth]: dateOfBirth,
            [entityFieldNames.address]: address,
            [entityFieldNames.givenName]: givenName,
            [entityFieldNames.familyName]: familyName,
            [entityFieldNames.updatedTypeOfReassured]: typeOfReassured,
            [entityFieldNames.updatedCountryName]: countryName,
            [entityFieldNames.updatedCountryCode]: countryCode,
            [entityFieldNames.updatedDateOfBirth]: dateOfBirth,
            [entityFieldNames.updatedAddress]: address,
            [entityFieldNames.updatedPostCode]: postCode,
            [entityFieldNames.state]: state,
            [entityFieldNames.stateCode]: stateCode,
            [entityFieldNames.updatedGivenName]: givenName,
            [entityFieldNames.updatedFamilyName]: familyName
          }
        }));

        setValidationValue(entityFieldNames.updatedTypeOfReassured, typeOfReassured);
        setValidationValue(entityFieldNames.updatedCountryName, countryName);
        setValidationValue(entityFieldNames.updatedDateOfBirth, dateOfBirth);
        setValidationValue(entityFieldNames.updatedAddress, address);
        setValidationValue(entityFieldNames.updatedPostCode, postCode);
      } else {
        setBannerState({
          show: true,
          type: BannerType.error,
          message: response.errorMessage
        });
      }
    }

    abortController.abort();
  }, [abortController, setFormState, setBannerState, setLoading, setValidationValue, setForceClearDatasourceState, fields]);

  const onTypeOfReassuredChange = useCallback((event, entityFieldNames) => {
    let updatedTypeOfReassured = event.target?.value;

    setValidationValue(entityFieldNames.updatedCountryName, null);
    setValidationValue(entityFieldNames.updatedAssured, null);
    setValidationValue(entityFieldNames.countryForSanctionsScreening, null);
    setValidationValue(entityFieldNames.companyForSanctionsScreening, null);

    setForceClearDatasourceState({
      [entityFieldNames.updatedAssured]: true,
      [entityFieldNames.updatedCountryName]: true,
      [entityFieldNames.companyForSanctionsScreening]: true,
      [entityFieldNames.countryForSanctionsScreening]: true
    });

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        [entityFieldNames.updatedTypeOfReassured]: updatedTypeOfReassured,
        [entityFieldNames.updatedAssured]: null,
        [entityFieldNames.updatedCountryName]: '',
        [entityFieldNames.updatedCountryCode]: '',
        [entityFieldNames.updatedDateOfBirth]: null,
        [entityFieldNames.updatedAddress]: '',
        [entityFieldNames.updatedPostCode]: '',
        [entityFieldNames.state]: '',
        [entityFieldNames.stateCode]: '',
        [entityFieldNames.updatedGivenName]: '',
        [entityFieldNames.updatedFamilyName]: '',
        [entityFieldNames.countryForSanctionsScreening]: '',
        [entityFieldNames.countryCodeForSanctionsScreening]: '',
        [entityFieldNames.companyForSanctionsScreening]: null
      }
    }));

    abortController.abort();
  }, [abortController, setFormState, setValidationValue, setForceClearDatasourceState]);

  const onCountryChange = useCallback((value, entityFieldNames) => {
    let updatedCountryName = value?.shortName ?? '';
    let updatedCountryCode = value?.iso2 ?? '';

    setValidationValue(entityFieldNames.updatedAssured, null);

    setForceClearDatasourceState({
      [entityFieldNames.updatedAssured]: true
    });

    setFormState(previous => {
      return {
        ...previous,
        fields: {
          ...previous.fields,
          [entityFieldNames.updatedCountryName]: updatedCountryName,
          [entityFieldNames.updatedCountryCode]: updatedCountryCode,
          [entityFieldNames.updatedAssured]: null
        }
      };
    });

    abortController.abort();
  }, [setFormState, setForceClearDatasourceState, setValidationValue, abortController]);

  const onCountryForSanctionsScreeningChange = useCallback((value, entityFieldNames) => {
    let countryForSanctionsScreening = value?.shortName ?? '';
    let countryCodeForSanctionsScreening = value?.iso2 ?? '';

    setValidationValue(entityFieldNames.companyForSanctionsScreening, null);

    setForceClearDatasourceState({
      [entityFieldNames.companyForSanctionsScreening]: true
    });

    setFormState(previous => {
      return {
        ...previous,
        fields: {
          ...previous.fields,
          [entityFieldNames.countryForSanctionsScreening]: countryForSanctionsScreening,
          [entityFieldNames.countryCodeForSanctionsScreening]: countryCodeForSanctionsScreening,
          [entityFieldNames.companyForSanctionsScreening]: null
        }
      };
    });

    abortController.abort();
  }, [setFormState, abortController, setForceClearDatasourceState, setValidationValue]);

  const onUpdatedAssuredChange = useCallback((assured, entityFieldNames) => {
    setFormState(previous => {
      var isIndividual = previous.fields[entityFieldNames.updatedTypeOfReassured] === TypesOfReassured.Individual;

      let orgType;
      let updatedAssuredName;
      if (fields.assuredType === OrganisationTypes.Assured.toUpperCase()) {
        orgType = OrganisationTypes.Assured;
        updatedAssuredName = entityFieldNames.updatedAssured;
      } else if (fields.assuredType === OrganisationTypes.Reassured.toUpperCase()) {
        orgType = OrganisationTypes.Reassured;
        updatedAssuredName = entityFieldNames.updatedReassured;
      }

      let kycDataPacket = getOrbisDataPacket(previous.fields, orgType, assured, true);

      return {
        ...previous,
        fields: {
          ...previous.fields,
          [updatedAssuredName]: assured?.[orgType],
          [entityFieldNames.kycDataPacket]: kycDataPacket,
          [entityFieldNames.hamiltonId]: assured?.hamiltonId,
          [entityFieldNames.updatedAddress]: isIndividual ? assured?.address : '',
          [entityFieldNames.updatedPostCode]: isIndividual ? assured?.postCode : '',
          [entityFieldNames.state]: isIndividual ? assured?.state : '',
          [entityFieldNames.stateCode]: isIndividual ? assured?.stateCode : '',
          [entityFieldNames.updatedDateOfBirth]: isIndividual ? assured?.insuredDOB : previous.fields[entityFieldNames.updatedDateOfBirth]
        }
      };
    });
  }, [setFormState, fields]);

  const onCompanyForSanctionScreeningChange = useCallback((company, entityFieldNames) => {
    const selectedCompany = {
      companyForSanctionsScreening: company?.assured ?? '',
      companyIdForSanctionsScreening: company?.assuredId,
      addressForSanctionsScreening: '',
      postCodeForSanctionsScreening: ''
    };

    setFormState(previous => {
      let kycDataPacket = getOrbisDataPacket('', 'companyForSanctionsScreening', company, true);
      return {
        ...previous,
        fields: {
          ...previous.fields,
          [entityFieldNames.companyAssured]: company?.assured ?? '',
          [entityFieldNames.companyForSanctionsScreening]: company ? selectedCompany : null,
          [entityFieldNames.kycDataPacket]: kycDataPacket,
          [entityFieldNames.companySanctionsHamiltonId]: company?.hamiltonId,
        }
      };
    });
  }, [setFormState]);

  const onUpdatedAssuredPopupSubmit = useCallback((submitData, updatedAssuredName, entityFieldNames) => {
    let assured = submitData.data.assured;
    let address = submitData.data?.address;
    let postCode = submitData.data?.postCode;
    let state = submitData.data?.state;
    let stateCode = submitData.data?.stateCode;

    setFormState(previous => ({
      ...previous,
      fields: { 
        ...previous.fields, 
        [updatedAssuredName]: assured,
        [entityFieldNames.updatedAddress]: address,
        [entityFieldNames.updatedPostCode]: postCode,
        [entityFieldNames.state]: state,
        [entityFieldNames.stateCode]: stateCode,
        [entityFieldNames.hamiltonId]: null,
      }
    }));

    setValidationValue(updatedAssuredName, assured, { shouldValidate: true });
  }, [setFormState, setValidationValue]);

  const onIndividualPopupSubmit = useCallback((submitData, updatedAssuredName, entityFieldNames) => {
    let givenName = submitData.data.givenName ?? '';
    let familyName = submitData.data.familyName ?? '';
    let assured = submitData.data.assured;
    let address = submitData.data?.address;
    let postCode = submitData.data?.postCode;
    let state = submitData.data?.state;
    let stateCode = submitData.data?.stateCode;

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        [updatedAssuredName]: assured,
        [entityFieldNames.updatedGivenName]: givenName,
        [entityFieldNames.updatedFamilyName]: familyName,
        [entityFieldNames.updatedAddress]: address,
        [entityFieldNames.updatedPostCode]: postCode,
        [entityFieldNames.state]: state,
        [entityFieldNames.stateCode]: stateCode,
        [entityFieldNames.hamiltonId]: null,
      }
    }));

    setValidationValue(updatedAssuredName, assured, { shouldValidate: true });
  }, [setFormState, setValidationValue]);

  const onOperatingTerritoryChange = useCallback(value => {
    let operatingTerritory = value?.operatingTerritory;
    let territoryId = value?.id;

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        operatingTerritory,
        territoryId
      }
    }));
  }, [setFormState]);

  const onDateOfBirthChange = useCallback((name, date) => {
    let dateOfBirth = validateDate(date) ? getFormattedDate(date) : null;
    
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        [name]: dateOfBirth ?? date,
      }
    }));
  }, [setFormState]);

  const renderDualStampSwitch = () => {
    const name = "dualStampSwitch";
    let isDualStampVisible = [S4000Entity.DisplayValue, HidacEntity.DisplayValue].every((el) => {
      return dropdownsData.entitiesForFilter.includes(el)
    });
    
    return isDualStampVisible && (
      <Grid item>
        <FormControlWrapper
          control={
            <SwitchComponent
              name={name}
              checked={fields.isDualStamp}
              onChange={e => onDualStampSwitch(e.target?.checked)}
            />
          }
          label={GeneralDetailsFields.dualStampSwitch}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderModellingPriority = () => {
    let name = "requiredModellingPriority";
    return fields.majorClass?.isModellingPriority &&
      (
        <Grid item>
          <FormControl fullWidth>
            <Dropdown
              id={name}
              label={GeneralDetailsFields.requiredModellingPriority}
              value={fields.requiredModellingPriority}
              data={dropdownsData.modellingPriorities}
              onChange={e => onFieldChange(name, e.target.value)}
              inputRef={ai?.fieldsRef?.[name]}
            />
          </FormControl>
        </Grid>
      );
  }

  const renderLookupForAssuredAndPolicyReference = (lookupType = "lookupForAssuredAndPolicyReference") => {
    return (<AssuredAndPolicyReferenceControl
      lookupType={lookupType}
      errors={errors}
      control={control}
      fields={fields}
      onChange={value => onAssuredPolicyChanged(value)}
      additionalRequestData={{ businessEntities: fields.isDualStamp ? [S4000Entity.DisplayValue] : dropdownsData.entitiesForFilter }}
      setFormState={setFormState}
      setValidationValue={setValidationValue}
      setLoading={setLoading}
      setBannerState={setBannerState}
      forceClearDatasourceState={forceClearDatasourceState}
      forceEnabled={true}
      includePlacingBasis={true}
      abortController={abortController}
      setAbortController={setAbortController}
      inputRef={ai?.fieldsRef?.[lookupType]}
    />)
  }

  const renderAssured = (assuredType="assured") => {
    let name = "assured";
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[assuredType]}
          value={fields.assured?.assured ?? fields.reassured ?? ""}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderPolicyReferenceNumber = (policyReferenceNumberType="policyReferenceNumber") => {
    let name = "policyReferenceNumber";

    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[policyReferenceNumberType]}
          value={fields.policyReferenceNumber}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderSecondLookupForAssuredAndPolicyReference = (lookupType = "secondLookupForAssuredAndPolicyReference") => {
    return (fields.isDualStamp && <SecondAssuredAndPolicyReferenceControl
      lookupType={lookupType}
      errors={errors}
      control={control}
      fields={fields}
      additionalRequestData={{ businessEntities: ["HDAC", "IDAC"] }}
      setFormState={setFormState}
      setValidationValue={setValidationValue}
      setLoading={setLoading}
      setBannerState={setBannerState}
      forceClearDatasourceState={forceClearDatasourceState}
      includePlacingBasis={true}
      inputRef={ai?.fieldsRef?.[lookupType]}
    />)
  }

  const renderSecondPolicyReferenceNumber = () => {
    let name = "secondPolicyReferenceNumber";
    return (
      fields.isDualStamp &&
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.secondPolicyReferenceNumber}
        />
      </Grid>
    );
  }

  const renderSecondAssured = () => {
    let name = "secondAssured";
    return (
      fields.isDualStamp &&
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.secondAssured?.assured ?? fields.secondReassured ?? ""}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderChangeOnFirmOrderStage = (label, entityFieldNames, disabled) => {
    return (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              name={entityFieldNames.reassuredChangedAtFirmOrder}
              edge="end"
              disabled={disabled}
              onChange={(_, checked) => onFirmOrderStageChange(checked, entityFieldNames)}
              checked={fields[entityFieldNames.reassuredChangedAtFirmOrder]}
              inputRef={ai?.fieldsRef?.[entityFieldNames.reassuredChangedAtFirmOrder]}
            />
          }
          label={label}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderChangeOnFirmOrderCheckbox = () => {
    return fields.isDualStamp
      ? <>
        {renderChangeOnFirmOrderStage(GeneralDetailsFields.changeOnFirmOrderStage4000, SingleEntityFieldNames, !fields._assuredOrReassured)}
        {renderChangeOnFirmOrderStage(GeneralDetailsFields.changeOnFirmOrderStageHIDAC, DualEntityFieldNames, !fields._secondAssuredOrReassured)}
      </>
      : <>
        {renderChangeOnFirmOrderStage(GeneralDetailsFields.changeOnFirmOrderStage, SingleEntityFieldNames, !fields._assuredOrReassured)}
      </>;
  }

  const renderTypeOfReassured = (label, entityFieldNames) => {
    let organisationTypes = dropdownsData.organisationTypes.find(x => x.placingBasis === fields[entityFieldNames.placingBasisCode])?.organisations ?? [];
    let render = fields[entityFieldNames.placingBasisCode] !== PlacingBases.Declaration;
    let disabled = !fields[entityFieldNames.placingBasis] || organisationTypes.length === 0;

    return render && (
      <Grid item>
        <ComponentController
          name={entityFieldNames.updatedTypeOfReassured}
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                label={label}
                disabled={disabled}
                value={fields[entityFieldNames.updatedTypeOfReassured]}
                data={organisationTypes}
                onChange={e => { onTypeOfReassuredChange(e, entityFieldNames); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name)}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  const renderDateOfBirth = (label, entityFieldNames) => {
    return (
      <Grid item>
        <ComponentController
          name={entityFieldNames.updatedDateOfBirth}
          control={control}
          rules={
            {
              validate: { validDate: value => validateDate(value) }
            }}
          render={({ field: { name, onChange, onBlur } }) =>
            <DatePickerElement
              id={name}
              name={name}
              label={label}
              fullWidth
              format="dd/MM/yyyy"
              value={fields[entityFieldNames.updatedDateOfBirth]}
              onChange={e => { onDateOfBirthChange(name, e); onChange(e); }}
              onBlur={onBlur}
              error={!!errors[name]}
              helperText={getDateValidationError(name)}
            />
          }
        />
      </Grid>
    );
  }

  const renderAddress = (label, entityFieldNames) => {
    return (
      <Grid item>
        <ComponentController
          name={entityFieldNames.updatedAddress}
          control={control}
          rules={{ maxLength: maxAddressLength }}
          render={({ field: { name, onBlur, onChange } }) =>
            <TextField
              id={name}
              name={name}
              label={label}
              fullWidth
              onChange={e => { onFieldChange(name, e.target?.value); onChange(e); }}
              onBlur={onBlur}
              value={fields[entityFieldNames.updatedAddress]}
              error={!!errors[name]}
              helperText={<InformationMessage
                fieldName={label}
                fieldErrors={errors.updatedAddress}
                maxLength={maxAddressLength}
                showLengthInfo={true}
                length={fields[entityFieldNames.updatedAddress]?.length}
              />}
            />
          }
        />
      </Grid>
    );
  }

  const renderPostCode = (label, entityFieldNames) => {
    return (
      <Grid item>
        <ComponentController
          name={entityFieldNames.updatedPostCode}
          control={control}
          rules={{ maxLength: maxPostCodeLength }}
          render={({ field: { name, onBlur, onChange } }) =>
            <TextField
              id={name}
              name={name}
              label={label}
              fullWidth
              onChange={e => { onFieldChange(name, e.target?.value); onChange(e); }}
              onBlur={onBlur}
              value={fields[entityFieldNames.updatedPostCode]}
              error={!!errors[name]}
              helperText={<InformationMessage
                fieldName={label}
                fieldErrors={errors.updatedPostCode}
                maxLength={maxPostCodeLength}
                showLengthInfo={true}
                length={fields[entityFieldNames.updatedPostCode]?.length}
              />}
            />
          }
        />
      </Grid>
    );
  }

  const renderIndividualFiltersSection = (lables, entityFieldNames) => {
    let render = fields[entityFieldNames.updatedTypeOfReassured] === TypesOfReassured.Individual;

    return render && <>
      {renderDateOfBirth(lables.dateOfBirthLabel, entityFieldNames)}
      {renderAddress(lables.addressLabel, entityFieldNames)}
      {renderPostCode(lables.postCodeLabel, entityFieldNames)}
    </>;
  }

  const renderCountry = (countryLabels, entityFieldNames) => {
    let updatedTypeOfReassured = fields[entityFieldNames.updatedTypeOfReassured];

    let fieldName = updatedTypeOfReassured === TypesOfReassured.Company
      ? 'updatedCountryOfIncorporation'
      : updatedTypeOfReassured === TypesOfReassured.Individual
        ? 'updatedCountryOfResidency'
        : 'updatedCountryOfCover';

    let label = countryLabels[fieldName];

    return updatedTypeOfReassured && (
      <Grid item>
        <ComponentController
          name={entityFieldNames.updatedCountryName}
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={label}
              fieldName={name}
              value={fields[entityFieldNames.updatedCountryName]}
              config={{ showAddOptionIfNoData: false }}
              datasource={Datasource.countries}
              forceClearDatasource={forceClearDatasourceState}
              abortController={abortController}
              error={!!errors[name]}
              errorText={getCountryRequiredError(name, label)}
              setBannerState={setBannerState}
              setValidationValue={setValidationValue}
              setLoading={setLoading}
              setFormState={setFormState}
              setAbortController={setAbortController}
              onChange={value => { onCountryChange(value, entityFieldNames); onChange(value); }}
              onBlur={onBlur}
              OptionsHeader={({ className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={9}><div>Country</div></Grid>
                    <Grid item xs={3}><div>Country Code</div></Grid>
                  </Grid>
                );
              }}
              OptionView={({ option, className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={9}><div>{option.shortName}</div></Grid>
                    <Grid item xs={3}><div>{option.iso2}</div></Grid>
                  </Grid>
                );
              }}
            />
          }
        />
      </Grid>
    );
  }

  const renderCountryOfIncorporation = (label, entityFieldNames) => {
    let labelName = GeneralDetailsFields.sanctionsScreeningCountry[label];
    let render = fields[entityFieldNames.updatedTypeOfReassured] == TypesOfReassured.Other
    return render && (
      <Grid item container justifyContent='flex-end'>
        <Grid item xs={10}>
          <ComponentController
            name={entityFieldNames.countryForSanctionsScreening}
            control={control}
            render={({ field: { name, onBlur, onChange } }) =>
              <PickerComponent
                label={labelName}
                fieldName={name}
                value={fields[entityFieldNames.countryForSanctionsScreening]}
                config={{ showAddOptionIfNoData: false }}
                datasource={Datasource.countries}
                forceClearDatasource={forceClearDatasourceState}
                abortController={abortController}
                setBannerState={setBannerState}
                setValidationValue={setValidationValue}
                setLoading={setLoading}
                setFormState={setFormState}
                setAbortController={setAbortController}
                onChange={value => { onCountryForSanctionsScreeningChange(value, entityFieldNames); onChange(value); }}
                onBlur={onBlur}
                OptionsHeader={({ className }) => {
                  return (
                    <Grid container direction="row" spacing={0} className={className}>
                      <Grid item xs={9}><div>Country</div></Grid>
                      <Grid item xs={3}><div>Country Code</div></Grid>
                    </Grid>
                  );
                }}
                OptionView={({ option, className }) => {
                  return (
                    <Grid container direction="row" spacing={0} className={className}>
                      <Grid item xs={9}><div>{option.shortName}</div></Grid>
                      <Grid item xs={3}><div>{option.iso2}</div></Grid>
                    </Grid>
                  );
                }}
              />
            }
          />
        </Grid>
      </Grid>
    );
  }

  const renderUpdatedAssured = (label, entityFieldNames) => {
    let assuredType;
    let datasource;
    let viewLabel;

    if (fields.assuredType === OrganisationTypes.Assured.toUpperCase()) {
      assuredType = entityFieldNames.updatedAssured;
      datasource = Datasource.organisations.assured;
      viewLabel = OrganisationTypes.Assured;
    } else if (fields.assuredType === OrganisationTypes.Reassured.toUpperCase()) {
      assuredType = entityFieldNames.updatedReassured;
      datasource = Datasource.organisations.reassured;
      viewLabel = OrganisationTypes.Reassured;
    }

    let disabled = !fields[entityFieldNames.updatedTypeOfReassured] || !fields[entityFieldNames.updatedCountryName];

    let popup;
    let onPopupSubmit;
    if (fields[entityFieldNames.updatedTypeOfReassured] !== TypesOfReassured.Individual) {
      popup = AssuredPopup;
      onPopupSubmit = submitData => onUpdatedAssuredPopupSubmit(submitData, assuredType, entityFieldNames);
    } else {
      popup = IndividualAssuredPopup;
      onPopupSubmit = submitData => onIndividualPopupSubmit(submitData, assuredType, entityFieldNames);
    }

    let dateOfBirth = fields[entityFieldNames.updatedDateOfBirth];

    return (
      <Grid item>
        <ComponentController
          name={assuredType}
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={label}
              fieldName={name}
              value={fields[assuredType]}
              datasource={datasource}
              config={{
                newValueAtTheEnd: true,
                rowsLimit: 100,
                pickerViewClass: "assured-picker-view"
              }}
              requestData={{
                typeOfReassured: fields[entityFieldNames.updatedTypeOfReassured],
                countryName: fields[entityFieldNames.updatedCountryName],
                countryCode: fields[entityFieldNames.updatedCountryCode],
                entityDOB: dateOfBirth ?? null,
                address: fields[entityFieldNames.updatedAddress],
                countryStatesData: dropdownsData.countryStatesData
              }}
              forceClearDatasource={forceClearDatasourceState}
              datasourceProperty="organisations"
              disabled={disabled}
              abortController={abortController}
              error={!!errors[name]}
              errorText={getAssuredRequiredError(name, label)}
              setBannerState={setBannerState}
              setValidationValue={setValidationValue}
              setLoading={setLoading}
              setFormState={setFormState}
              setAbortController={setAbortController}
              onChange={value => { onUpdatedAssuredChange(value, entityFieldNames); onChange(value); }}
              onBlur={onBlur}
              onCustomDataLoad={onOrganisationsDataLoad}
              Popup={popup}
              onPopupSubmit={onPopupSubmit}
              OptionsHeader={renderAssuredLookupHeader(viewLabel, fields.typeOfReassured)}
              OptionView={renderAssuredLookupView(viewLabel, fields.typeOfReassured)}
            />
          }
        />
      </Grid>
    );
  }

  const renderCompanyForSanctionsScreening = (label, entityFieldNames) => {
    let labelName = GeneralDetailsFields.sanctionsScreeningCompany[label];
    let datasource = Datasource.organisations.assured;
    let render = fields[entityFieldNames.updatedTypeOfReassured] == TypesOfReassured.Other;
    
    let isCountryOfIncorporationSelected;
    if (entityFieldNames.isDualEntity){
      isCountryOfIncorporationSelected = fields.secondCountryForSanctionsScreening;
    } else {
      isCountryOfIncorporationSelected = fields.countryForSanctionsScreening;
    }

    return render && (
      <Grid item container justifyContent='flex-end'>
        <Grid item xs={10}>
          <ComponentController
            name={entityFieldNames.companyForSanctionsScreening}
            control={control}
            required={isCountryOfIncorporationSelected}
            render={({ field: { name, onBlur, onChange } }) =>
              <PickerComponent
                label={labelName}
                fieldName={name}
                value={fields[entityFieldNames.companyAssured]}
                datasource={datasource}
                config={{
                  newValueAtTheEnd: true,
                  rowsLimit: 100,
                  pickerViewClass: "assured-picker-view"
                }}
                requestData={{
                  typeOfReassured: TypesOfReassured.Company,
                  countryCode: fields[entityFieldNames.countryCodeForSanctionsScreening]
                }} 
                forceClearDatasource={forceClearDatasourceState}
                disabled={!isCountryOfIncorporationSelected}
                abortController={abortController}
                error={isCountryOfIncorporationSelected && !!errors[name]}
                errorText={isCountryOfIncorporationSelected && getCompanyRequiredError(name, labelName)}
                setBannerState={setBannerState}
                setValidationValue={setValidationValue}
                setLoading={setLoading}
                setFormState={setFormState}
                setAbortController={setAbortController}
                onChange={value => { onCompanyForSanctionScreeningChange(value, entityFieldNames); onChange(value); }}
                onBlur={onBlur}
                datasourceProperty="organisations"
                Popup={(props) => <CompanyPopup {...props} fieldNames={entityFieldNames} />}
                onCustomDataLoad={onOrganisationsDataLoad}
                OptionsHeader={renderAssuredLookupHeader(OrganisationTypes.Assured, TypesOfReassured.Company)}
                OptionView={renderAssuredLookupView(OrganisationTypes.Assured, TypesOfReassured.Company)}
              />
            }
          />
        </Grid>
      </Grid>
    );
  }

  const renderMoodysLookupSection = () => {
    if (fields.placingBasisCode !== PlacingBases.Declaration) {
      if (fields.reassuredChangedAtFirmOrder && fields.secondReassuredChangedAtFirmOrder) {
        return <>
          {renderTypeOfReassured(GeneralDetailsFields.updatedTypeOfReassured4000, SingleEntityFieldNames)}
          {renderIndividualFiltersSection({ addressLabel: GeneralDetailsFields.updatedAddress4000, dateOfBirthLabel: GeneralDetailsFields.updatedDateOfBirth4000, postCodeLabel: GeneralDetailsFields.updatedPostCode4000 }, SingleEntityFieldNames)}
          {renderCountry(GeneralDetailsFields.updatedCountry4000, SingleEntityFieldNames)}
          {renderUpdatedAssured(GeneralDetailsFields.updatedAssured4000, SingleEntityFieldNames)}
          {renderCountryOfIncorporation('countryForSanctionsScreening4000', SingleEntityFieldNames)}
          {renderCompanyForSanctionsScreening('companyForSanctionsScreening4000', SingleEntityFieldNames)}

          {renderTypeOfReassured(GeneralDetailsFields.updatedTypeOfReassuredHIDAC, DualEntityFieldNames)}
          {renderIndividualFiltersSection({ addressLabel: GeneralDetailsFields.updatedAddressHIDAC, dateOfBirthLabel: GeneralDetailsFields.updatedDateOfBirthHIDAC, postCodeLabel: GeneralDetailsFields.updatedPostCodeHIDAC }, DualEntityFieldNames)}
          {renderCountry(GeneralDetailsFields.updatedCountryHIDAC, DualEntityFieldNames)}
          {renderUpdatedAssured(GeneralDetailsFields.updatedAssuredHIDAC, DualEntityFieldNames)}
          {renderCountryOfIncorporation('countryForSanctionsScreeningHIDAC', DualEntityFieldNames)}
          {renderCompanyForSanctionsScreening('companyForSanctionsScreeningHIDAC', DualEntityFieldNames)}
        </>;
      }

      if (fields.reassuredChangedAtFirmOrder && fields.isDualStamp) {
        return <>
          {renderTypeOfReassured(GeneralDetailsFields.updatedTypeOfReassured4000, SingleEntityFieldNames)}
          {renderIndividualFiltersSection({ addressLabel: GeneralDetailsFields.updatedAddress4000, dateOfBirthLabel: GeneralDetailsFields.updatedDateOfBirth4000, postCodeLabel: GeneralDetailsFields.updatedPostCode4000 }, SingleEntityFieldNames)}
          {renderCountry(GeneralDetailsFields.updatedCountry4000, SingleEntityFieldNames)}
          {renderUpdatedAssured(GeneralDetailsFields.updatedAssured4000, SingleEntityFieldNames)}
          {renderCountryOfIncorporation('countryForSanctionsScreening4000', SingleEntityFieldNames)}
          {renderCompanyForSanctionsScreening('companyForSanctionsScreening4000', SingleEntityFieldNames)}
        </>;
      }

      if (fields.reassuredChangedAtFirmOrder && !fields.isDualStamp) {
        return <>
          {renderTypeOfReassured(GeneralDetailsFields.updatedTypeOfReassured, SingleEntityFieldNames)}
          {renderIndividualFiltersSection({ addressLabel: GeneralDetailsFields.updatedAddress, dateOfBirthLabel: GeneralDetailsFields.updatedDateOfBirth, postCodeLabel: GeneralDetailsFields.updatedPostCode }, SingleEntityFieldNames)}
          {renderCountry(GeneralDetailsFields.updatedCountry, SingleEntityFieldNames)}
          {renderUpdatedAssured(GeneralDetailsFields.updatedAssured, SingleEntityFieldNames)}
          {renderCountryOfIncorporation('countryForSanctionsScreening', SingleEntityFieldNames)}
          {renderCompanyForSanctionsScreening('companyForSanctionsScreening', SingleEntityFieldNames)}
        </>;
      }

      if (fields.secondReassuredChangedAtFirmOrder) {
        return <>
          {renderTypeOfReassured(GeneralDetailsFields.updatedTypeOfReassuredHIDAC, DualEntityFieldNames)}
          {renderIndividualFiltersSection({ addressLabel: GeneralDetailsFields.updatedAddressHIDAC, dateOfBirthLabel: GeneralDetailsFields.updatedDateOfBirthHIDAC, postCodeLabel: GeneralDetailsFields.updatedPostCodeHIDAC }, DualEntityFieldNames)}
          {renderCountry(GeneralDetailsFields.updatedCountryHIDAC, DualEntityFieldNames)}
          {renderUpdatedAssured(GeneralDetailsFields.updatedAssuredHIDAC, DualEntityFieldNames)}
          {renderCountryOfIncorporation('countryForSanctionsScreeningHIDAC', DualEntityFieldNames)}
          {renderCompanyForSanctionsScreening('companyForSanctionsScreeningHIDAC', DualEntityFieldNames)}
        </>;
      }
    }
  }

  const renderInceptionDate = () => {
    let required = !isDeclaration(fields.placingBasisCode);
    return (
      <Grid item>
        <ComponentController
          name="inceptionDate"
          required={required}
          control={control}
          rules={
            {
              validate: { validDate: value => validateDate(value) }
            }}
          render={({ field: { name, onChange, onBlur } }) =>
            <DatePickerElement
              id={name}
              name={name}
              label={GeneralDetailsFields[name]}
              fullWidth
              format="dd/MM/yyyy"
              value={fields[name]}
              onChange={e => { onFieldChange(name, e); onChange(e); }}
              onBlur={onBlur}
              error={!!errors[name]}
              helperText={getRequiredError(name) || getDateValidationError(name)}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
        />
      </Grid>
    );
  }
    
  const renderFinalPricingCompleted = () => {
    const name = "finalPricingCompleted";

    return (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              name={name}
              edge="end"
              onChange={(e, checked) => onFieldChange(e.target?.name, checked)}
              checked={fields[name]}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
          label={GeneralDetailsFields.finalPricingCompleted}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderTotalNumberOfReferences = () => {
    return (
      <Grid item>
        <TotalPoliciesField
          name={TotalNumberOfPolicyReferencesName}
          required={true}
          label={GeneralDetailsFields[TotalNumberOfPolicyReferencesName]}
          control={control}
          value={fields.totalNumberOfPolicyReferences}
          errors={errors}
          setFormState={setFormState}
          setValidationValue={setValidationValue}
          getRequiredError={getRequiredError}
          inputRef={ai?.fieldsRef?.[TotalNumberOfPolicyReferencesName]}
        />
      </Grid>
    );
  }

  const renderOperatingTerritory = () => {
    const name = "operatingTerritory";
    return (
      <Grid item>
        <PickerComponent
          label={GeneralDetailsFields[name]}
          fieldName={name}
          value={fields[name]}
          datasource={Datasource[name]}
          abortController={null}
          setAbortController={setAbortController}
          setBannerState={setBannerState}
          onChange={value => { onOperatingTerritoryChange(value); }}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderConditionCode = () => {
    return (
      <ConditionCode
        name={DefaultConditionName}
        dropdownsData={dropdownsData}
        fields={fields}
        control={control}
        errors={errors}
        trigger={trigger}
        setFormState={setFormState}
        setBannerState={setBannerState}
        validationRef={validationRef}
        isRequired={true}
        inputRef={ai?.fieldsRef?.[DefaultConditionName]}
      />
    );
  }

  const renderMasterPolicyReferenceNumber = () => {
    const name = "masterPolicyReferenceNumber";
    const render = fields.placingBasisCode === PlacingBases.Declaration;

    return render && (
      <Grid item>
        <TextField
          id={name}
          name={name}
          label={GeneralDetailsFields[name]}
          fullWidth
          disabled
          value={fields[name]}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderTriaSwitch = () => {
    const name = "tria";
    
    return (
      <Grid item>
        <FormControlWrapper
          control={
            <SwitchComponent
              name={name}
              checked={fields[name]}
              onChange={e => onTriaSwitch(e.target?.checked)}
            />
          }
          label={GeneralDetailsFields[name]}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderNumberBox = (name, digits, render, isTriaPremium) => {
    let required = isTriaPremium ? fields.tria : false;
    let shouldRender = isTriaPremium ? fields.tria : render;

    return shouldRender && (
      <Grid item>
        <NumberBox
          name={name}
          label={GeneralDetailsFields[name]}
          control={control}
          required={required}
          numberValue={fields[name]}
          digitsCount={digits}
          onValueChange={value => { onFieldChange(name, value); setValidationValue(name, value, { shouldValidate: true }); }}
          errors={errors}
          inputRef={ai?.fieldsRef?.[name]}
        />
      </Grid>
    );
  }

  const renderEeaRisk = () => {
    const name = "eeaRisk";

    return (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              name={name}
              onChange={(e, checked) => onFieldChange(name, checked)}
              checked={fields[name]}
              inputRef={ai?.fieldsRef?.[name]}
            />
          }
          label={GeneralDetailsFields[name]}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  return <>
    {renderDualStampSwitch()}
    {renderModellingPriority()}
    {renderLookupForAssuredAndPolicyReference(fields.isDualStamp ? "lookupForAssuredAndPolicyReference4000" : "lookupForAssuredAndPolicyReference")}
    {renderPolicyReferenceNumber(fields.isDualStamp ? "policyReferenceNumber4000" : "policyReferenceNumber")}
    {renderAssured(fields.isDualStamp ? "assuredOrMasterAssured4000" : "assured")}
    {renderSecondLookupForAssuredAndPolicyReference()}
    {renderSecondPolicyReferenceNumber()}
    {renderSecondAssured()}
    {renderChangeOnFirmOrderCheckbox()}
    {renderMoodysLookupSection()}
    {renderInceptionDate()}
    {renderFinalPricingCompleted()}
    {renderTotalNumberOfReferences()}
    {renderOperatingTerritory()}
    {renderConditionCode()}
    {renderMasterPolicyReferenceNumber()}
    {renderTriaSwitch()}
    {renderNumberBox("triaPremium", 6, false, true)}
    {renderEeaRisk()}
    {renderNumberBox("rtbls", 3, true, false)}
  </>;
}

export default FirmOrderSection;