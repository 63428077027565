import { v4 as uuidv4 } from 'uuid';

export const generateRows = (rowsCount, rows, newRowData) => {
  let newRowsCount = rowsCount - rows.length;
  let lastIndex = rows.length;
  let resultRows = [];

  if (rowsCount > rows.length) {
    resultRows = [...rows, ...Array.from({ length: newRowsCount }, (_, i) => i + 1).map(i => ({
      id: uuidv4(),
      layer: lastIndex + i,
      ...newRowData
    }))];

    return resultRows;
  }
  else if (rowsCount < rows.length) {
    for (let i = 0; i < rowsCount; i++) {
      resultRows = [...resultRows, rows[i]]
    }

    return resultRows;
  }

  return rows;
}