import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { TextField, Dialog, DialogActions, DialogTitle, DialogContent, FormControl } from '@material-ui/core';

import { DialogButton, BannerType, ComponentController } from 'components/common';
import { GeneralDetailsFields, getCountryData } from '../Helpers';
import { addAssured } from '../SubmissionWizard.api';
import { maxAddressLength, assuredMaxLength, maxPostCodeLength } from '../Helpers/Constants';
import { Dropdown } from 'components/common';

export const CompanyPopup = ({
  popupState,
  onPopupSubmit,
  submitData,
  requestData,
  setPopupState,
  setFormState,
  setValidationValue,
  setBannerState,
  setLoading,
  fieldNames = {
    companyForSanctionsScreening: "companyForSanctionsScreening",
    companyIdForSanctionsScreening: "companyIdForSanctionsScreening",
    companySanctionsHamiltonId: "companySanctionsHamiltonId",
    companyAssured: "companyAssured"
  }
}) => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: "onTouched",
    defaultValues: {
      [fieldNames.companyForSanctionsScreening]: popupState.newValue,
      state: ""
    }
  });

  const [states, setStates] = useState([]);
  const [stateToStateCodeMap, setStateToStateCodeMap] = useState({});
  const [isCountryValid, setIsCountryValid] = useState(false);
      
  useEffect(() => {
    const { countryData, states, stateToStateCodeMap } = getCountryData(
      requestData?.countryStatesData,
      requestData?.countryName
    );
    setStates(states);
    setStateToStateCodeMap(stateToStateCodeMap);
    setIsCountryValid(!!countryData);
  }, [requestData?.countryStatesData, requestData?.countryName]);

  const handleClose = useCallback(
    () => setPopupState(previous => ({ ...previous, show: false })),
    [setPopupState]
  );

  const onCompanyNameSubmit = useCallback(response => {
    let companyDetails = {
      companyForSanctionsScreening: response.data?.assured,
      companyIdForSanctionsScreening: response.data?.assuredId,
      addressForSanctionsScreening: response.data?.address,
      postCodeForSanctionsScreening: response.data?.postCode,
    };

    let state = response.data?.state;
    let stateCode = response.data?.stateCode;

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        [fieldNames.companyAssured]: response.data?.assured ?? '',
        [fieldNames.companyForSanctionsScreening]: companyDetails ?? null,
        [fieldNames.companySanctionsHamiltonId]: null,
        state,
        stateCode
      }
    }));

    setValidationValue(fieldNames.companyForSanctionsScreening, companyDetails, { shouldValidate: true });
  }, [setFormState, setValidationValue, fieldNames]);

  const onSubmit = useCallback(async popupData => {
    handleClose();
    let companyName = popupData[fieldNames.companyForSanctionsScreening];
    let stateCode = stateToStateCodeMap[popupData.state];

    if (requestData.typeOfReassured) {
      onCompanyNameSubmit({
        data: {
          assured: companyName,
          address: popupData.addressDetails,
          postCode: popupData.postCodeDetails,
          state: popupData.state,
          stateCode: stateCode
        }
      });
    } else {
      setLoading(true);

      let response = await addAssured({ assured: companyName });
      if (response.success) {
        if (onPopupSubmit) {
          onPopupSubmit(response, submitData);
        } else {
          onCompanyNameSubmit(response);
        }
      } else {
        setBannerState({
          show: true,
          type: BannerType.error,
          message: response.errorMessage
        });
      }

      setLoading(false);
    }
  }, [handleClose, submitData, onCompanyNameSubmit, onPopupSubmit, setBannerState, setLoading, fieldNames, stateToStateCodeMap]);

  const getRequiredValidationError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields.companyForSanctionsScreening}" is required</span>;
  }

  const getMaxLengthValidationError = name => {
    return errors[name]?.type === 'maxLength' &&
      <span role="alert">"{GeneralDetailsFields.companyForSanctionsScreening}" maximum length is {assuredMaxLength} characters</span>;
  }

  return (
    <Dialog open={popupState.show} onClose={handleClose} aria-labelledby="assured-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="assured-dialog-title">Add a new Company name for sanctions screening</DialogTitle>
        <DialogContent>
          <ComponentController
            name={fieldNames.companyForSanctionsScreening}
            control={control}
            required
            rules={{ maxLength: assuredMaxLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields.companyForSanctionsScreening}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getRequiredValidationError(name) || getMaxLengthValidationError(name)}
              />
            }
          />
          <ComponentController
            name="addressDetails"
            control={control}
            rules={{ maxLength: maxAddressLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getMaxLengthValidationError(name)}
              />
            }
          />
          <ComponentController
            name="postCodeDetails"
            control={control}
            rules={{ maxLength: maxPostCodeLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                helperText={getMaxLengthValidationError(name)}
              />
            }
          />
          {isCountryValid && (
            <ComponentController
              name="state"
              control={control}
              render={({ field: { name, value, onChange, onBlur } }) =>
                <FormControl fullWidth error={!!errors[name]}>
                  <Dropdown
                    id={name}
                    name={name}
                    value={value || ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={GeneralDetailsFields[name]}
                    data={states}
                    margin="dense"
                    fullWidth
                  />
                </FormControl>
              }
            />
          )}
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <DialogButton onClick={handleClose}>Cancel</DialogButton>
          <DialogButton type="Submit">Add</DialogButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};