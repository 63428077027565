export const DocumentTypes = {
  Submissions: "Submissions",
  Cancellations: "Cancellations",
  Endorsements: "Endorsements",
  EpiUpdate: "EPI Update",
  FirmOrder: "Firm Order",
  FirmOrderOnly: "Firm Order Only",
  ModellingSubmission: "Modelling Submission",
  ModellingInfo: "Modelling Info",
  PeerReviewOnly: "Peer Review Only",
  QCError: "QC Error",
  Quote: "Quote",
  BulkItem: "Bulk Item",
  BordereauxBooking: "Bordereaux Booking",
  AccountsReceivable: "Accounts Receivable",
  PromisedLines: "Promised Line/F.O.N."
};

export const PlacingBases = {
  MasterBinder: "MB",
  Binder: "B",
  BinderNonProportionalRI: "BNP",
  BinderProportionalRI: "BP",
  Declaration: "DEC",
  LineSlip: "L",
  LineslipProportionalRI: "LP",
  LineslipNonProportionalRI: "LNP",
  Consortium: "C",
  ConsortiaNonProportionalRI: "CNP",
  ConsortiaProportionalRI: "CP",
  FacultativeCover: "FC",
  ReportingReinsuranceMaster: "RIM",
  NonProportionalReportingReinsuranceMaster: "NRM",
  ProportionalReportingReinsuranceMaster: "PRM"
};

export const assuredMaxLength = 130;
export const maxAddressLength = 250;
export const maxPostCodeLength = 15;

export const RenewDeclaration = {
  ShortDeclaration: "eDSUC",
  LongDeclaration: "eFRD"
}

export const CustomValidationField = "customValidation";

export const RenewalLinkReference = "renewal";

export const CustomLinkType = "custom";

export const DeclarationPlacingType = "DEC";

export const ReassuredPlacingBases = ["F", "FP", "XSL", "QS", "XSR", "SL", "NRM", "PRM"];

export const CoverHolderPlacingBases = [PlacingBases.Binder, PlacingBases.BinderNonProportionalRI, PlacingBases.BinderProportionalRI, PlacingBases.MasterBinder];

export const ModellingPriorities = ["No Modelling Required", "Low", "Medium", "High"];

export const MajorClassCodes = {
  MA: "MA",
  PR: "PR"
};

export const OrganisationTypes = {
  Assured: "assured",
  Reassured: "reassured",
  Obligor: "obligor",
  Coverholder: "coverHolder"
};

export const OrganisationDataSources = {
  Ataccama: "Ataccama",
  Orbis: "Orbis",
  Eclipse: "Eclipse"
};

export const TypesOfReassured = {
  Company: "Company",
  Individual: "Individual",
  Other: "Other"
}

export const GeneralDetailsFields = {
  criticalMonthEndTask: "Critical Month End Task",
  policyEntryInformation: "Policy Entry Information",
  documentType: "Type Of Document",
  majorClass: "Major Class",
  businessEntity: "Business Entity",
  underwriter: "Underwriter",
  yoa: "YOA",
  renewedPolicy: "Renewed Policy",
  expiringPolicyReferenceNumber: "Expiring Policy Reference",
  policyReferenceNumber: "Policy Reference Number",
  policyReferenceNumber4000: "Policy Reference Number (4000)",
  secondPolicyReferenceNumber: "Policy Reference Number (HIDAC)",
  masterPolicyReferenceNumber: "Master Policy Reference Number",
  masterPolicyReferenceNumber4000: "Master Policy Reference Number (S4000)",
  secondMasterPolicyReferenceNumber: "Master Policy Reference Number (HIDAC)",
  totalNumberOfPolicyReferences: "Total Number of Policy References/Declarations",
  totalNumberOfMonthsRequired: "Total Number of Months Required",
  conditionCode: "Condition Code",
  placingBasis: "Placing Basis",
  broker: "Broker",
  brokerContact: "Broker Contact",
  declineImmediately: "Decline Immediately",
  lookupForAssuredAndPolicyReference: "Lookup for (Assured / Reassured / Policy Reference)",
  lookupForAssuredAndPolicyReference4000: "Lookup for Assured / Reassured / Policy Reference (4000)",
  secondLookupForAssuredAndPolicyReference: "Lookup for Assured / Reassured / Policy Reference (HIDAC)",
  lookupForMasterAssuredAndMasterPolicyReference: "Lookup for Master (Assured / Reassured / Policy Reference)",
  lookupForMasterAssuredAndMasterPolicyReference4000: "Lookup for Master (Assured / Reassured / Policy Reference) S4000",
  secondLookupForMasterAssuredAndMasterPolicyReference: "Lookup for Master (Assured / Reassured / Policy Reference) HIDAC",
  assured: "Assured / Reassured / Master Assured",
  assuredOrMasterAssured: "Assured / Master Assured",
  assuredOrMasterAssured4000: "Assured / Reassured / Master Assured (4000)",
  secondAssured: "Assured / Reassured / Master Assured (HIDAC)",
  _masterAssuredOrMasterReassured: "Master Assured / Reassured",
  _masterAssuredOrMasterReassured4000: "Master Assured / Reassured (S4000)",
  _secondMasterAssuredOrMasterReassured: "Master Assured / Reassured (HIDAC)",
  declarationAssured: "Declaration Assured",
  reassured: "Reassured",
  coverHolder: "Coverholder",
  updatedAssured: "Updated Assured / Reassured",
  updatedAssured4000: "Updated Assured / Reassured (4000)",
  updatedAssuredHIDAC: "Updated Assured / Reassured (HIDAC)",
  eplacement: "E placement",
  operatingTerritory: "Operating Territory",
  typeOfModellingRisk: "Type Of Modelling Risk",
  requiredModellingPriority: "Required Modelling Priority",
  typeOfBordereaux: "Type of Bordereaux",
  inceptionDate: "Inception Date",
  pricingNotRequired: "Pricing Not Required",
  eeaRisk: "EEA Risk",
  contractBasis: "Contract Basis",
  premiumBearing: "Premium Bearing",
  justificationForEpiUpdate: "Justification for EPI Update",
  changeOnFirmOrderStage: "Is there a change to the Assured / Reassured at Firm Order stage?",
  changeOnFirmOrderStage4000: "Has the (Re)Assured changed at Firm Order stage (4000)",
  changeOnFirmOrderStageHIDAC: "Has the (Re)Assured changed at Firm Order stage (HIDAC)",
  finalPricingCompleted: "Has Final Pricing Been Completed?",
  peerReviewNotRequired: "Peer Review not Required",
  sendTaskToOperations: "Send task to Operations for manual reference creation",
  expiringReferenceSwitch: "Expiring Policy Reference/Programme Reference",
  expiringPolicyReference: "Expiring Policy Reference",
  expiringProgrammeReference: "Expiring Programme Reference",
  dualStampSwitch: "Dual Stamp",
  typeOfReassured: "Type Of (Re)Assured",
  updatedTypeOfReassured: "Updated Type Of (Re)Assured",
  updatedTypeOfReassured4000: "Updated Type Of (Re)Assured (4000)",
  updatedTypeOfReassuredHIDAC: "Updated Type Of (Re)Assured (HIDAC)",
  renewalTypeOfReassured: "Renewal Type Of (Re)Assured",
  renewAssuredMasterAssured: "Renewal Assured / Master Assured",
  renewReassured: "Renewal Reassured",
  renewAssuredForReassured: "Change of Assured for Renewal",
  periodMonth: "Month of Period",
  periodYear: "Year of Period",
  commentsBordereaux: "Comments",
  dateOfBirth: "Date of Birth",
  renewalDateOfBirth: "Renewal Date of Birth",
  updatedDateOfBirth: "Updated Date of Birth",
  updatedDateOfBirth4000: "Updated Date of Birth (4000)",
  updatedDateOfBirthHIDAC: "Updated Date of Birth (HIDAC)",
  address: "Address",
  renewalAddress: "Renewal Address",
  updatedAddress: "Updated Address",
  updatedAddress4000: "Updated Address (4000)",
  updatedAddressHIDAC: "Updated Address (HIDAC)",
  updatedPostCode: "Updated Post Code",
  updatedPostCode4000: "Updated Post Code (4000)",
  updatedPostCodeHIDAC: "Updated Post Code (HIDAC)",
  country: {
    countryOfIncorporation: "Country Of Incorporation",
    countryOfResidency: "Country Of Residency",
    countryOfCover: "Country Of Cover"
  },
  updatedCountry: {
    updatedCountryOfIncorporation: "Updated Country Of Incorporation",
    updatedCountryOfResidency: "Updated Country Of Residency",
    updatedCountryOfCover: "Updated Country Of Cover"
  },
  updatedCountry4000: {
    updatedCountryOfIncorporation: "Updated Country Of Incorporation (4000)",
    updatedCountryOfResidency: "Updated Country Of Residency (4000)",
    updatedCountryOfCover: "Updated Country Of Cover (4000)"
  },
  updatedCountryHIDAC: {
    updatedCountryOfIncorporation: "Updated Country Of Incorporation (HIDAC)",
    updatedCountryOfResidency: "Updated Country Of Residency (HIDAC)",
    updatedCountryOfCover: "Updated Country Of Cover (HIDAC)"
  },
  renewalCountry: {
    renewalCountryOfIncorporation: "Renewal Country Of Incorporation",
    renewalCountryOfResidency: "Renewal Country Of Residency",
    renewalCountryOfCover: "Renewal Country Of Cover"
  },
  assuredCountry: {
    assuredCountryOfIncorporation: "Country Of Incorporation (Assured)",
    countryOfCover: "Country Of Cover (Assured)"
  },
  givenName: "Given Name(s)",
  familyName: "Family Name",
  countryName: "Country",
  givenName : "Given Name",
  highPrioritySanctionsCheck: "High Priority Sanctions Check Required",
  tagAddress: "Address",
  postCode: "Post Code",
  renewalPostCode: "Renewal Post Code",
  tagCountry: "Country",
  sanctionsScreeningCountry: {
    countryForSanctionsScreening: "Country Of Incorporation For Sanctions Screening",
    countryForSanctionsScreening4000: "Country Of Incorporation For Sanctions Screening (4000)",
    countryForSanctionsScreeningHIDAC: "Country Of Incorporation For Sanctions Screening (HIDAC)"
  },
  sanctionsScreeningCompany: {
    companyForSanctionsScreening: "Company Name For Sanctions Screening",
    companyForSanctionsScreening4000: "Company Name For Sanctions Screening (4000)",
    companyForSanctionsScreeningHIDAC: "Company Name For Sanctions Screening (HIDAC)",
  },
  addressDetails: "Address",
  postCodeDetails: "Post Code",
  state: "State",
  expiringAssured: "Expiring Assured",
  expiringReassured: "Expiring Reassured",
  promisedDate: "Promised Date",
  tria: "TRIA",
  triaPremium: "TRIA Premium",
  rtbls: "RTBLs",
  notes: "Notes",
  epiRationale: "EPI rationale",
  epiEvidenceProvided: "Evidence Provided"
};

export const LineOfBusinessFields = {
  policyStructure: "Structure",
  totalNumberOfPolicyReferences: "Total Number of Policy References",
  obligor: "Obligor",
  periodBasis: "Period Basis",
  requiredModellingPriority: "Required Modelling Priority",
  obligorCountryName: "Country (Obligor)"
};

export const SingleEntityFieldNames = {
  reassuredChangedAtFirmOrder: "reassuredChangedAtFirmOrder",
  placingBasis: "placingBasis",
  placingBasisCode: "placingBasisCode",
  typeOfReassured: "typeOfReassured",
  dateOfBirth: "dateOfBirth",
  address: "address",
  postCode: "postCode",
  state: "state",
  stateCode: "stateCode",
  countryName: "countryName",
  countryCode: "countryCode",
  updatedTypeOfReassured: "updatedTypeOfReassured",
  updatedAssured: "updatedAssured",
  updatedReassured: "updatedReassured",
  updatedCoverholder: "updatedCoverholder",
  updatedDateOfBirth: "updatedDateOfBirth",
  updatedAddress: "updatedAddress",
  updatedCountryName: "updatedCountryName",
  updatedCountryCode: "updatedCountryCode",
  updatedFamilyName: "updatedFamilyName",
  updatedGivenName: "updatedGivenName",
  updatedPostCode: "updatedPostCode",
  renewalAssured: "renewalAssured",
  renewalReassured: "renewalReassured",
  renewalCoverHolder: "renewalCoverHolder",
  renewalDateOfBirth: "renewalDateOfBirth",
  renewalAddress: "renewalAddress",
  renewalPostCode: "renewalPostCode",
  renewalFamilyName: "renewalFamilyName",
  renewalGivenName: "renewalGivenName",
  policyId: "policyId",
  hamiltonId: "hamiltonId",
  companySanctionsHamiltonId: "companySanctionsHamiltonId",
  kycDataPacket: "kycDataPacket",
  companyForSanctionsScreening: "companyForSanctionsScreening",
  countryForSanctionsScreening: "countryForSanctionsScreening",
  countryCodeForSanctionsScreening: "countryCodeForSanctionsScreening",
  companyAssured: "companyAssured",
  isDualEntity: false
};

export const DualEntityFieldNames = {
  reassuredChangedAtFirmOrder: "secondReassuredChangedAtFirmOrder",
  placingBasis: "secondPlacingBasis",
  placingBasisCode: "secondPlacingBasisCode",
  typeOfReassured: "secondTypeOfReassured",
  dateOfBirth: "secondDateOfBirth",
  address: "secondAddress",
  state: "state",
  stateCode: "stateCode",
  countryName: "secondCountryName",
  countryCode: "secondCountryCode",
  updatedTypeOfReassured: "secondUpdatedTypeOfReassured",
  updatedAssured: "secondUpdatedAssured",
  updatedReassured: "secondUpdatedReassured",
  updatedCoverholder: "secondUpdatedCoverholder",
  updatedDateOfBirth: "secondUpdatedDateOfBirth",
  updatedAddress: "secondUpdatedAddress",
  updatedPostCode: "secondUpdatedPostCode",
  updatedCountryName: "secondUpdatedCountryName",
  updatedCountryCode: "secondUpdatedCountryCode",
  updatedFamilyName: "secondUpdatedFamilyName",
  updatedGivenName: "secondUpdatedGivenName",
  policyId: "secondPolicyId",
  hamiltonId: "secondHamiltonId",
  companySanctionsHamiltonId: "secondCompanySanctionsHamiltonId",
  kycDataPacket: "secondKycDataPacket",
  companyForSanctionsScreening: "secondCompanyForSanctionsScreening",
  countryForSanctionsScreening: "secondCountryForSanctionsScreening",
  countryCodeForSanctionsScreening: "secondCountryCodeForSanctionsScreening",
  companyAssured: "secondCompanyAssured",
  isDualEntity: true
};

export const TotalPoliciesValidRange = { min: 1, max: 30, minDualStamp: 2 };

export const BannerValidationMessage = "Form contains validation errors. Please provide valid information.";
export const AssuredValidationMessage = "You cannot submit this task until the Assured has been populated.";

export const TotalNumberOfPolicyReferencesName = "totalNumberOfPolicyReferences";
export const TotalNumberOfMonthsRequiredName = "Total Number of Months Required";

export const S4000Entity = { Value: "S4000", DisplayValue: "4000" };
export const HidacEntity = { Value: "HIDAC", DisplayValue: "HDAC" };
export const S1947Entity = { Value: "S1947", DisplayValue: "1947" };
export const DualStampEntity = { Value: "Dual Stamp S4000/HIDAC", DispalyValue: "Dual Stamp 4000/HIDAC"}

export const DefauldUnderwriter = {
  Abbreviation: "XX",
  RenewalYear: 2023
};

export const UmrField = {
  MinLength: 4,
  MaxLength: 16
}